import { All_QUOTE_REQUEST, All_QUOTE_SUCCESS, All_QUOTE_FAIL, All_QUOTE_RESET, } from '../constants/AllQuoteRequestContants'




export const getAllRequestQuoteReducer = (state = { requests: [] }, action) => {
    switch (action.type) {
        case All_QUOTE_REQUEST:
            return { ...state, loading: true }
        case All_QUOTE_SUCCESS:
            return {
                loading: false,
                requests: action.payload.data,
            }
        case All_QUOTE_FAIL:
            return { loading: false, error: action.payload }
        case All_QUOTE_RESET:
            return { requests: [] }
        default:
            return state
    }
}
