import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { getAllLaptopAllication } from "../../actions/LaptopApplicationAction";
import ModalHeader from "../../components/ModalHeader";
import { BsLaptop } from "react-icons/bs";

import {
  NoRecordFound,
  SearchComponent,
  TableFetch,
} from "../../components/TableOptions";
import Pagination from "../../components/Pagination";
import { useNavigate } from "react-router-dom";

const HodGetAllLaptop = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [displayData, setDisplayData] = useState([]);
  const getAllApplicantsDetails = useSelector(
    (state) => state.getAllApplicantsDetails
  );
  const { data: datainfo, loading, error } = getAllApplicantsDetails;

  const [data, setData] = useState([]);
  const [filtered, setFilterd] = useState([]);
  const [result, setResult] = useState("");

  const [entriesPerPage, setEntriesPerPage] = useState(() => {
    return localStorage.getItem("rowsPerPage") || "8";
  });

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role === "Admin" ||
        userInfo.role === "CEO" ||
        userInfo.role === "Super Admin" ||
        (userInfo.role === "Head Of Department" &&
          userInfo.department === "IT") ||
        (userInfo.role === "Head Of Department" &&
          userInfo.department === "Admin"))
    ) {
      dispatch(getAllLaptopAllication());
    } else {
      navigate("/");
    }
  }, [dispatch, navigate, userInfo]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => {
  //   setShow(true);
  // }

  useEffect(() => {
    setFilterd(datainfo);
    localStorage.setItem("rowsPerPage", entriesPerPage);
  }, [datainfo, entriesPerPage]);

  useEffect(() => {
    const results = filtered?.filter((data) =>
      data?.employeeEmail.toLowerCase().includes(result)
    );
    setData(results);
  }, [result, filtered]);

  const onChange = (e) => {
    setResult(e.target.value);
  };

  return (
    <div id="reports">
      <h5 className="page-title mb-4">All Laptop Request</h5>
      <div className="half-background">
        <SearchComponent
          sortData={data}
          entriesPerPage={entriesPerPage}
          setEntriesPerPage={setEntriesPerPage}
          placeholder={"All Laptop request"}
          value={result}
          onChange={onChange}
        />
        <section className="md-ui component-data-table">
          {error && (
            <Message variant="danger" className="error-border" dismissible>
              {error}
            </Message>
          )}
          {loading && <Loader />}
          <div className="main-table-wrapper">
            <>
              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="myleave-modal">
                <ModalHeader
                  setLgShow={setShow}
                  icon={<BsLaptop size={30} />}
                  title={"Laptop"}
                  subtitle={"Request for Laptop"}
                />
                <Modal.Body className="show-grid">
                  <div className="getjob-application-details">
                    <p>Laptop Type</p>
                    <p>{data?.Array?.laptopType}</p>
                    <p>Laptop Ram Size</p>
                    <p>{data?.laptopRamSize}</p>
                    <p>Accessories</p>
                    <p>{data?.accessories}</p>
                    <p>Employee Email</p>
                    <p>{data?.employeeEmail}</p>
                    <p>Reason</p>
                    <p>{data?.reason}</p>
                    <p>Date</p>
                    <p>{moment(data?.createdAt).format("DD-MM-YYYY")}</p>
                  </div>
                  <div className="spoken-languages">
                    <p>Spoken Languages</p>
                    <div className="getjob-application-details">
                      <p>HOD Approval</p>
                      <p>
                        {data?.hodApproval ? (
                          <i
                            className="fas fa-check-circle"
                            style={{ color: "green" }}
                          />
                        ) : (
                          <i
                            className="fas fa-times-circle"
                            style={{ color: "#e2522e" }}
                          />
                        )}
                      </p>
                      <p>Final Approval</p>
                      <p>
                        {data?.finalApproval ? (
                          <i
                            className="fas fa-check-circle"
                            style={{ color: "green" }}
                          />
                        ) : (
                          <i
                            className="fas fa-times-circle"
                            style={{ color: "#e2522e" }}
                          />
                        )}
                      </p>
                    </div>
                  </div>
                  <Button
                    className="applyleave-btn "
                    type="submit"
                    value="Request">
                    {loading ? "Requesting..." : "Request"}
                  </Button>
                  <Button
                    className="mb-2"
                    variant="secondary"
                    onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Body>
              </Modal>
            </>

            <div className="main-table-wrapper">
              <table className="main-table-content">
                <thead className="data-table-header">
                  <tr className="data-table-row">
                    <th>Laptop Type</th>
                    <th>Ram Size</th>
                    <th>Accessories</th>
                    <th>Employee Email</th>
                    <th>Reason</th>
                    <th>Date</th>
                    <th>HOD IT Approval</th>
                    <th>Admin Approval</th>
                    <th>Final Approval</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody className="data-table-content">
                  {loading ? (
                    <TableFetch colSpan={10} />
                  ) : displayData?.length === 0 || displayData == null ? (
                    <NoRecordFound colSpan={10} />
                  ) : (
                    displayData?.map((user) => (
                      <tr key={user?._id}>
                        <td>{user?.laptopType}</td>
                        <td>{user?.laptopRamSize}</td>
                        <td>{user?.accessories}</td>
                        <td>{user?.employeeEmail}</td>
                        <td>{user?.reason}</td>
                        <td>{moment(user?.createdAt).format("DD-MM-YYYY")}</td>
                        <td>
                          {user?.hodITApproval ? (
                            <i
                              className="fas fa-check-circle"
                              style={{ color: "green" }}></i>
                          ) : (
                            <i
                              className="fas fa-times-circle"
                              style={{ color: "red" }}></i>
                          )}
                        </td>
                        <td>
                          {user?.adminApproval ? (
                            <i
                              className="fas fa-check-circle"
                              style={{ color: "green" }}></i>
                          ) : (
                            <i
                              className="fas fa-times-circle"
                              style={{ color: "red" }}></i>
                          )}
                        </td>
                        <td>
                          {user?.ceoApproval ? (
                            <i
                              className="fas fa-check-circle"
                              style={{ color: "green" }}></i>
                          ) : (
                            <i
                              className="fas fa-times-circle"
                              style={{ color: "red" }}></i>
                          )}
                        </td>
                        <td className="myleave-status-td">
                          {userInfo.role === "Head Of Department" &&
                          userInfo.department === "IT" ? (
                            <Link
                              to={`/hodupdate/${user?._id}/laptop/`}
                              exact
                              className="update-btn rounded-5"
                              style={{
                                background: "#E2522E",
                                boxShadow: "none",
                              }}>
                              Update
                            </Link>
                          ) : userInfo.role === "Head Of Department" &&
                            userInfo.department === "Admin" ? (
                            <Link
                              to={`/adminapprove/${user?._id}/laptop/`}
                              exact
                              className="update-btn rounded-5"
                              style={{
                                background: "#E2522E",
                                boxShadow: "none",
                              }}>
                              Update
                            </Link>
                          ) : (
                            userInfo.role === "CEO" && (
                              <Link
                                to={`/ceoapprove/${user?._id}/laptop/`}
                                exact
                                className="update-btn rounded-5"
                                style={{
                                  background: "#E2522E",
                                  boxShadow: "none",
                                }}>
                                Update
                              </Link>
                            )
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <footer className="main-table-footer">
          <Pagination
            setDisplayData={setDisplayData}
            data={data}
            entriesPerPage={entriesPerPage}
            Total={"All Laptop Request"}
          />
        </footer>
      </div>
    </div>
  );
};

export default HodGetAllLaptop;
