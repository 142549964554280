import React from "react";
import { Doughnut } from "react-chartjs-2";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};

const DonutChat = ({ employees }) => {
  // Filter employees with true policy
  const truePolicyEmployees = employees?.filter(
    (employee) => employee?.policy === true
  );
  const truePolicyLength = truePolicyEmployees?.length;

  // Filter employees with false policy
  const falsePolicyEmployees = employees?.filter(
    (employee) => employee?.policy === false
  );
  const falsePolicyLength = falsePolicyEmployees?.length;

  const data = {
    labels: ["Employees signed policy", "Employees policy not signed"],
    datasets: [
      {
        label: "Employee",
        data: [truePolicyLength, falsePolicyLength],
        backgroundColor: ["#124383", "#7aa3d86e"],
        borderColor: ["#124383", "#7aa3d86e"],
        borderWidth: 1,
      },
    ],
  };
  // className = "chat-container";
  return (
    <div>
      <Doughnut data={data} />
    </div>
  );
};

export default DonutChat;
