import React, { useEffect, useState } from "react";
import { updateJobPostByIdAction } from "../../actions/CreateJobPostAction";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { baseUrl } from "../../shared/baseUrl";
import axios from "axios";
import Message from "../../components/Message";
import { useParams, useNavigate } from "react-router-dom";

const UpdataJobPost = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  // getAllJobPostById
  const postID = id;
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // const updateJobPostById = useSelector((state) => state.updateJobPostById);
  // const { error: errorPost } = updateJobPostById;

  const [data, setData] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const [qualifications, setQualifications] = useState("");
  const [jobOverview, setJobOverview] = useState("");
  const [responsilities, setResponsilities] = useState("");
  const [benefits, setBenefits] = useState("");

  const [deleteJobpost, setDeleteJobpost] = useState(false);
  const [successDelect, setSuccessDelect] = useState(false);
  const [successUpdate, setSuccessUpdate] = useState(false);
  const [loadingUpdate, setloadingUpdate] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const _Id = postID;
  const url = `/api/v1/career/${_Id}`;
  useEffect(() => {
    const getSinglePost = async () => {
      const res = await axios.get(baseUrl + url, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      setData(res.data.career);
    };
    getSinglePost();
  }, [userInfo, url]);

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role === "Admin" ||
        userInfo.role === "CEO" ||
        userInfo.role === "Super Admin" ||
        (userInfo.role === "Head Of Department" &&
          userInfo.department === "IT") ||
        (userInfo.role === "Head Of Department" &&
          userInfo.department === "Admin"))
    ) {
      dispatch(updateJobPostByIdAction());
    } else {
      navigate("/");
    }
  }, [dispatch, navigate, userInfo]);

  useEffect(() => {
    if (successDelect === true) {
      navigate("/createJobpost");
    }
    if (successUpdate === true) {
      navigate("/createJobpost");
    }
  }, [successDelect, navigate, successUpdate]);

  const deletePostHandler = (e) => {
    e.preventDefault(e);
    async function DeleteSinglePost(_Id) {
      setDeleteJobpost(true);
      setSuccessDelect(false);
      const request = await axios.delete(baseUrl + `/api/v1/career/${_Id}`, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      setDeleteJobpost(false);
      setSuccessDelect(true);
      return request;
    }
    DeleteSinglePost(_Id);
  };

  useEffect(() => {
    setJobTitle(data.jobTitle);
    setJobLocation(data.jobLocation);
    setQualifications(data.qualifications);
    setJobOverview(data.jobOverview);
    setResponsilities(data.responsilities);
    setBenefits(data.benefits);
  }, [data]);

  const updateJobPostHandler = (e) => {
    e.preventDefault(e);
    setloadingUpdate(true);
    setSuccessUpdate(false);
    dispatch(
      updateJobPostByIdAction({
        _id: postID,
        jobTitle,
        jobLocation,
        qualifications,
        jobOverview,
        responsilities,
        benefits,
      })
    );

    setTimeout(function () {
      setloadingUpdate(false);
    }, 2000);
    setTimeout(function () {
      setShowMessage(true);
    }, 2500);
    setTimeout(function () {
      setSuccessUpdate(true);
    }, 4500);
  };

  return (
    <div className="dashboard-container">
      <div className="profilescreen-wrapper">
        <div className="dashboard-body">
          <div className="allLeave-title">
            <h3>UPDATE JOB POST</h3>
          </div>
          {/* {errorPost && (
            <Message variant="danger" className="error-border">
              {errorPost}
            </Message>
          )} */}

          <Form className="form-shadow" onSubmit={updateJobPostHandler}>
            <Form.Row>
              <Form.Group className="col-md-12" controlId="firstname">
                <Form.Label>Job Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Job Title"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}></Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group className="col-md-12" controlId="formGridDepartment">
                <Form.Label> Job Location </Form.Label>
                <Form.Control
                  type="text"
                  value={jobLocation}
                  onChange={(e) =>
                    setJobLocation(e.target.value)
                  }></Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1">
              <Form.Label>Job Overview</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={jobOverview}
                onChange={(e) => setJobOverview(e.target.value)}
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1">
              <Form.Label>Responsilities</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={responsilities}
                onChange={(e) => setResponsilities(e.target.value)}
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1">
              <Form.Label>Qualifications </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={qualifications}
                onChange={(e) => setQualifications(e.target.value)}
              />
            </Form.Group>

            {showMessage && (
              <Message variant="success" className="success-border">
                {" "}
                <p className="success-color">Updated successfully!</p>{" "}
              </Message>
            )}
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1">
              <Form.Label>Benefits</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={benefits}
                onChange={(e) => setBenefits(e.target.value)}
              />
            </Form.Group>

            <button
              className="mb-2 btn"
              variant="primary"
              disabled={loadingUpdate && true}
              type="submit"
              value="Register"
              style={{
                background: "orange",
                color: "#fff",
                marginRight: "20px",
              }}>
              {loadingUpdate ? "Updating..." : "Update"}
            </button>
            <button
              className="mb-2 btn"
              variant="secondary"
              style={{ background: "red", color: "#fff" }}
              onClick={deletePostHandler}
              disabled={deleteJobpost && true}>
              {deleteJobpost ? "Delecting..." : "Delect"}
            </button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default UpdataJobPost;
