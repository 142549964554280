import React, { useState, useEffect } from "react";
import { Col, Form, Button, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  hodGetAllLaptopRequestById,
  approveHodLaptopRequestId,
  rejectHodLaptopRequestId,
} from "../../actions/LaptopApplicationAction";
import {
  APPROVE_HOD_LAPTOP_ID_RESET,
  LAPTOP_REQUEST_DETAILS_ID_HOD_RESET,
  REJECT_HOD_LAPTOP_ID_RESET,
} from "../../constants/laptopApplicationContants";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { Link, useParams, useNavigate } from "react-router-dom";
import { GoBriefcase } from "react-icons/go";
import ModalHeader from "../../components/ModalHeader";

const HODUpdateLaptop = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const laptopRequestId = id;
  const laptopRequestDetailsById = useSelector(
    (state) => state.laptopRequestDetailsById
  );
  const { laptoprequest, loading, error } = laptopRequestDetailsById;

  const [employeeEmail, setEmployeeEmail] = useState("");
  const [laptopType, setLaptopType] = useState("");
  const [laptopRamSize, setLaptopRamSize] = useState("");
  const [reason, setReason] = useState("");
  const [accessories, setAccessories] = useState("");
  // const [laptopStatus, setLaptopStatus] = useState('')
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const approveHodLaptopRequest = useSelector(
    (state) => state.approveHodLaptopRequest
  );
  const {
    success: successUpdate,
    error: errorUpdate,
    loading: loadingUpdate,
  } = approveHodLaptopRequest;

  const rejectHodLaptopRequest = useSelector(
    (state) => state.rejectHodLaptopRequest
  );
  const { success: successReject, error: errorReject } = rejectHodLaptopRequest;

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role === "Human Resource Executive" ||
        userInfo.role === "Head Of Department - HR" ||
        userInfo.role === "CEO" ||
        userInfo.role === "Super Admin" ||
        userInfo.role === "Assistant Manager - Human Resources" ||
        userInfo.role === "Human Resource Manager" ||
        userInfo.role === "Head Of Department")
    ) {
      if (successUpdate || successReject) {
      } else {
        if (laptoprequest._id !== laptopRequestId) {
          dispatch(hodGetAllLaptopRequestById(laptopRequestId));
        } else {
          setEmployeeEmail(laptoprequest.employeeEmail);
          setLaptopType(laptoprequest.laptopType);
          setLaptopRamSize(laptoprequest.laptopRamSize);
          setAccessories(laptoprequest.accessories);
          setReason(laptoprequest.reason);
          // setLaptopStatus(laptoprequest.laptopStatus)
          setApproved(laptoprequest.hodITApproval);
          setRejected(laptoprequest.rejectRequest);
        }
      }
    } else {
      navigate("/");
    }
  }, [
    navigate,
    userInfo,
    laptopRequestId,
    dispatch,
    laptoprequest,
    successUpdate,
    successReject,
  ]);

  useEffect(() => {
    if (successUpdate) {
      setShowMessage(true);
      dispatch({
        type: APPROVE_HOD_LAPTOP_ID_RESET,
      });
      dispatch({
        type: LAPTOP_REQUEST_DETAILS_ID_HOD_RESET,
      });
      dispatch({
        type: REJECT_HOD_LAPTOP_ID_RESET,
      });
    }
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  }, [dispatch, successUpdate]);

  const approveLaptopHandler = (e) => {
    e.preventDefault(e);
    dispatch(
      approveHodLaptopRequestId({
        _id: laptopRequestId,
      })
    );
  };

  const handleReject = (e) => {
    e.preventDefault(e);
    dispatch(
      rejectHodLaptopRequestId({
        _id: laptopRequestId,
      })
    );
  };

  return (
    <>
      <div className="dashboard-container">
        <div className="profilescreen-wrapper">
          <div className="dashboard-body">
            <div className="allLeave-title">
              <h3>HOD LAPTOP APPROVAL</h3>
            </div>
            {error && (
              <Message variant="danger" className="error-border">
                {error}
              </Message>
            )}
            <div>
              {errorUpdate && (
                <Message variant="danger" className="error-border">
                  {errorUpdate}
                </Message>
              )}
              {errorReject && (
                <Message variant="danger" className="error-border">
                  {errorReject}
                </Message>
              )}
              {showMessage && (
                <Message variant="success" className="success-border">
                  {" "}
                  <p className="success-color">
                    Laptop approved successfully!
                  </p>{" "}
                </Message>
              )}
              {loading && <Loader />}
              <Form className="form-shadow" onSubmit={approveLaptopHandler}>
                <Form.Row>
                  <Form.Group as={Col} controlId="employeeEmail">
                    <Form.Label>Employee Email</Form.Label>
                    <Form.Control
                      type="text"
                      value={employeeEmail}
                      onChange={(e) => setEmployeeEmail(e.target.value)}
                      disabled></Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} controlId="laptopType">
                    <Form.Label>Laptop Type</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={laptopType}
                      value={laptopType}
                      onChange={(e) => setLaptopType(e.target.value)}
                      disabled></Form.Control>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="laptopRamSize">
                    <Form.Label>Laptop Ram Size</Form.Label>
                    <Form.Control
                      type="text"
                      value={laptopRamSize}
                      onChange={(e) => setLaptopRamSize(e.target.value)}
                      disabled></Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} controlId="accessories">
                    <Form.Label>Accessories</Form.Label>
                    <Form.Control
                      type="text"
                      value={accessories}
                      onChange={(e) => setAccessories(e.target.value)}
                      disabled></Form.Control>
                  </Form.Group>
                </Form.Row>

                <Form.Group controlId="description">
                  <Form.Label>Detailed Reason For Laptop Request </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    style={{ backgroundColor: "var(--input-field-color)" }}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    disabled
                  />
                </Form.Group>

                {approved ? (
                  <Link
                    to="/hodgetallaptop/"
                    className="btn btn-light my-3 go-back-btn"
                    style={{ background: "green" }}>
                    APPROVED || Go Back
                  </Link>
                ) : rejected ? (
                  <Link
                    to="/hodgetallaptop/"
                    className="btn btn-light my-3 go-back-btn"
                    style={{ background: "red" }}>
                    REJECTED || Go Back
                  </Link>
                ) : (
                  <>
                    <Button
                      className="applyleave-btn "
                      variant="primary"
                      disabled={loadingUpdate && true}
                      type="submit"
                      value="Approve">
                      {loadingUpdate ? "Approving..." : "Approve"}
                    </Button>
                    <Button
                      className="mb-2 rejectleave-btn"
                      onClick={handleShow}>
                      Reject
                    </Button>
                    <Modal show={show} onHide={handleClose}>
                      <div className="not-eligible">
                        <div className="not-eligible-container">
                          <ModalHeader
                            setLgShow={setShow}
                            icon={<GoBriefcase size={30} />}
                            title={"Leave"}
                            subtitle={"Reject Laptop Request"}
                          />
                          <Modal.Body>
                            <i className="fas fa-exclamation-triangle fa-2x pb-3"></i>
                            <br />
                            <p>
                              <h3>Are you sure you want to Reject!</h3>
                            </p>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              className="mb-2 rejectleave-btn"
                              disabled={loading && true}
                              style={{
                                backgroundColor: "#e2522e",
                                borderRadius: 50,
                              }}
                              onClick={handleReject}
                              value="Reject"
                              type="submit">
                              {loading ? (
                                <Spinner animation="border" size="sm" />
                              ) : (
                                "Reject"
                              )}
                            </Button>
                          </Modal.Footer>
                        </div>
                      </div>
                    </Modal>
                  </>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HODUpdateLaptop;
