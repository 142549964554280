import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getAllAgentApplication } from "../../actions/agentLeaveActions";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import {
  NoRecordFound,
  SearchComponent,
  TableFetch,
} from "../../components/TableOptions";
import Pagination from "../../components/Pagination";

const AllAgentLeaveApplicationsScreen = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [displayData, setDisplayData] = useState([]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const allAgentLeaveApplications = useSelector(
    (state) => state.allAgentLeaveApplications
  );
  const { data: datainfo, loading, error } = allAgentLeaveApplications;

  const [data, setData] = useState([]);
  const [filtered, setFilterd] = useState([]);
  const [result, setResult] = useState("");

  const [entriesPerPage, setEntriesPerPage] = useState(() => {
    return localStorage.getItem("rowsPerPage") || "8";
  });

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role === "Human Resource Executive" ||
        userInfo.role === "Head Of Department - HR" ||
        userInfo.role === "Super Admin" ||
        userInfo.role === "Assistant Manager - Human Resources" ||
        userInfo.role === "Human Resource Manager" ||
        userInfo.role === "CEO")
    ) {
      dispatch(getAllAgentApplication());
    } else {
      navigate("/");
    }
  }, [dispatch, navigate, userInfo]);

  useEffect(() => {
    setFilterd(datainfo);
  }, [datainfo]);
  console.log(filtered);
  useEffect(() => {
    const results = filtered?.filter((data) =>
      data.employee.email.toLowerCase().includes(result)
    );
    setData(results);
    localStorage.setItem("rowsPerPage", entriesPerPage);
  }, [result, filtered, entriesPerPage]);

  const onChange = (e) => {
    setResult(e.target.value);
  };

  return (
    <div id="reports">
      <h5 className="page-title mb-4">Agent Leave Applications</h5>
      <div className="half-background">
        <SearchComponent
          sortData={data}
          entriesPerPage={entriesPerPage}
          setEntriesPerPage={setEntriesPerPage}
          placeholder={"All Agent Leave"}
          onChange={onChange}
        />

        <section className="md-ui component-data-table">
          {error && (
            <Message variant="danger" className="error-border" dismissible>
              {error}
            </Message>
          )}
          {loading && <Loader />}
          <div className="main-table-wrapper">
            <table className="main-table-content">
              <thead className="data-table-header">
                <tr className="data-table-row">
                  <th>Date Applied</th>
                  <th>Email Address</th>
                  <th>Leave Type</th>
                  <th>Start Date</th>
                  <th>Leave Status</th>
                  <th>Update</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <TableFetch colSpan={8} />
                ) : displayData?.length === 0 || displayData == null ? (
                  <NoRecordFound colSpan={8} />
                ) : (
                  displayData?.map((user) => (
                    <tr key={user?._id}>
                      <td>{moment(user?.createdAt)?.format("DD-MM-YYYY")}</td>
                      <td>{user?.employee?.email}</td>
                      <td>{user?.leaveType}</td>
                      <td>{moment(user?.fromDate)?.format("DD-MM-YYYY")}</td>
                      <td>{user?.leaveStatus}</td>
                      <td>
                        <NavLink
                          to={`/myteamleaveapplications/${user?._id}/update`}
                          exact
                          className="update-btn">
                          Update
                        </NavLink>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </section>
        <footer className="main-table-footer">
          <Pagination
            setDisplayData={setDisplayData}
            data={data}
            entriesPerPage={entriesPerPage}
            Total={"Agent Leave Applications"}
          />
        </footer>
      </div>
    </div>
  );
};

export default AllAgentLeaveApplicationsScreen;
