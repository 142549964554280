import React, { useState, useEffect } from "react";
import { Col, Form, Button, Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Footer from "../../components/Footer";
import Message from "../../components/Message";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../shared/baseUrl";
import {
  EMPLOYEE_UPDATE_LEAVE_APPLICATION_RESET,
  LEAVE_APPLICATION_DETAILS_ID_RESET,
} from "../../constants/leaveApplicationConstants";
import {
  employeeUpdateApplication,
  getAllEmployeeLeaveApplicationById,
} from "../../actions/leaveApplication";
import { useNavigate, useParams } from "react-router-dom";
import { GoBriefcase } from "react-icons/go";
import ModalHeader from "../../components/ModalHeader";

const EmployeeUpdateLeaveScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const leaveApplicationId = id;

  const [leaveType, setLeaveType] = useState("");
  const [fromDate, setLeaveStartDate] = useState("");
  const [toDate, setLeaveEndDate] = useState("");
  const [noOfDays, setNoOfDays] = useState(0);
  const [reasonForLeave, setLeaveDescription] = useState("");
  // const [leaveStatus, setLeaveStatus] = useState('')
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const leaveApplicationDetailsById = useSelector(
    (state) => state.leaveApplicationDetailsById
  );
  const { leaveapplication } = leaveApplicationDetailsById;

  const employeeUpdateLeave = useSelector((state) => state.employeeUpdateLeave);
  const {
    success: successUpdate,
    error: errorUpdate,
    loading: loadingUpadte,
  } = employeeUpdateLeave;

  useEffect(() => {
    if (userInfo) {
      if (successUpdate) {
        dispatch({
          type: EMPLOYEE_UPDATE_LEAVE_APPLICATION_RESET,
        });
        dispatch({
          type: LEAVE_APPLICATION_DETAILS_ID_RESET,
        });
        navigate("/myleave");
      } else {
        dispatch(getAllEmployeeLeaveApplicationById(leaveApplicationId));
        setLeaveType(leaveapplication?.data?.leaveType);
        setLeaveStartDate(
          moment(leaveapplication?.data?.fromDate).format("YYYY-MM-DD")
        );
        setLeaveEndDate(
          moment(leaveapplication?.data?.toDate).format("YYYY-MM-DD")
        );
        setNoOfDays(leaveapplication?.data?.noOfDays);
        setLeaveDescription(leaveapplication?.data?.reasonForLeave);
        // setLeaveStatus(leaveapplication.leaveStatus)
        setApproved(leaveapplication?.data?.finalApproval);
        setRejected(leaveapplication?.data?.rejected);
      }
    } else {
      navigate("/");
    }
  }, [
    navigate,
    userInfo,
    leaveApplicationId,
    leaveapplication,
    successUpdate,
    dispatch,
  ]);

  const updateMyLeaveHandler = (e) => {
    e.preventDefault(e);
    dispatch(
      employeeUpdateApplication({
        _id: leaveApplicationId,
        leaveType,
        fromDate,
        toDate,
        noOfDays,
        reasonForLeave,
      })
    );
    // history.push('/leaveapplications')
  };

  //  leave-modal
  const [showdeleteLeave, setShowDeleteLeave] = useState(false);
  const handleDeleteClose = () => setShowDeleteLeave(false);
  const handleDeleteShow = () => setShowDeleteLeave(true);

  const deleteLeaveHandler = () => {
    async function deleteLeave(leaveApplicationId) {
      const request = await axios.delete(
        baseUrl + `/api/v1/empleave/${leaveApplicationId}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      setDeleteStatus(true);
      return request;
    }
    deleteLeave(leaveApplicationId);

    // --- Disable Modal --- //
    setShowDeleteLeave(false);
  };

  return (
    <>
      <div className="dashboard-container">
        <main className="profilescreen-wrapper">
          <div>
            <Modal
              show={showdeleteLeave}
              onHide={handleDeleteClose}
              backdrop="static"
              keyboard={false}
              className="kpi-modal">
              <ModalHeader
                setLgShow={setShowDeleteLeave}
                icon={<GoBriefcase size={30} />}
                title={"Leave"}
                subtitle={"Delete Leave Application"}
              />
              <Modal.Body>
                <p>Are you Sure?</p>
                <p>This action cannot be reversed</p>
                <p className="last-line">
                  Ensure you've "Checked" before you hit "Yes"
                </p>
                <input
                  type="button"
                  value="No"
                  className="KPI-close-btn"
                  onClick={handleDeleteClose}
                />
                <input
                  type="button"
                  value="Yes"
                  className="KPI-submit-btn"
                  onClick={deleteLeaveHandler}
                />
              </Modal.Body>
            </Modal>
            {deleteStatus ? (
              <div className="info-status">
                <i className="fas fa-trash-alt fa-2x" />
                <p>Your leave appllication have been deleteed successfully!</p>
                <p>
                  Click the button below to navigate back to Apply for leave
                </p>
                <NavLink to="/myleave" className="KPI-submit-btn">
                  My Leave
                </NavLink>
              </div>
            ) : successUpdate ? (
              <div className="info-status">
                <i className="fas fa-check-circle fa-2x" />
                <p>Thank you!</p>
                <p>This assessment has been updated successfully</p>
                <p>
                  Click the button below to navigate back to Apply for leave
                </p>
                <NavLink to="/myleave" className="KPI-submit-btn">
                  My Leave
                </NavLink>
              </div>
            ) : (
              <div className="leave-body">
                <div className="allLeave-title">
                  <h3>UPDATE LEAVE</h3>
                </div>
                {errorUpdate && (
                  <Message
                    variant="danger"
                    className="error-border"
                    dismissible>
                    {errorUpdate}
                  </Message>
                )}
                <Form onSubmit={updateMyLeaveHandler} className="form-shadow">
                  <Form.Row>
                    <Form.Group as={Col} controlId="leaveType">
                      <Form.Label>Leave Type</Form.Label>
                      <Form.Control
                        type="text"
                        value={leaveType}
                        onChange={(e) =>
                          setLeaveType(e.target.value)
                        }></Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="noOfDays">
                      <Form.Label>No Of Days</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        value={noOfDays}
                        onChange={(e) =>
                          setNoOfDays(e.target.value)
                        }></Form.Control>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="startDate">
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={fromDate}
                        onChange={(e) =>
                          setLeaveStartDate(e.target.value)
                        }></Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="endDate">
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={toDate}
                        onChange={(e) =>
                          setLeaveEndDate(e.target.value)
                        }></Form.Control>
                    </Form.Group>
                  </Form.Row>
                  <Form.Group controlId="description">
                    <Form.Label>
                      Detailed Reason For Leave Application
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      style={{ backgroundColor: "var(--input-field-color)" }}
                      value={reasonForLeave}
                      onChange={(e) => setLeaveDescription(e.target.value)}
                    />
                  </Form.Group>
                  {approved ? (
                    <Link
                      to="/myleave"
                      className="btn btn-light my-3 go-back-btn">
                      APPROVED || Go Back
                    </Link>
                  ) : rejected ? (
                    <Link
                      to="/myleave"
                      className="btn btn-light my-3 go-back-btn">
                      REJECTED || Go Back
                    </Link>
                  ) : (
                    <>
                      <Button
                        className="applyleave-btn "
                        type="submit"
                        value="Update">
                        {loadingUpadte ? "Updating..." : "Update"}
                      </Button>
                      <Button
                        onClick={handleDeleteShow}
                        type="button"
                        className="applyleave-btn "
                        style={{ background: "#E2522E" }}>
                        Delete
                      </Button>
                    </>
                  )}
                </Form>
              </div>
            )}
            <Footer />
          </div>
        </main>
      </div>
    </>
  );
};

export default EmployeeUpdateLeaveScreen;
