import axios from 'axios'
import { GET_EMPLOYEE_ASSESSMENTS_REQUEST, GET_EMPLOYEE_ASSESSMENTS_SUCCESS, GET_EMPLOYEE_ASSESSMENTS_FAIL, ALL_PERFORMANCE_ASSESSMENTS_REQUEST, ALL_PERFORMANCE_ASSESSMENTS_SUCCESS, ALL_PERFORMANCE_ASSESSMENTS_FAIL, UPDATE_TEAM_ASSESSMENT_ID_REQUEST, UPDATE_TEAM_ASSESSMENT_ID_SUCCESS, UPDATE_TEAM_ASSESSMENT_ID_FAIL, GET_PERFORMANCE_ASSESSMENT_ID_REQUEST, GET_PERFORMANCE_ASSESSMENT_ID_SUCCESS, GET_PERFORMANCE_ASSESSMENT_ID_FAIL, TEAM_PERFORMANCE_ASSESSMENTS_REQUEST, TEAM_PERFORMANCE_ASSESSMENTS_SUCCESS, TEAM_PERFORMANCE_ASSESSMENTS_FAIL, PERFORMANCE_ASSESSMENT_CREATE_REQUEST, PERFORMANCE_ASSESSMENT_CREATE_SUCCESS, PERFORMANCE_ASSESSMENT_CREATE_FAIL, EMPLOYEE_UPDATE_KPI_REQUEST, EMPLOYEE_UPDATE_KPI_SUCCESS, EMPLOYEE_UPDATE_KPI_FAIL } from '../constants/performanceConstants';
import { baseUrl } from '../shared/baseUrl';

export const createPerformanceAction = (
  performanceFactor,
  performanceRating,
  quarter,
  year,
  employeePerformanceAverage,
  employeeComment,
  reportsTo
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PERFORMANCE_ASSESSMENT_CREATE_REQUEST
    })

    const { userLogin: { userInfo } } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    await axios.post(
      baseUrl + '/api/v1/kpis', {
      performanceFactor,
      performanceRating,
      quarter,
      year,
      employeePerformanceAverage,
      employeeComment,
      reportsTo
    }, config)

    dispatch({
      type: PERFORMANCE_ASSESSMENT_CREATE_SUCCESS,
      success: true,
    })

  } catch (error) {
    dispatch({
      type: PERFORMANCE_ASSESSMENT_CREATE_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    })
  }
}


export const getTeamPerformancesAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: TEAM_PERFORMANCE_ASSESSMENTS_REQUEST
    })
    const { userLogin: { userInfo } } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    const { data } = await axios.get(
      baseUrl + '/api/v1/kpis/team', config)

    dispatch({
      type: TEAM_PERFORMANCE_ASSESSMENTS_SUCCESS,
      payload: data
    })

  } catch (error) {
    dispatch({
      type: TEAM_PERFORMANCE_ASSESSMENTS_FAIL,
      payload: error.response && error.response.data.message ?
        error.response.data.message : error.message,
    })
  }
}


export const getPerformanceByIdAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_PERFORMANCE_ASSESSMENT_ID_REQUEST
    })
    const { userLogin: { userInfo } } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    const { data } = await axios.get(
      baseUrl + `/api/v1/kpis/${id}`, config)

    dispatch({
      type: GET_PERFORMANCE_ASSESSMENT_ID_SUCCESS,
      payload: data
    })

  } catch (error) {
    dispatch({
      type: GET_PERFORMANCE_ASSESSMENT_ID_FAIL,
      payload: error.response && error.response.data.message ?
        error.response.data.message : error.message,
    })
  }
}


export const updateTeamPerformanceByIdAction = (performance) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_TEAM_ASSESSMENT_ID_REQUEST
    })
    const { userLogin: { userInfo } } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    await axios.put(
      baseUrl + `/api/v1/kpis/${performance._id}/endorsekpi`, performance, config)

    dispatch({
      type: UPDATE_TEAM_ASSESSMENT_ID_SUCCESS,
      success: true,
    })

  } catch (error) {
    dispatch({
      type: UPDATE_TEAM_ASSESSMENT_ID_FAIL,
      payload: error.response && error.response.data.message ?
        error.response.data.message : error.message,
    })
  }
}


export const getAllPerformanceAssessmentsAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ALL_PERFORMANCE_ASSESSMENTS_REQUEST
    })
    const { userLogin: { userInfo } } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    const { data } = await axios.get(
      baseUrl + '/api/v1/hrs/allkpis', config)

    dispatch({
      type: ALL_PERFORMANCE_ASSESSMENTS_SUCCESS,
      payload: data
    })

  } catch (error) {
    dispatch({
      type: ALL_PERFORMANCE_ASSESSMENTS_FAIL,
      payload: error.response && error.response.data.message ?
        error.response.data.message : error.message,
    })
  }
}

export const getEmployeeAssessmentsAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_EMPLOYEE_ASSESSMENTS_REQUEST
    })
    const { userLogin: { userInfo } } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    const { data } = await axios.get(
      baseUrl + '/api/v1/kpis', config)

    dispatch({
      type: GET_EMPLOYEE_ASSESSMENTS_SUCCESS,
      payload: data
    })

  } catch (error) {
    dispatch({
      type: GET_EMPLOYEE_ASSESSMENTS_FAIL,
      payload: error.response && error.response.data.message ?
        error.response.data.message : error.message,
    })
  }
}
export const employeeUpdateKPIAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: EMPLOYEE_UPDATE_KPI_REQUEST
    })
    const { userLogin: { userInfo } } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }
    const { data } = await axios.get(
      baseUrl + '/api/v1/kpis', config)

    dispatch({
      type: EMPLOYEE_UPDATE_KPI_SUCCESS,
      payload: data
    })

  } catch (error) {
    dispatch({
      type: EMPLOYEE_UPDATE_KPI_FAIL,
      payload: error.response && error.response.data.message ?
        error.response.data.message : error.message,
    })
  }
}