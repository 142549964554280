import React from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Button } from "@material-ui/core";

const ReportDownloader = ({ data, userInfo }) => {
  const headers = [
    { label: "Full Name", key: "fullname" },
    { label: "Employee Code", key: "userInfo.employeeCode" },
    { label: "Date Registered", key: "createdAt" },
    { label: "Quarter", key: "quarter" },
    { label: "Line Manager", key: "firstname" },
    { label: "Line Manager Comment", key: "reportsToComment" },
    { label: "Recommendations", key: "recommendations" },
    { label: "Development Plan", key: "developmentPlan" },
    { label: "Employee Comment", key: "employeeComment" },
    { label: "Employee Average", key: "employeePerformanceAverage" },
    { label: "Supervisor Average", key: "reportsToPerformanceAverage" },
    { label: "Total Average", key: "performanceAverage" },
  ];

  const employeeCode = userInfo;

  const loopData = (data) => {
    const newData = [];
    data?.forEach((item) => {
      newData?.push({
        fullname: item.employee?.firstname + "  " + item.employee.lastname,
        employeeCode: employeeCode.userInfo?.employeeCode,
        createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
        quarter: item.quarter,
        firstname: item.reportsTo?.firstname,
        reportsToComment: item.reportsToComment,
        recommendations: item.recommendations,
        developmentPlan: item.developmentPlan,
        employeeComment: item.employeeComment,
        employeePerformanceAverage: item.employeePerformanceAverage + "%",
        reportsToPerformanceAverage: item.reportsToPerformanceAverage + "%",
        performanceAverage: item.performanceAverage + "%",
      });
    });
    return newData;
  };
  const exportData = loopData(data);

  return (
    <CSVLink
      data={exportData}
      headers={headers}
      filename={"KPI-Performance Report.csv"}>
      <Button className="CSV-button">Download-csv</Button>
    </CSVLink>
  );
};

export default ReportDownloader;
