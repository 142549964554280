import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { getJobByIdAction } from "../../actions/jobApplicantsAction";

const GetJobApplication = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const jobApplicationId = id;

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const getJobById = useSelector((state) => state.getJobById);
  const { job } = getJobById;

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role === "Human Resource Executive" ||
        userInfo.role === "Head Of Department - HR" ||
        userInfo.role === "CEO" ||
        userInfo.role === "Super Admin" ||
        userInfo.role === "Assistant Manager - Human Resources" ||
        userInfo.role === "Human Resource Manager")
    ) {
      if (!job || job._id !== jobApplicationId) {
        dispatch(getJobByIdAction(jobApplicationId));
      }
    } else {
      navigate("/");
    }
  }, [jobApplicationId, userInfo, job, navigate, dispatch]);

  return (
    <div className="container-get-job-appli">
      <div class="get-appli-wrapper mt-5">
        <Card className="get-appli-profile-card">
          <Card.Header className="text-center">
            <h3>{job?.fullname} - Application Details</h3>
          </Card.Header>
          <Card.Body className="nopadding">
            <div className="getjob-application-body">
              <div className="alljobs-body">
                <div className="getjob-application-details">
                  <p>Full Name</p>
                  <p>{job?.fullname}</p>
                  <p>Phone Number</p>
                  <p>{job?.phoneNumber}</p>
                  <p>Email</p>
                  <p>{job?.email}</p>
                  <p>Age</p>
                  <p>{job?.age}</p>
                  <p>Address</p>
                  <p>{job?.address}</p>
                  <p>Job Location</p>
                  <p>{job?.jobLocation}</p>
                  <p>Educational Qualification</p>
                  <p>{job?.educationalQualification}</p>
                  <p>Nysc Status</p>
                  <p>{job?.nyscStatus}</p>
                  <p>Operating Window</p>
                  <p>{job?.operatingWindow}</p>
                  <p>Years Of Experience</p>
                  <p>{job?.yearsOfExperience}</p>
                  <a href={job?.resumeURL} target="_blank" rel="noreferrer">
                    Resume
                  </a>
                </div>
                <div className="spoken-languages">
                  <p>Spoken Languages</p>
                  <div className="getjob-application-details">
                    <p>English</p>
                    <p>
                      {job?.englishLanguage ? (
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "green" }}
                        />
                      ) : (
                        <i
                          className="fas fa-times-circle"
                          style={{ color: "#e2522e" }}
                        />
                      )}
                    </p>
                    <p>Igbo</p>
                    <p>
                      {job?.igboLanguage ? (
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "green" }}
                        />
                      ) : (
                        <i
                          className="fas fa-times-circle"
                          style={{ color: "#e2522e" }}
                        />
                      )}
                    </p>
                    <p>Yoruba</p>
                    <p>
                      {job?.yorubaLanguage ? (
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "green" }}
                        />
                      ) : (
                        <i
                          className="fas fa-times-circle"
                          style={{ color: "#e2522e" }}
                        />
                      )}
                    </p>
                    <p>Hausa</p>
                    <p>
                      {job?.hausaLanguage ? (
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "green" }}
                        />
                      ) : (
                        <i
                          className="fas fa-times-circle"
                          style={{ color: "#e2522e" }}
                        />
                      )}
                    </p>
                    <p>French</p>
                    <p>
                      {job?.frenchLanguage ? (
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "green" }}
                        />
                      ) : (
                        <i
                          className="fas fa-times-circle"
                          style={{ color: "#e2522e" }}
                        />
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
          <Card.Footer className="nopadding">
            <div className="details-back-btn">
              <NavLink to="/alljobapplication" className="getjob-details-btn">
                <i className="fas fa-long-arrow-alt-left pr-2" />
                Back
              </NavLink>
            </div>
          </Card.Footer>
        </Card>
      </div>
    </div>
  );
};
export default GetJobApplication;
