import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import hello from "../../img/hello.svg";
import { getEmployeeAssessmentsAction } from "../../actions/performanceActions";
import { PERFORMANCE_ASSESSMENT_CREATE_RESET } from "../../constants/performanceConstants";
import PolicyModal from "../../components/PolicyModal";
import UserChat from "../Dashboard/UserChat";

import { NoRecordFound, TableFetch } from "../../components/TableOptions";
const HomeScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const getEmployeeAssessments = useSelector(
    (state) => state.getEmployeeAssessments
  );
  const { data: infoData, loading } = getEmployeeAssessments;

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  useEffect(() => {
    if (userInfo) {
      dispatch(getEmployeeAssessmentsAction());
      dispatch({
        type: PERFORMANCE_ASSESSMENT_CREATE_RESET,
      });
    } else {
      navigate("/");
    }
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    setData(infoData);
  }, [infoData]);

  return (
    <div className="dashboard-container">
      <PolicyModal />
      <div className="dashboard-body">
        <div className="dashboard-title">
          <img src={hello} alt="hello" />
          <div className="dashboard-greeting">
            <h1>Hello {userInfo?.firstname}</h1>
            <p>Welcome to your admin dashboard</p>
          </div>
        </div>
        <div className="dashboard-cards">
          <div className="card">
            <i className="fas fa-user-check fa-2x"></i>
            <div className="card-inner">
              <p>Attendance</p>
              <span className="font-bold text-title"></span>
            </div>
          </div>

          <div className="card">
            <i className="fa fa-calendar fa-2x"></i>
            <div className="card-inner">
              <p>Conversions</p>
              <span className="font-bold text-title"></span>
            </div>
          </div>

          <div className="card">
            <i className="fas fa-chart-line fa-2x"></i>
            <div className="card-inner">
              <p>Average Perfomance</p>
              <span className="font-bold text-title"></span>
            </div>
          </div>

          <div className="card">
            <i className="fas fa-briefcase fa-2x"></i>
            <div className="card-inner">
              <p>Leave Balance: {userInfo?.leaveDays} Days</p>
              <span className="font-bold text-title"></span>
            </div>
          </div>
        </div>
        <div className="charts">
          <div className="charts-left">
            <div className="charts-left-title">
              <div>
                <h1>KPI</h1>
                <p>My Performance Assessments</p>
              </div>
              <i className="fa fa-usd"></i>
            </div>
            <div id="reports">
              {/* <h5 className="page-title">All Performance Assessments</h5> */}
              <div className="half-background">
                <section className="md-ui component-data-table">
                  <div
                    className="main-table-wrapper"
                    style={{ height: "300px" }}>
                    <table className="main-table-content">
                      <thead className="data-table-header">
                        <tr className="data-table-row">
                          <th>Year</th>
                          <th>Quarter</th>
                          <th>Average</th>
                          <th>Updated</th>
                          <th> ... </th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <TableFetch colSpan={8} />
                        ) : data?.length === 0 || data == null ? (
                          <NoRecordFound colSpan={8} />
                        ) : (
                          data?.map((user) => (
                            <tr key={user._id}>
                              <td>{user.year}</td>
                              <td>{user.quarter}</td>
                              <td className="table-avgScore">
                                {user.performanceAverage > 0 ? (
                                  <>{user.performanceAverage}%</>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>
                                {user.reportsToUpdate ? (
                                  <i
                                    className="fas fa-check-circle"
                                    style={{ color: "green" }}
                                  />
                                ) : (
                                  <i
                                    className="fas fa-times-circle"
                                    style={{ color: "#e2522e" }}
                                  />
                                )}
                              </td>
                              <td>
                                <NavLink
                                  to={`/kpi/${user._id}/summary`}
                                  exact
                                  className="update-btn rounded-5"
                                  style={{
                                    background: "#E2522E",
                                    boxShadow: "none",
                                  }}>
                                  View
                                </NavLink>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div className="charts-right" style={{ background: "#fff" }}>
            <div className="charts-right-title">
              <div>
                <h1>daily Reports</h1>
                <p>Opebi, Lagos, Nigeria</p>
              </div>
              <i className="fa fa-usd"></i>
            </div>

            <div className="charts-right-cards">
              <UserChat resultArray={data} />
              {/* <div className="card1">
                <h1>Income</h1>
                <p></p>
              </div> */}
              {/* <div className="card2">
                <h1>Sales</h1>
                <p></p>
              </div> */}
              {/* <div className="card3">
                <h1>Users</h1>
                <p></p>
              </div> */}
              {/* <div className="card4">
                <h1>Plans</h1>
                <p></p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
