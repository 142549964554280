import { MdOutlineErrorOutline } from "react-icons/md";
import { VscCloudDownload } from "react-icons/vsc";
import { Button } from "@material-ui/core";
import { IoIosSearch } from "react-icons/io";
import { RiDownloadLine } from "react-icons/ri";
import LeaveDownloader from "../components/LeaveDownloader";
import KpiPdf from "../components/KpiPdf";
import ReportDownloader from "../components/ReportDownloader";
import { CSVLink } from "react-csv";
// import filter from "../assets/images/Filter.svg";

// EntriesPerPage
const EntriesPerPage = ({ data, entriesPerPage, setEntriesPerPage }) => (
  <div className="entries-perpage">
    {data?.length > 1 && (
      <>
        Show
        <select
          value={entriesPerPage}
          onChange={(e) => setEntriesPerPage(e.target.value)}>
          <option value="5">5</option>
          <option value="8">8</option>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        entries
      </>
    )}
  </div>
);

// EntriesPerPage
const EmployeeStatus = ({ status, setStatus, roles, setRole }) => (
  <div className="entries-perpage">
    <>
      Filter by
      <select value={status} onChange={(e) => setStatus(e.target.value)}>
        <option value="in review">in review</option>
        <option value="pending">pending</option>
        <option value="engaged">engaged</option>
      </select>
      Status
    </>
    <select value={roles} onChange={(e) => setRole(e.target.value)}>
      <option value="">All Roles</option>
      {roles &&
        roles?.map((role) => (
          <option key={role.id} value={role.id}>
            {role.name}
          </option>
        ))}
    </select>
    Role
    {/* <select value={departments} onChange={(e) => setDepartment(e.target.value)}>
      <option value="">All Departments</option>
      {departments.map((department: any) => (
        <option key={department.id} value={department.id}>
          {department.name}
        </option>
      ))}
    </select>
    Department */}
    {/* )} */}
  </div>
);

// TableFetch
const TableFetch = ({ colSpan }) => (
  <tr>
    <td colSpan={colSpan} className="table-loader">
      <VscCloudDownload size={75} />
      <p className="mt-3">Fetching request...</p>
    </td>
  </tr>
);
// NoRecordFound
const NoRecordFound = ({ colSpan }) => (
  <tr>
    <td colSpan={colSpan} className="table-loader">
      <MdOutlineErrorOutline size={75} />
      <p className="mt-3">No record found</p>
    </td>
  </tr>
);

const SearchComponent = ({
  sortData,
  entriesPerPage,
  setEntriesPerPage,
  placeholder,
  setSelectedOption,
  CSV,
  value,
  onChange,
  LeaveDownloadert,
  LeaveDownloaderdata,
  userInfo,
  ReportDownloaderd,
  KpiPdfd,
  performance,
  CSVLinkd,
  CSVLinkdt,
}) => {
  return (
    <div className="half-background-input">
      <div style={{ display: "flex", alignItems: "center" }}></div>

      <div>
        <EntriesPerPage
          data={sortData}
          entriesPerPage={entriesPerPage}
          setEntriesPerPage={setEntriesPerPage}
        />
      </div>

      <div
        className="pay_parameter"
        style={{ display: "flex", alignItems: "center" }}>
        {CSV && (
          <Button className="CSV-button">
            <RiDownloadLine size={15} /> CSV
          </Button>
        )}

        {CSVLinkdt && (
          <Button className="CSV-button">
            <CSVLink
              filename={"All-Job-Application.csv"}
              data={CSVLinkd}
              asyncOnClick={true}
              onClick={(event, done) => {}}>
              Export
            </CSVLink>
          </Button>
        )}

        {performance && (
          <ReportDownloader data={ReportDownloaderd} userInfo={userInfo} />
        )}
        {performance && <KpiPdf data={KpiPdfd} userInfo={userInfo} />}
        {LeaveDownloadert && (
          <LeaveDownloader data={LeaveDownloaderdata} userInfo={userInfo} />
        )}

        <div id="mix-input-icon">
          <input
            style={{ border: "0px" }}
            id="mix-input-icon-Button-input"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
          <Button id="mix-input-icon-Button">
            <IoIosSearch size={20} />
          </Button>
        </div>
      </div>
    </div>
  );
};

const InputOne = ({ setSelectedValue, selectedValue }) => {
  const options = [
    "Employees",
    "Role",
    "Category",
    "Department",
    "Expatriate",
    "Location",
  ];

  // Handle the change event and update the selected value
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div className="input  mt-3">
      <label className={"input__label"}>{"Select Employee By "}</label>
      <select
        id="Modal-textarea-input-sub"
        className="input-perameter-color"
        value={selectedValue}
        onChange={handleSelectChange}>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export {
  TableFetch,
  EntriesPerPage,
  EmployeeStatus,
  NoRecordFound,
  SearchComponent,
  InputOne,
};
