import React from "react";
import HomeScreen from "./HomeScreen";
import AdminDashboard from "./AdminDashboard";
import getUserPrivileges from "../../functions/auth";

const DashboardContainer = () => {
  const {
    isCEO,
    isHRHead,
    isSuperAdmin,
    isHRAssistantManager,
    isHRManager,
    isHRExecutive,
  } = getUserPrivileges();

  return (
    <div className="h-100">
      {isSuperAdmin ||
      isHRHead ||
      isCEO ||
      isCEO ||
      isHRAssistantManager ||
      isHRManager ||
      isHRExecutive ? (
        <AdminDashboard />
      ) : (
        <HomeScreen />
      )}
    </div>
  );
};
export default DashboardContainer;
