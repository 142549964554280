export const AGENT_LEAVE_APPLICATION_CREATE_REQUEST = 'AGENT_LEAVE_APPLICATION_CREATE_REQUEST';
export const AGENT_LEAVE_APPLICATION_CREATE_SUCCESS = 'AGENT_LEAVE_APPLICATION_CREATE_SUCCESS';
export const AGENT_LEAVE_APPLICATION_CREATE_FAIL = 'AGENT_LEAVE_APPLICATION_CREATE_FAIL';
export const AGENT_LEAVE_APPLICATION_CREATE_RESET = 'AGENT_LEAVE_APPLICATION_CREATE_RESET';

export const LEAVE_APPLICATION_DETAILS_AGENT_REQUEST = 'LEAVE_APPLICATION_DETAILS_AGENT_REQUEST';
export const LEAVE_APPLICATION_DETAILS_AGENT_SUCCESS = 'LEAVE_APPLICATION_DETAILS_AGENT_SUCCESS';
export const LEAVE_APPLICATION_DETAILS_AGENT_FAIL = 'LEAVE_APPLICATION_DETAILS_AGENT_FAIL';
export const LEAVE_APPLICATION_DETAILS_AGENT_RESET = 'LEAVE_APPLICATION_DETAILS_AGENT_RESET';

export const AGENT_LEAVE_APPLICATION_DETAILS_REQUEST = 'AGENT_LEAVE_APPLICATION_DETAILS_REQUEST';
export const AGENT_LEAVE_APPLICATION_DETAILS_SUCCESS = 'AGENT_LEAVE_APPLICATION_DETAILS_SUCCESS';
export const AGENT_LEAVE_APPLICATION_DETAILS_FAIL = 'AGENT_LEAVE_APPLICATION_DETAILS_FAIL';
export const AGENT_LEAVE_APPLICATION_DETAILS_RESET = 'AGENT_LEAVE_APPLICATION_DETAILS_RESET';

export const AGENT_LEAVE_APPLICATIONS_REQUEST = 'AGENT_LEAVE_APPLICATIONS_REQUEST';
export const AGENT_LEAVE_APPLICATIONS_SUCCESS = 'AGENT_LEAVE_APPLICATIONS_SUCCESS';
export const AGENT_LEAVE_APPLICATIONS_FAIL = 'AGENT_LEAVE_APPLICATIONS_FAIL';
export const AGENT_LEAVE_APPLICATIONS_RESET = 'AGENT_LEAVE_APPLICATIONS_RESET';

export const AGENT_LEAVE_APPLICATION_DETAILS_ID_REQUEST = 'AGENT_LEAVE_APPLICATION_DETAILS_ID_REQUEST';
export const AGENT_LEAVE_APPLICATION_DETAILS_ID_SUCCESS = 'AGENT_LEAVE_APPLICATION_DETAILS_ID_SUCCESS';
export const AGENT_LEAVE_APPLICATION_DETAILS_ID_FAIL = 'AGENT_LEAVE_APPLICATION_DETAILS_ID_FAIL';
export const AGENT_LEAVE_APPLICATION_DETAILS_ID_RESET = 'AGENT_LEAVE_APPLICATION_DETAILS_ID_RESET';

export const LEAVE_APPLICATION_UPDATE_AGENT_REQUEST = 'LEAVE_APPLICATION_UPDATE_AGENT_REQUEST';
export const LEAVE_APPLICATION_UPDATE_AGENT_SUCCESS = 'LEAVE_APPLICATION_UPDATE_AGENT_SUCCESS';
export const LEAVE_APPLICATION_UPDATE_AGENT_FAIL = 'LEAVE_APPLICATION_UPDATE_AGENT_FAIL';
export const LEAVE_APPLICATION_UPDATE_AGENT_RESET = 'LEAVE_APPLICATION_UPDATE_AGENT_RESET';