import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPerformanceByIdAction } from "../../actions/performanceActions";
import { Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../shared/baseUrl";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import ModalHeader from "../../components/ModalHeader";
import { GrDocumentPerformance } from "react-icons/gr";
import Loader from "../../components/Loader";

const UserKPIOverview = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const performanceId = id;
  const dispatch = useDispatch();
  const [factors, setFactors] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [hodRating, setHodRatings] = useState([]);
  const [deleteStatus, setDeleteStatus] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const getPerformanceById = useSelector((state) => state.getPerformanceById);
  const { data, loading } = getPerformanceById;

  useEffect(() => {
    if (userInfo) {
      if (!data || data?._id !== performanceId) {
        dispatch(getPerformanceByIdAction(performanceId));
      } else {
        setFactors(data.performanceFactor);
        setRatings(data.performanceRating);
        setHodRatings(data.lineManagerPerformanceRating);
      }
    } else {
      navigate("/");
    }
  }, [dispatch, navigate, data, userInfo, performanceId]);

  // --- Convert factors & ratings to one array{object} --- //
  const performances = (factors, ratings, hodRating) => {
    const assessments = [];
    for (let i = 0; i < factors.length; i++) {
      assessments.push({
        performanceFactor: factors[i],
        performanceRating: ratings[i],
        hodPerformanceRating: hodRating[i],
      });
    }
    return assessments;
  };
  const performanceFields = performances(factors, ratings, hodRating);

  //  performance-modal
  const [showdeleteKPI, setShowDeleteKPI] = useState(false);
  const handleDeleteClose = () => setShowDeleteKPI(false);
  const handleDeleteShow = () => setShowDeleteKPI(true);

  const deleteKPIHandler = () => {
    async function deleteKIP(performanceId) {
      const request = await axios.delete(
        baseUrl + `/api/v1/kpis/${performanceId}/empdelete`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      setDeleteStatus(true);
      return request;
    }
    deleteKIP(performanceId);

    // --- Disable Modal --- //
    setShowDeleteKPI(false);
  };

  return (
    <div className="dashboard-container">
      <div>
        <Modal
          show={showdeleteKPI}
          onHide={handleDeleteClose}
          backdrop="static"
          keyboard={false}
          className="kpi-modal">
          <ModalHeader
            setLgShow={setShowDeleteKPI}
            icon={<GrDocumentPerformance size={30} />}
            title={"KPI"}
            subtitle={"Delete KPI Assessment"}
          />
          <Modal.Body>
            <p>Are you Sure?</p>
            <p>This action cannot be reversed</p>
            <p className="last-line">
              Ensure you've "Checked" before you hit "Yes"
            </p>
            <input
              type="button"
              value="No"
              className="KPI-close-btn"
              onClick={handleDeleteClose}
            />
            <input
              type="button"
              value="Yes"
              className="KPI-submit-btn"
              onClick={deleteKPIHandler}
            />
          </Modal.Body>
        </Modal>
        {deleteStatus ? (
          <div className="info-status">
            <i className="fas fa-trash-alt fa-2x" />
            <p>Your KPI Assessment have been deleteed successfully!</p>
            <p>Click the button below to navigate back to KPI Assessment </p>
            <NavLink to="/kpi/user" className="KPI-submit-btn">
              KPI Assessment
            </NavLink>
          </div>
        ) : (
          <div className="performance-screen-wrapper">
            <div className="kpi-body">
              <div className="kpi-performance-section">
                {!data?.reportsToUpdate && (
                  <input
                    type="button"
                    value="Delete"
                    onClick={handleDeleteShow}
                    className="KPI-submit-btn"
                    style={{ marginBottom: "8px", marginLeft: "535px" }}
                  />
                )}
                {loading && <Loader />}
                <div className="key-performance-box">
                  <div className="key-perf-title per-title">
                    <p>Performance Factor</p>
                    <p>Staff</p>
                    <p>HOD</p>
                  </div>
                  <div className="fields-container">
                    {performanceFields?.map((item, index) => (
                      <div key={index} className="performance-field">
                        <p>{item.performanceFactor}</p>
                        <p>{item.performanceRating}</p>
                        <p>{item.hodPerformanceRating}</p>
                      </div>
                    ))}
                  </div>
                  <div className="key-perf-title ratings">
                    <p>...</p>
                    <p>{data?.employeePerformanceAverage}%</p>
                    <p>
                      {data?.reportsToPerformanceAverage > 0 ? (
                        <>{data?.reportsToPerformanceAverage}%</>
                      ) : (
                        "-"
                      )}
                    </p>
                  </div>
                  <div className="development-plan">
                    <p>Development Plan</p>
                    <p>{data?.developmentPlan}</p>
                  </div>
                </div>
              </div>
              <div className="kpi-legend">
                <div className="performance-info">
                  <p>Name</p>
                  <p>
                    {data?.employee?.firstname} {data?.employee?.lastname}
                  </p>
                  <p>Emp ID</p>
                  <p>{data?.employee?.employeeCode}</p>
                  <p>Email</p>
                  <p>{data?.employee?.email}</p>
                  <p>Line Manager</p>
                  <p>
                    {data?.reportsTo?.firstname} {data?.reportsTo?.lastname}
                  </p>
                  <p>Date</p>
                  <p>{moment(data?.createdAt).format("DD-MM-YYYY")}</p>
                  <p>Quarter</p>
                  <p>{data?.quarter}</p>
                  <p>Average</p>
                  <p>
                    {data?.performanceAverage > 0 ? (
                      <>{data?.performanceAverage}%</>
                    ) : (
                      "-"
                    )}
                  </p>
                </div>
                <div className="other-assessments">
                  {data?.reportsToUpdate && (
                    <>
                      <p>Recommendations</p>
                      <ul>
                        {data?.recommendations.map(function (recommend) {
                          return <li>{recommend}</li>;
                        })}
                      </ul>
                    </>
                  )}
                  <p>{data?.employee?.firstname}'s Comment</p>
                  <textarea rows="3" disabled value={data?.employeeComment} />
                  <p>{data?.reportsTo?.firstname}'s Comment (HOD)</p>
                  <textarea rows="3" disabled value={data?.reportsToComment} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default UserKPIOverview;
