import axios from "axios";
import { baseUrl } from "../shared/baseUrl";
import {
  ADMIN_CREATE_JOB_POST_REQUEST,
  ADMIN_CREATE_JOB_POST_SUCCESS,
  ADMIN_CREATE_JOB_POST_FAIL,
  GET_ALL_JOB_POST_BY_ID_REQUEST,
  GET_ALL_JOB_POST_BY_ID_SUCCESS,
  GET_ALL_JOB_POST_BY_ID_FAIL,
  UPDATE_JOB_POST_BY_ID_REQUEST,
  UPDATE_JOB_POST_BY_ID_SUCCESS,
  UPDATE_JOB_POST_BY_ID_FAIL,
} from "../constants/createJobPostContants";

export const jobPostAction =
  (
    jobTitle,
    jobLocation,
    qualifications,
    jobOverview,
    responsilities,
    benefits
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_CREATE_JOB_POST_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        baseUrl + "/api/v1/career",
        {
          jobTitle,
          jobLocation,
          qualifications,
          jobOverview,
          responsilities,
          benefits,
        },
        config
      );

      dispatch({
        type: ADMIN_CREATE_JOB_POST_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_CREATE_JOB_POST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getJobPostByIdAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ALL_JOB_POST_BY_ID_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(baseUrl + `/api/v1/career/${id}`, config);

    dispatch({
      type: GET_ALL_JOB_POST_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_JOB_POST_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateJobPostByIdAction =
  (postID) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_JOB_POST_BY_ID_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await axios.put(baseUrl + `/api/v1/career/${postID._id}`, postID, config);

      dispatch({
        type: UPDATE_JOB_POST_BY_ID_SUCCESS,
        success: true,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_JOB_POST_BY_ID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
