import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Table, Row, Col } from "react-bootstrap";
import { getUserDetailsById } from "../../actions/userActions";
import FixedNavbar from "../../components/FixedNavbar";
import { useParams, useNavigate } from "react-router-dom";
import { NoRecordFound, TableFetch } from "../../components/TableOptions";

const HrHomeScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = id;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetailsById = useSelector((state) => state.userDetailsById);
  const { user, loading } = userDetailsById;

  useEffect(() => {
    if (!userInfo && userInfo.role !== "hr") {
      navigate("/");
    } else {
      dispatch(getUserDetailsById(userId));
    }
  }, [navigate, userInfo, userId, dispatch]);

  return (
    <>
      <Row className="ml-4 mr-4 py-4">
        <Col md={3}>
          <FixedNavbar />
        </Col>
        <Col md={8}>
          <Table
            striped
            bordered
            hover
            reesponsive
            className="table-sm mr-4 ml-3">
            <thead>
              <tr>
                <th>EMPLOYEE ID</th>
                <th>FIRST NAME</th>
                <th>MIDDLE NAME</th>
                <th>LAST NAME</th>
                <th>EMAIL</th>
                <th>ROLE</th>
                <th>DEPARTMENT</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <TableFetch colSpan={8} />
              ) : user?.length === 0 || user == null ? (
                <NoRecordFound colSpan={8} />
              ) : (
                user?.map((user) => (
                  <tr key={user.employee._id}>
                    <td>{user.employee.employeeCode}</td>
                    <td>{user.employee.firstname}</td>
                    <td>{user.employee.middlename}</td>
                    <td>{user.employee.lastname}</td>
                    <td>
                      <a href={`mailto:${user.employee.email}`}>
                        {user.employee.email}
                      </a>
                    </td>
                    <td>{user.employee.role}</td>
                    <td>{user.employee.department}</td>
                    {userInfo && (
                      <td>
                        {/* <LinkContainer to={`/dashboard`}>
                      <Button variant="light" className="btn-sm">
                        <i className="fas fa-edit"></i>
                      </Button>
                    </LinkContainer> */}
                      </td>
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default HrHomeScreen;
