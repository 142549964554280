import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { createMessages, employeeMessage } from "../../actions/messageActions";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import {
  NoRecordFound,
  SearchComponent,
  TableFetch,
} from "../../components/TableOptions";
import Pagination from "../../components/Pagination";
import { useNavigate } from "react-router-dom";
import ModalHeader from "../../components/ModalHeader";
import { BiMessageRoundedDetail } from "react-icons/bi";

const SupportService = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [displayData, setDisplayData] = useState([]);

  // -- Show New Message
  const [showNewMsg, setShowNewMsg] = useState(false);
  const handleCloseNewMsg = () => setShowNewMsg(false);
  const handleShowNewMsg = () => setShowNewMsg(true);

  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const employeeMessages = useSelector((state) => state.employeeMessages);
  const { data } = employeeMessages;

  const [entriesPerPage, setEntriesPerPage] = useState(() => {
    return localStorage.getItem("rowsPerPage") || "8";
  });

  const createMessage = useSelector((state) => state.createMessage);
  const { success: successCreate, error: errorCreate } = createMessage;

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else {
      dispatch(employeeMessage());
    }
  }, [dispatch, navigate, successCreate, userInfo]);

  const createsubmitHandler = (e) => {
    e.preventDefault(e);
    dispatch(createMessages(title, body));
  };

  return (
    <div id="reports">
      <h5 className="page-title">Support Service</h5>
      <div className="nav-tabs">
        <li className="active" onClick={handleShowNewMsg}>
          New Message
        </li>
      </div>
      <div className="half-background">
        <SearchComponent
          sortData={data}
          entriesPerPage={entriesPerPage}
          setEntriesPerPage={setEntriesPerPage}
          placeholder={"All Support Service"}
        />
        {errorCreate && (
          <Message variant="danger" className="error-border" dismissible>
            {errorCreate}
          </Message>
        )}
        <section className="md-ui component-data-table">
          {/* {loading && <Loader />} */}
          <div className="main-table-wrapper">
            <table className="main-table-content">
              <thead className="data-table-header">
                <tr className="data-table-row">
                  <th>Email Address</th>
                  <th>First Name</th>
                  <th>Date Sent</th>
                  <th>Role</th>
                  <th>Department</th>
                  <th>Subject</th>
                  {userInfo &&
                    (userInfo.role === "Human Resource Executive" ||
                      userInfo.role === "Super Admin" ||
                      userInfo.role === "Assistant Manager - Human Resources" ||
                      userInfo.role === "Human Resource Manager") && <th></th>}
                </tr>
              </thead>
              <tbody className="data-table-content">
                {false ? (
                  <TableFetch colSpan={9} />
                ) : displayData?.length === 0 || displayData == null ? (
                  <NoRecordFound colSpan={9} />
                ) : (
                  displayData?.map((user, i) => (
                    <tr className="data-table-row" key={i}>
                      <td>{user.employee.email}</td>
                      <td>{user.employee.firstname}</td>
                      <td>{moment(user.createdAt).format("DD-MM-YYYY")}</td>
                      <td>{user.employee.role}</td>
                      <td>{user.employee.department}</td>
                      <td>{user.title}</td>
                      <td>
                        <NavLink
                          to={`/support/${user._id}/messaging`}
                          exact
                          className="update-btn rounded-5"
                          style={{ background: "#E2522E", boxShadow: "none" }}>
                          Reply
                        </NavLink>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          {/* --- New MSG Modal ---- */}
          <Modal
            show={showNewMsg}
            onHide={handleCloseNewMsg}
            backdrop="static"
            keyboard={false}
            centered
            className="messaging-modal">
            <ModalHeader
              setLgShow={setShowNewMsg}
              icon={<BiMessageRoundedDetail size={30} />}
              title={"Support"}
              subtitle={"Send a new message"}
            />
            <Modal.Body>
              {errorCreate && (
                <Message variant="danger" className="error-border" dismissible>
                  {errorCreate}
                </Message>
              )}
              <Form onSubmit={createsubmitHandler}>
                <Form.Group controlId="inputText">
                  <Form.Control
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Subject"
                  />
                </Form.Group>
                <Form.Group controlId="inputText">
                  <Form.Control
                    as="textarea"
                    placeholder="Type a message..."
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    rows={5}
                    style={{ backgroundColor: "var(--input-field-color)" }}
                  />
                </Form.Group>
                <Button
                  type="submit"
                  onClick={handleCloseNewMsg}
                  className="msg-btn w-100 mt-3">
                  Send
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </section>
        <footer className="main-table-footer">
          <Pagination
            setDisplayData={setDisplayData}
            data={data}
            entriesPerPage={entriesPerPage}
            Total={"Support Service"}
          />
        </footer>
      </div>
    </div>
  );
};

export default SupportService;
