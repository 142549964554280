import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { TfiAlignJustify } from "react-icons/tfi";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userActions";
import avatar from "../img/avatar.png";
import logo from "../img/outcess-logo.png";
import mobileLogo from "../img/outcess-icon-haf.png";
import MobileSideBar from "./SidebarAndDropdown/MobileSideBar";

const Header = ({ toggleSideNav }) => {
  const dispatch = useDispatch();
  const [dropDown, setDropDown] = useState(false);
  const [network, setnetwork] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  window.addEventListener("offline", (e) => setnetwork("offline"));
  window.addEventListener("online", (e) => setnetwork("online"));
  useEffect(() => {
    if (network === "online") {
      toast.success("You are back online!");
    } else if (network === "offline") {
      toast.error("You have lost internet connection!");
    }
  }, [network]);

  const [isOpen, setIsopen] = useState(false);

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };
  const logoutHandler = () => {
    dispatch(logout());
  };
  return (
    <div
      id="header"
      onMouseLeave={() => {
        setDropDown(false);
      }}>
      <Toaster position="top-center" />
      <div className="header-container">
        <div className="header-left">
          <TfiAlignJustify
            className="mobileSidebarbtn"
            size={25}
            onClick={toggleSideNav}
          />
          <TfiAlignJustify
            className="mobileSidebarbtntwo"
            size={25}
            onClick={ToggleSidebar}
          />

          <div className="header-logo">
            <img src={logo} alt="Outcess" />
          </div>
          <div className="header-logo-mobile">
            <img src={mobileLogo} alt="Outcess" />
          </div>
          <div className="navbar-left">
            <span>{userInfo?.role}</span>
            <span>{userInfo?.email}</span>
          </div>
        </div>

        <div className="hand-noficational-place">
          <div>
            <div
              onClick={() => setDropDown(!dropDown)}
              onMouseEnter={() => setDropDown(true)}>
              <img width="35" src={avatar} alt="avatar" />
              <span className="pl-2">
                {userInfo?.firstname} {userInfo?.lastname}
                <i className="fas fa-chevron-down pl-2"></i>
              </span>
            </div>

            {dropDown ? (
              <div className="dropdown">
                <Nav className="flex-column">
                  <NavLink to="/viewprofile">
                    <i className="far fa-id-card pr-2"></i>
                    View Profile
                  </NavLink>

                  <NavLink to="/updateprofile">
                    <i className="fas fa-cog pr-2"></i>
                    Update Profile
                  </NavLink>

                  <NavLink to="/changepassword">
                    <i className="fas fa-unlock-alt pr-2"></i>
                    Change Password
                  </NavLink>

                  <NavLink
                    to="/"
                    className="drop-logout"
                    onClick={logoutHandler}>
                    <i className="fas fa-power-off pr-2"></i>
                    Logout
                  </NavLink>
                </Nav>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <MobileSideBar ToggleSidebar={ToggleSidebar} isOpen={isOpen} />
    </div>
  );
};

export default Header;
