import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { Button } from "@material-ui/core";

const KpiPdf = ({ data, userInfo }) => {
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(20);
    doc.setTextColor("#e87a5f");

    const title = "KPI Performance Report";
    const headers = [
      [
        "Full Name",
        "Employee Code",
        "Date Registered",
        "Quarter",
        "Line Manager",
        "Line Manager Comment",
        "Recommendations",
        "Development Plan",
        "Employee Comment",
        "Employee Average",
        "Supervisor Average",
        "Total Average",
      ],
    ];

    const kpi = data?.map((elt) => [
      elt.employee.firstname + "  " + elt.employee.lastname,
      userInfo.employeeCode,
      moment(elt.createdAt).format("DD-MM-YYYY"),
      elt.quarter,
      elt.reportsTo.firstname,
      elt.reportsToComment,
      elt.recommendations,
      elt.developmentPlan,
      elt.employeeComment,
      elt.employeePerformanceAverage + "%",
      elt.reportsToPerformanceAverage + "%",
      elt.performanceAverage + "%",
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: kpi,
      theme: "grid",
      color: "red",
      pageBreak: "auto",
      styles: { fontSize: 9 },
      rowPageBreak: "avoid",
      headerStyles: { halign: "center", fillColor: ["#124383"] },
      bodyStyles: { color: ["#000"] },
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Outcess KPI report.pdf");
  };

  return (
    <div>
      <Button onClick={exportPDF} className="CSV-button">
        Generate-Report Pdf
      </Button>
    </div>
  );
};

export default KpiPdf;
