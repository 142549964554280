import { Bar } from "react-chartjs-2";

const Barchat = ({ employees }) => {
  // Group data by department
  const groupedData = employees?.reduce((acc, employee) => {
    const { department } = employee;
    if (!acc[department]) {
      acc[department] = [];
    }
    acc[department].push(employee);
    return acc;
  }, {});

  // Get the length of each group
  const departmentLengths = Object.keys(groupedData).map((department) => ({
    department,
    length: groupedData[department].length,
  }));

  const data = {
    labels: departmentLengths?.map((item) => item.department).splice(0, 9),
    datasets: [
      {
        label: "People in Department",
        data: departmentLengths?.map((item) => item.length).splice(0, 9),
        backgroundColor: "#124383",
        barPercentage: 0.2,
      },
    ],
  };
  return (
    <div className="chat-container">
      <Bar data={data} />
    </div>
  );
};

export default Barchat;
