import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTeamPerformancesAction } from "../../actions/performanceActions";
import moment from "moment";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import {
  NoRecordFound,
  SearchComponent,
  TableFetch,
} from "../../components/TableOptions";
import Pagination from "../../components/Pagination";
import { useNavigate } from "react-router-dom";

const TeamKPIAssessments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const getTeamPerformances = useSelector((state) => state.getTeamPerformances);
  const { data: datainfo, loading, error } = getTeamPerformances;
  const [displayData, setDisplayData] = useState([]);

  const [data, setData] = useState([]);
  const [filtered, setFilterd] = useState([]);
  const [result, setResult] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(() => {
    return localStorage.getItem("rowsPerPage") || "8";
  });

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role === "Head Of Department" ||
        userInfo.role === "Head Of Department - HR" ||
        userInfo.role === "Team Lead" ||
        userInfo.role === "Super Admin" ||
        userInfo.role === "Assistant Manager" ||
        userInfo.role === "QA - Lead" ||
        userInfo.role === "MIS Lead" ||
        userInfo.role === "CEO" ||
        userInfo.role === "Assistant Manager - Human Resources")
    ) {
      dispatch(getTeamPerformancesAction());
    } else {
      navigate("/");
    }
  }, [dispatch, navigate, userInfo]);

  const onChange = (e) => {
    setResult(e.target.value);
  };

  useEffect(() => {
    setFilterd(datainfo);
  }, [datainfo]);

  useEffect(() => {
    const results = filtered?.filter((data) =>
      data?.employee?.email?.toLowerCase().includes(result)
    );
    setData(results);
  }, [result, filtered]);

  return (
    <div id="reports">
      <h5 className="page-title mb-4">Team Performance Assessments</h5>
      <div className="half-background">
        <SearchComponent
          sortData={data}
          entriesPerPage={entriesPerPage}
          setEntriesPerPage={setEntriesPerPage}
          placeholder={"Team Performance Assessments"}
          value={result}
          onChange={onChange}
        />
        <section className="md-ui component-data-table">
          {loading && <Loader />}
          {error && (
            <Message variant="danger" className="error-border">
              {error}
            </Message>
          )}
          <div className="main-table-wrapper">
            <table className="main-table-content">
              <thead className="data-table-header">
                <tr className="data-table-row">
                  <th>Date Registered</th>
                  <th>Quarter</th>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Average Rating</th>
                  <th>Updated</th>
                  <th> ... </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <TableFetch colSpan={8} />
                ) : displayData?.length === 0 || displayData == null ? (
                  <NoRecordFound colSpan={8} />
                ) : (
                  displayData?.map((user) => (
                    <tr key={user?._id}>
                      <td>{moment(user?.createdAt).format("DD-MM-YYYY")}</td>
                      <td>{user?.quarter}</td>
                      <td>
                        {user?.employee?.firstname} {user?.employee?.lastname}
                      </td>
                      <td className="table-email">
                        <a href={`mailto:${user?.employee?.email}`}>
                          {user?.employee?.email}
                        </a>
                      </td>
                      <td className="table-avgScore">
                        {user?.reportsToUpdate ? (
                          <span>{user?.performanceAverage}%</span>
                        ) : (
                          <span>_</span>
                        )}
                      </td>
                      <td>
                        {user?.reportsToUpdate ? (
                          <i
                            className="fas fa-check-circle"
                            style={{ color: "green" }}
                          />
                        ) : (
                          <i
                            className="fas fa-times-circle"
                            style={{ color: "#e2522e" }}
                          />
                        )}
                      </td>
                      <td>
                        {user?.reportsToUpdate ? (
                          <NavLink
                            to={`/kpi/${user?._id}/summary`}
                            exact
                            className="update-btn rounded-5"
                            style={{
                              background: "#E2522E",
                              boxShadow: "none",
                            }}>
                            View
                          </NavLink>
                        ) : (
                          <NavLink
                            to={`/kpi/${user?._id}/review`}
                            exact
                            className="update-btn rounded-5"
                            style={{
                              background: "#E2522E",
                              boxShadow: "none",
                            }}>
                            Update
                          </NavLink>
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </section>
        <footer className="main-table-footer">
          <Pagination
            setDisplayData={setDisplayData}
            data={data}
            entriesPerPage={entriesPerPage}
            Total={"Team Performance Assessments"}
          />
        </footer>
      </div>
    </div>
  );
};

export default TeamKPIAssessments;
