import { Bar } from "react-chartjs-2";

const UserChat = ({ resultArray }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: true,
    },
    scales: {
      x: {
        ticks: {
          color: "#252733",
        },
        grid: {
          display: false,
          borderColor: "#252733",
          backgroundColor: "#005B90",
        },
      },

      y: {
        ticks: {
          padding: 5,
          backgroundColor: " #252733",
        },
        grid: {
          borderDash: [8, 6],
          color: "#adb5bd",
          drawBorder: false,
          drawTicks: false,
        },
      },
    },
  };

  // Function to filter data for a specific year and quarter
  const filterDataForYearAndQuarter = (data, targetYear, targetQuarter) => {
    // Check if data is defined before attempting to filter
    if (data) {
      return data?.filter(
        (item) => item?.year === targetYear && item?.quarter === targetQuarter
      );
    } else {
      // Return an empty array or handle the case when data is undefined
      return [];
    }
  };

  // Function to extract employee performance information for a specific year and quarter
  const getEmployeePerformanceForYearAndQuarter = (
    data,
    targetYear,
    targetQuarter
  ) => {
    // Function to get month name from quarter
    const getMonthNameFromQuarter = (quarter, targetYear) => {
      const monthNumber = parseInt(quarter, 10); // Assuming quarter is a string representation of the month
      return new Date(targetYear, monthNumber - 1, 1).toLocaleString("en-US", {
        month: "long",
      });
    };

    const filteredData = filterDataForYearAndQuarter(
      data,
      targetYear,
      targetQuarter
    );

    // Extract employee performance information with month names
    const employeePerformance = filteredData.map((item) => {
      const monthName = getMonthNameFromQuarter(item?.quarter, targetYear);

      return {
        month: monthName,
        performanceAverage: item?.performanceAverage || 0,
      };
    });

    return employeePerformance;
  };

  // Get the current year and the previous year
  const currentYear = "" + new Date().getFullYear();
  const previousYear = "" + (new Date().getFullYear() - 1);

  // Define the quarters
  const quarters = ["1", "2", "3", "4"];
  const quarterslabel = ["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4"];

  // Example usage for both current and previous years
  const categorizedDataForCurrentYear = {};
  const categorizedDataForPreviousYear = {};

  quarters.forEach((quarter) => {
    categorizedDataForCurrentYear[quarter] =
      getEmployeePerformanceForYearAndQuarter(
        resultArray,
        currentYear,
        quarter
      );

    categorizedDataForPreviousYear[quarter] =
      getEmployeePerformanceForYearAndQuarter(
        resultArray,
        previousYear,
        quarter
      );

    // Replace empty quarters with an array of zeros
    if (!categorizedDataForCurrentYear[quarter]) {
      categorizedDataForCurrentYear[quarter] = Array(quarters.length).fill(0);
    }

    if (!categorizedDataForPreviousYear[quarter]) {
      categorizedDataForPreviousYear[quarter] = Array(quarters.length).fill(0);
    }
  });

  const datasets = [
    {
      label: `My KPI for ${previousYear}`,
      data: quarters.flatMap(
        (quarter) =>
          categorizedDataForPreviousYear[quarter]?.map(
            (item) => item?.performanceAverage
          ) || Array(quarters.length).fill(0)
      ),
      backgroundColor: "#124383",
      barPercentage: 0.2,
    },
    {
      label: `My KPI for ${currentYear}`,
      data: quarters.flatMap(
        (quarter) =>
          categorizedDataForCurrentYear[quarter]?.map(
            (item) => item?.performanceAverage
          ) || Array(quarters.length).fill(0)
      ),
      backgroundColor: "#FF5733",
      barPercentage: 0.2,
    },
  ];

  // Filter quarters and labels for which data is available
  const availableQuarters = quarters.filter((quarter) => {
    return (
      categorizedDataForPreviousYear[quarter]?.length > 0 ||
      categorizedDataForCurrentYear[quarter]?.length > 0
    );
  });

  const availableLabels = availableQuarters.map((quarter) => {
    return quarterslabel[parseInt(quarter) - 1]; // Adjusting for zero-based indexing
  });

  const data = {
    labels: availableLabels, // Assuming you want the quarters on the x-axis
    datasets,
  };

  return (
    <div className="chat-container">
      <Bar data={data} options={options} />
    </div>
  );
};

export default UserChat;
