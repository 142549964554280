import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import LoginScreen from "./screens/Login/LoginScreen";
import DashboardScreen from "./screens/Dashboard/DashboardScreen";
import PasswordScreen from "./screens/Profile/PasswordScreen";
import StaffListScreen from "./screens/EmployeeScreens/StaffListScreen";
import StaffCreateScreen from "./screens/EmployeeScreens/StaffCreateScreen";
import AdminEditScreen from "./screens/AdminScreens/AdminEditScreen";
import DashboardContainer from "./screens/Dashboard/DashboardContainer";
import RoleEditScreen from "./screens/Profile/RoleEditScreen";
import ProfileScreen from "./screens/Profile/ProfileScreen";
import NextOfKinScreen from "./screens/Profile/NextOfKinScreen";
import EducationScreen from "./screens/Profile/EducationScreen";
import HrHomeScreen from "./screens/Dashboard/HrHomeScreen";
import AdminProfileScreen from "./screens/AdminScreens/AdminProfileScreen";
import AdminNextOfKinScreen from "./screens/AdminScreens/AdminNextOfKinScreen";
import AdminEducationScreen from "./screens/AdminScreens/AdminEducationScreen";
import MyLeaveApplicationScreen from "./screens/LeaveApplication/MyLeaveApplicationScreen";
import ResetPasswordScreen from "./screens/Profile/ResetPasswordScreen";
import AllLeaveApplications from "./screens/LeaveApplication/AllLeaveApplications";
import UpdateMyLeave from "./screens/LeaveApplication/UpdateMyLeave";
import AgentLeaveApplicationScreen from "./screens/AgentScreens/AgentLeaveApplicationScreen";
import TeamLeaveApplicationsScreen from "./screens/LeaveApplication/TeamLeaveApplicationsScreen";
import UpdateTeamApplicationScreen from "./screens/LeaveApplication/UpdateTeamApplicationScreen";
import AllAgentLeaveApplicationsScreen from "./screens/AgentScreens/AllAgentLeaveApplicationsScreen";
import ViewProfile from "./screens/Profile/ViewProfile";
import UpdateProfile from "./screens/Profile/UpdateProfileScreen";
import ChangePassword from "./screens/Profile/ChangePassword";
import SupportService from "./screens/Support/SupportService";
import AllSupportService from "./screens/Support/AllSupportService";
import DepartmentLeavesScreen from "./screens/LeaveApplication/DepartmentLeavesScreen";
import ApproveLeaveScreen from "./screens/LeaveApplication/ApproveLeaveScreen";
import EmployeeUpdateLeaveScreen from "./screens/LeaveApplication/EmployeeUpdateLeaveScreen";
import SupportMessaging from "./screens/Support/SupportMessaging";
import UserKPIAssessment from "./screens/KPIScreens/KPIUserAssessment";
import TeamKPIAssessments from "./screens/KPIScreens/KPITeamAssessments";
import HODReviewEmpKPI from "./screens/KPIScreens/KPIHODReview";
import AllKPIAssessment from "./screens/KPIScreens/KPIAllAssessment";
import UserKPIOverview from "./screens/KPIScreens/KPIuserOverview";
import JobApplication from "./screens/JobApplication/JobApplication";
import AllJobApplications from "./screens/JobApplication/AllJobApplications";
import GetJobApplication from "./screens/JobApplication/GetJobApplication";
import LaptopApplication from "./screens/LaptopScreens/LaptopApplication";
import UpdateLaptopApp from "./screens/LaptopScreens/HODUpdateLaptopApp";
import HodGetAllLaptop from "./screens/LaptopScreens/HodGetAllLaptop";
import AdminUpdateLaptop from "./screens/AdminScreens/AdminUpdateLaptop";
import CeoUpdateLaptop from "./screens/LaptopScreens/CeoUpdateLaptop";
import LaptopInfo from "./screens/LaptopScreens/LaptopInfo";
import LaptopInfoUpdate from "./screens/LaptopScreens/LaptopInfoUpdate";
import CreateJobPost from "./screens/JobApplication/CreateJobPost";
import UpdataJobPost from "./screens/JobApplication/UpdataJobPost";
import AllQuoteRequest from "./screens/Profile/AllQuoteRequest";
import Policy from "./screens/Policy/Policy";
import PrivacyPolicy from "./screens/Policy/PrivacyPolicy";
import NotFound from "./screens/404/NotFound";
import Layout from "./components/Layout";
import Protected from "./functions/Protected";
import { useDispatch } from "react-redux";
import { getUserDetails } from "./actions/userActions";

function App() {
  const dispatch = useDispatch();

  const userStrings = localStorage.getItem("userInfo");
  const user = JSON.parse(userStrings);

  useEffect(() => {
    dispatch(getUserDetails("me"));
  }, [dispatch]);
  const auth = (
    <Protected loggedIn={user && user?.token}>
      <Layout />
    </Protected>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginScreen />} />
        <Route path="/resetpassword/:id" element={<ResetPasswordScreen />} />
        <Route path="*" element={<NotFound />} />
        <Route element={auth}>
          <Route path="/home" element={<DashboardContainer />} />
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path="/viewprofile" element={<ViewProfile />} />
          <Route path="/nextofkin" element={<NextOfKinScreen />} />
          <Route path="/education" element={<EducationScreen />} />
          <Route path="/dashboard" element={<DashboardScreen />} />
          <Route path="/myleave" element={<MyLeaveApplicationScreen />} />

          <Route
            path="/employeeupdateleave/:id/update"
            element={<EmployeeUpdateLeaveScreen />}
          />
          <Route path="/agentleave" element={<AgentLeaveApplicationScreen />} />
          <Route
            path="/myteamapplications"
            element={<TeamLeaveApplicationsScreen />}
          />
          <Route
            path="/myteamleaveapplications/:id/update"
            element={<UpdateTeamApplicationScreen />}
          />
          <Route
            path="/mydepartmentapplications"
            element={<DepartmentLeavesScreen />}
          />
          <Route
            path="/mydepartmentapplications/:id/update"
            element={<ApproveLeaveScreen />}
          />

          {/* --- UpdateMyLeave */}
          <Route path="/myleave/:id/update" element={<UpdateMyLeave />} />
          <Route path="/leaveapplications" element={<AllLeaveApplications />} />

          <Route
            path="/allagentleaveapplications"
            element={<AllAgentLeaveApplicationsScreen />}
          />

          <Route path="/admin/password/:id/edit" element={<PasswordScreen />} />
          <Route path="/admin/userlist" element={<StaffListScreen />} />

          <Route path="/admin/register" element={<StaffCreateScreen />} />
          <Route path="/admin/home/:id" element={<HrHomeScreen />} />
          <Route
            path="/admin/profile/:id/edit"
            element={<AdminProfileScreen />}
          />
          <Route
            path="/admin/nextofkin/:id/edit"
            element={<AdminNextOfKinScreen />}
          />
          <Route
            path="/admin/education/:id/edit"
            element={<AdminEducationScreen />}
          />
          <Route path="/admin/user/:id/edit" element={<AdminEditScreen />} />
          <Route path="/admin/user/:id/role" element={<RoleEditScreen />} />

          {/* Tabs section */}
          <Route path="/updateprofile" element={<UpdateProfile />} />
          <Route path="/changepassword" element={<ChangePassword />} />

          {/* --- Support Service --- */}
          <Route path="/supportservice" element={<SupportService />} />
          <Route path="/allsupportservice" element={<AllSupportService />} />
          <Route path="/support/:id/messaging" element={<SupportMessaging />} />

          {/* --- KPI --- */}
          <Route path="/kpi/user" element={<UserKPIAssessment />} />
          <Route path="/kpi/teamassessments" element={<TeamKPIAssessments />} />
          <Route path="/kpi/:id/review" element={<HODReviewEmpKPI />} />
          <Route path="/kpi/allassessments" element={<AllKPIAssessment />} />
          <Route path="/kpi/:id/summary" element={<UserKPIOverview />} />

          {/* Job Application */}
          <Route path="/createjobpost" element={<CreateJobPost />} />
          <Route path="/updatajobpost/:id" element={<UpdataJobPost />} />
          <Route path="/jobapplication" element={<JobApplication />} />
          <Route path="/alljobapplication" element={<AllJobApplications />} />

          <Route
            path="/getjobapplication/user/:id"
            element={<GetJobApplication />}
          />
          <Route path="/laptopapplication/" element={<LaptopApplication />} />
          <Route path="/hodupdate/:id/laptop/" element={<UpdateLaptopApp />} />
          <Route path="/hodgetallaptop/" element={<HodGetAllLaptop />} />
          <Route
            path="/adminapprove/:id/laptop/"
            element={<AdminUpdateLaptop />}
          />
          <Route path="/ceoapprove/:id/laptop/" element={<CeoUpdateLaptop />} />

          <Route path="/laptopinfo" element={<LaptopInfo />} />
          <Route
            path="/laptopinform/:id/update"
            element={<LaptopInfoUpdate />}
          />
          <Route path="/allquoterequest" element={<AllQuoteRequest />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
