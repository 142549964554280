import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";

import { getAllChatMessages } from "../../actions/messageActions";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../components/Loader";
import Message from "../../components/Message";
import {
  NoRecordFound,
  SearchComponent,
  TableFetch,
} from "../../components/TableOptions";
import Pagination from "../../components/Pagination";

const AllSupportService = ({ history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const getMyMessage = useSelector((state) => state.getMyMessage);
  const { loading, data: datainfo, error } = getMyMessage;

  const [data, setData] = useState([]);
  const [filtered, setFilterd] = useState([]);
  const [result, setResult] = useState("");
  const [displayData, setDisplayData] = useState([]);

  const [entriesPerPage, setEntriesPerPage] = useState(() => {
    return localStorage.getItem("rowsPerPage") || "8";
  });

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role === "Human Resource Executive" ||
        userInfo.role === "Head Of Department - HR" ||
        userInfo.role === "CEO" ||
        userInfo.role === "Super Admin" ||
        userInfo.role === "Assistant Manager - Human Resources" ||
        userInfo.role === "Human Resource Manager")
    ) {
      dispatch(getAllChatMessages());
    } else {
      history.push("/");
    }
  }, [dispatch, history, userInfo]);

  useEffect(() => {
    setFilterd(datainfo);
  }, [datainfo]);

  useEffect(() => {
    const results = filtered?.filter((data) =>
      data.employee.email.toLowerCase().includes(result)
    );
    setData(results);
  }, [result, filtered]);

  const onChange = (e) => {
    setResult(e.target.value);
  };

  return (
    <div id="reports">
      <h5 className="page-title mb-4">
        Drop your message - we'll respond shortly
      </h5>

      <div className="half-background">
        <SearchComponent
          sortData={data}
          entriesPerPage={entriesPerPage}
          setEntriesPerPage={setEntriesPerPage}
          placeholder={"All Support Service"}
          onChange={onChange}
        />
        {error && (
          <Message variant="danger" className="error-border" dismissible>
            {error}
          </Message>
        )}
        <section className="md-ui component-data-table">
          {loading && <Loader />}
          <div className="main-table-wrapper">
            <table className="main-table-content">
              <thead className="data-table-header">
                <tr className="data-table-row">
                  <th>Email Address</th>
                  <th>First Name</th>
                  <th>Date Sent</th>
                  <th>Role</th>
                  <th>Department</th>
                  <th>Subject</th>
                  <th>View</th>
                  {userInfo &&
                    (userInfo.role === "Human Resource Executive" ||
                      userInfo.role === "Super Admin" ||
                      userInfo.role === "Assistant Manager - Human Resources" ||
                      userInfo.role === "Human Resource Manager") && <th></th>}
                </tr>
              </thead>
              <tbody className="data-table-content">
                {loading ? (
                  <TableFetch colSpan={8} />
                ) : displayData?.length === 0 || displayData == null ? (
                  <NoRecordFound colSpan={8} />
                ) : (
                  displayData?.map((user, i) => (
                    <tr className="data-table-row" key={i}>
                      <td>{user.employee.email}</td>
                      <td>{user.employee.firstname}</td>
                      <td>{moment(user.createdAt).format("DD-MM-YYYY")}</td>
                      <td>{user.employee.role}</td>
                      <td>{user.employee.department}</td>
                      <td>{user.title}</td>
                      <td>
                        <NavLink
                          to={`/support/${user._id}/messaging`}
                          exact
                          className="update-btn rounded-5"
                          style={{ background: "#E2522E", boxShadow: "none" }}>
                          Reply
                        </NavLink>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </section>
        <footer className="main-table-footer">
          <Pagination
            setDisplayData={setDisplayData}
            data={data}
            entriesPerPage={entriesPerPage}
            Total={"Employees"}
          />
        </footer>
      </div>
    </div>
  );
};

export default AllSupportService;
