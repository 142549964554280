import axios from 'axios'
import { HOD_LAPTOP_DETAILS_REQUEST, HOD_LAPTOP_DETAILS_SUCCESS, HOD_LAPTOP_DETAILS_FAIL, LAPTOP_APPLICATION_CREATE_REQUEST, LAPTOP_APPLICATION_CREATE_SUCCESS, LAPTOP_APPLICATION_CREATE_FAIL, LAPTOP_APPLICATION_DETAILS_REQUEST, LAPTOP_APPLICATION_DETAILS_SUCCESS, LAPTOP_APPLICATION_DETAILS_FAIL, LAPTOP_REQUEST_DETAILS_ID_HOD_REQUEST, LAPTOP_REQUEST_DETAILS_ID_HOD_SUCCESS, LAPTOP_REQUEST_DETAILS_ID_HOD_FAIL, APPROVE_HOD_LAPTOP_ID_REQUEST, APPROVE_HOD_LAPTOP_ID_SUCCESS, APPROVE_HOD_LAPTOP_ID_FAIL, ADMIN_APPROVE_HOD_LAPTOP_ID_REQUEST, ADMIN_APPROVE_HOD_LAPTOP_ID_SUCCESS, ADMIN_APPROVE_HOD_LAPTOP_ID_FAIL, CEO_APPROVE_HOD_LAPTOP_ID_REQUEST, CEO_APPROVE_HOD_LAPTOP_ID_SUCCESS, CEO_APPROVE_HOD_LAPTOP_ID_FAIL, REJECT_HOD_LAPTOP_ID_REQUEST, REJECT_HOD_LAPTOP_ID_SUCCESS, REJECT_HOD_LAPTOP_ID_FAIL, GET_LAPTOP_INFO_DETAILS_REQUEST, GET_LAPTOP_INFO_DETAILS_SUCCESS, GET_LAPTOP_INFO_DETAILS_FAIL, CREATE_LAPTOP_INFO_REQUEST, CREATE_LAPTOP_INFO_SUCCESS, CREATE_LAPTOP_INFO_FAIL, LAPTOP_INFO_DETAILS_ID_REQUEST, LAPTOP_INFO_DETAILS_ID_SUCCESS, LAPTOP_INFO_DETAILS_ID_FAIL, LAPTOP_INFO_UPDATE_ID_REQUEST, LAPTOP_INFO_UPDATE_ID_SUCCESS, LAPTOP_INFO_UPDATE_ID_FAIL } from '../constants/laptopApplicationContants'
import { baseUrl } from '../shared/baseUrl';




export const createLaptopApplication = (
    laptopType,
    laptopRamSize,
    accessories,
    employeeEmail,
    reason
) => async (dispatch, getState) => {
    try {
        dispatch({
            type: LAPTOP_APPLICATION_CREATE_REQUEST
        })


        const { userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post(
            baseUrl + '/api/v1/laptops', {
            laptopType,
            laptopRamSize,
            accessories,
            employeeEmail,
            reason
        },
            config)

        dispatch({
            type: LAPTOP_APPLICATION_CREATE_SUCCESS,

            payload: data
        })

    } catch (error) {
        dispatch({
            type: LAPTOP_APPLICATION_CREATE_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }

}


export const getHodLaptopRequest = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: HOD_LAPTOP_DETAILS_REQUEST
        })


        const { userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(
            baseUrl + `/api/v1/laptops`,
            config)

        dispatch({
            type: HOD_LAPTOP_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: HOD_LAPTOP_DETAILS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }

}


export const getAllLaptopAllication = () => async (dispatch, getState) => {
    try {

        dispatch({
            type: LAPTOP_APPLICATION_DETAILS_REQUEST
        })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token} `
            }
        }
        const { data } = await axios.get(
            baseUrl + `/api/v1/laptops/all`, config)

        dispatch({
            type: LAPTOP_APPLICATION_DETAILS_SUCCESS,
            payload: data

        })

    } catch (error) {
        dispatch({
            type: LAPTOP_APPLICATION_DETAILS_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message,
        })
    }
}



export const hodGetAllLaptopRequestById = (id) => async (dispatch, getState) => {
    try {

        dispatch({
            type: LAPTOP_REQUEST_DETAILS_ID_HOD_REQUEST
        })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(
            baseUrl + `/api/v1/laptops/${id}`, config)

        dispatch({
            type: LAPTOP_REQUEST_DETAILS_ID_HOD_SUCCESS,
            payload: data

        })

    } catch (error) {
        dispatch({
            type: LAPTOP_REQUEST_DETAILS_ID_HOD_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message,
        })
    }
}

export const approveHodLaptopRequestId = (laptoprequest) => async (dispatch, getState) => {
    try {

        dispatch({
            type: APPROVE_HOD_LAPTOP_ID_REQUEST
        })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        await axios.put(
            baseUrl + `/api/v1/laptops/${laptoprequest._id}`, laptoprequest, config)

        dispatch({
            type: APPROVE_HOD_LAPTOP_ID_SUCCESS,
            success: true,
        })

    } catch (error) {
        dispatch({
            type: APPROVE_HOD_LAPTOP_ID_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message,
        })
    }
}

export const adminApproveHodLaptopRequestId = (adminLaptopRequestId) => async (dispatch, getState) => {
    try {

        dispatch({
            type: ADMIN_APPROVE_HOD_LAPTOP_ID_REQUEST
        })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        await axios.put(
            baseUrl + `/api/v1/laptops/${adminLaptopRequestId._id}/admin`, adminLaptopRequestId, config)

        dispatch({
            type: ADMIN_APPROVE_HOD_LAPTOP_ID_SUCCESS,
            success: true,
        })

    } catch (error) {
        dispatch({
            type: ADMIN_APPROVE_HOD_LAPTOP_ID_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message,
        })
    }
}

export const ceoApproveHodLaptopRequestId = (ceoLaptopRequestId) => async (dispatch, getState) => {
    try {

        dispatch({
            type: CEO_APPROVE_HOD_LAPTOP_ID_REQUEST
        })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        await axios.put(
            baseUrl + `/api/v1/laptops/${ceoLaptopRequestId._id}/finalapproval`, ceoLaptopRequestId, config)

        dispatch({
            type: CEO_APPROVE_HOD_LAPTOP_ID_SUCCESS,
            success: true,
        })

    } catch (error) {
        dispatch({
            type: CEO_APPROVE_HOD_LAPTOP_ID_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message,
        })
    }
}


export const rejectHodLaptopRequestId = (laptoprequest) => async (dispatch, getState) => {
    try {

        dispatch({
            type: REJECT_HOD_LAPTOP_ID_REQUEST
        })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        await axios.put(
            baseUrl + `/api/v1/laptops/${laptoprequest._id}/reject`, laptoprequest, config)

        dispatch({
            type: REJECT_HOD_LAPTOP_ID_SUCCESS,
            success: true,
        })

    } catch (error) {
        dispatch({
            type: REJECT_HOD_LAPTOP_ID_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message,
        })
    }
}

export const createSystemInfomation = (
    fullname,
    department,
    employeeStatus,
    systemName,
    systemType,
    serialNumber,
    monitor,
    monitorSerialNumber,
    HDD,
    windowsVersion,
    ramSize,
    status,
    systemStatus,
    systemCondition
) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CREATE_LAPTOP_INFO_REQUEST
        })


        const { userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.post(
            baseUrl + '/api/v1/laptopinfo', {
            fullname,
            department,
            employeeStatus,
            systemName,
            systemType,
            serialNumber,
            monitor,
            monitorSerialNumber,
            HDD,
            windowsVersion,
            ramSize,
            status,
            systemStatus,
            systemCondition
        },
            config)

        dispatch({
            type: CREATE_LAPTOP_INFO_SUCCESS,

            payload: data
        })

    } catch (error) {
        dispatch({
            type: CREATE_LAPTOP_INFO_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }

}

export const getLaptopInfo = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_LAPTOP_INFO_DETAILS_REQUEST
        })


        const { userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(baseUrl + `/api/v1/laptopinfo`,
            config)

        dispatch({
            type: GET_LAPTOP_INFO_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: GET_LAPTOP_INFO_DETAILS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }

}


export const AllLaptopInfoById = (id) => async (dispatch, getState) => {
    try {

        dispatch({
            type: LAPTOP_INFO_DETAILS_ID_REQUEST
        })
        const { userLogin: { userInfo } } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(
            baseUrl + `/api/v1/laptopinfo/${id}`, config)

        dispatch({
            type: LAPTOP_INFO_DETAILS_ID_SUCCESS,
            payload: data

        })

    } catch (error) {
        dispatch({
            type: LAPTOP_INFO_DETAILS_ID_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message,
        })
    }
}




export const updateLaptopInfo = (laptop) => async (dispatch, getState) => {
    try {
        dispatch({
            type: LAPTOP_INFO_UPDATE_ID_REQUEST
        })

        const { userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            baseUrl + `/api/v1/laptopinfo/${laptop._id}`, laptop, config)

        dispatch({
            type: LAPTOP_INFO_UPDATE_ID_SUCCESS,
            success: true,
            payload: data
        })
    }

    catch (error) {
        dispatch({
            type: LAPTOP_INFO_UPDATE_ID_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}






