import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { policyActions } from "../actions/userActions";
import { USER_POLICY_RESET } from "../constants/userConstants";
import { useDispatch } from "react-redux";

const PolicyModal = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userPolicy = useSelector((state) => state.userPolicy);
  const { loading, success } = userPolicy;

  const [lgShow, setLgShow] = useState(false);
  const [findpolicy, setFindpolicy] = useState(() => {
    return JSON.parse(localStorage.getItem("MyPolicy")) || false;
  });

  useEffect(() => {
    if (success) {
      setLgShow(false);
      dispatch({
        type: USER_POLICY_RESET,
      });
    }
  }, [dispatch, success, userInfo, findpolicy]);

  useEffect(() => {
    if (userInfo.policy === false && findpolicy !== true) {
      setLgShow(true);
    } else if (userInfo.policy === true) {
      setLgShow(false);
    } else if (findpolicy === true) {
      setLgShow(false);
    } else {
      setLgShow(false);
    }
  }, [userInfo, lgShow, findpolicy]);

  const PolicyInfo = (e) => {
    e.preventDefault(e);
    dispatch(policyActions(true));
    setFindpolicy(true);
  };

  const handelScroll = (event) => {
    const target = event.target;

    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      console.log(target.clientHeight);
    }
  };

  return (
    <div>
      <>
        <Modal
          scrollable={true}
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg">
          <Modal.Header>
            <div>
              <h2 className="container__heading">
                EMPLOYEE RECEIPT AND ACCEPTANCE{" "}
              </h2>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div onScroll={handelScroll} id="Scroll-body" className="onScroll">
              <p>
                I hereby acknowledge receipt of the (Organization Name) Employee
                Handbook. I understand that it is my continuing responsibility
                to read and know its contents. I also understand and agree that
                the Employee Handbook is not an employment contract for any
                specific period of employment or for continuing or long‐term
                employment. Therefore, I acknowledge and understand that unless
                I have a written employment agreement with OUTCESS Solutions
                Nigeria Limited that provides otherwise, I have the right to
                resign from my employment with OUTCESS Solutions Nigeria Limited
                at any time with or without notice and with or without cause,
                and that OUTCESS Solutions Nigeria Limited has the right to
                terminate my employment at any time with or without notice and
                with or without cause. I have read, understand and agree to all
                of the above. I have also read and understand the OUTCESS
                Solutions Nigeria Limited Employee Handbook. I agree to return
                the Employee Handbook upon termination of my employment.{" "}
              </p>

              <h4>CONFIDENTIALITY POLICY AND PLEDGE </h4>
              <p>
                Any information that an employee learns about OUTCESS Solutions
                Nigeria Limited, or its members or donors, as a result of
                working for OUTCESS Solutions Nigeria Limited that is not
                otherwise publicly available constitutes confidential
                information. Employees may not disclose confidential information
                to anyone who is not employed by OUTCESS Solutions Nigeria
                Limited or to other persons employed by OUTCESS Solutions
                Nigeria Limited who do not need to know such information to
                assist in rendering services. The disclosure, distribution,
                electronic transmission or copying of OUTCESS Solutions Nigeria
                Limited confidential information is prohibited. Any employee who
                discloses confidential information OUTCESS Solutions Nigeria
                Limited will be subject to disciplinary actions even if he or
                she does not actually benefit from the disclosure of such
                information. I understand the above policy and pledge not to
                disclose confidential information.
              </p>

              <h5>i. MISSION </h5>
              <h5>ii. OVERVIEW </h5>

              <p>
                OUTCESS Employee Handbook (the “Handbook”) has been developed to
                provide general guidelines about OUTCESS policies and procedures
                for employees. It is a guide to assist you in becoming familiar
                with some of the privileges and obligations of your employment.
                None of the policies or guidelines in the Handbook is intended
                to give rise to contractual rights or obligations, or to be
                construed as a guarantee of employment for any specific period
                of time, or any specific type of work. Additionally, these
                guidelines are subject to modification, amendment or revocation
                by OUTCESS at any time, without advance notice.{" "}
              </p>

              <p>
                The personnel policies of OUTCESS are established by the Human
                Resources Manager which has delegated authority and
                responsibility for their administration to the Administrative
                Manager. The Administrative Manager may, in turn, delegate
                authority for administering specific policies. Employees are
                encouraged to consult the Human Resources Department for
                additional information regarding the policies, procedures, and
                privileges described in this Handbook. Questions about personnel
                matters also may be reviewed with the Human Resources Manager.{" "}
              </p>
              <p>
                OUTCESS will provide each individual a copy of this Handbook
                upon employment. All employees are expected to abide by it. The
                highest standards of personal and professional ethics and
                behavior are expected of all OUTCESS employees. Further, OUTCESS
                expects each employee to display good judgment, diplomacy and
                courtesy in their professional relationships with members of
                OUTCESS Board of Directors, committees, membership, staff, and
                the general public.{" "}
              </p>

              <h5>III. VOLUNTARY AT‐WILL EMPLOYMENT </h5>
              <p>
                Unless an employee has a written employment agreement with
                OUTCESS, which provides differently, all employment at OUTCESS
                is “at‐will.”{" "}
              </p>
              <p>
                That means that employees may be terminated from employment with
                OUTCESS with or without cause, and employees are free to leave
                the employment of OUTCESS with or without cause. Any
                representation by any OUTCESS officer or employee contrary to
                this policy is not binding upon OUTCESS unless it is in writing
                and is signed by the Administrative Manager with the approval of
                the Human Resources.{" "}
              </p>
              <h5>IV. EQUAL EMPLOYMENT OPPORTUNITY</h5>
              <p>
                OUTCESS shall follow the spirit and intent of all federal, state
                and local employment law and is committed to equal employment
                opportunity. To that end, the Board of Directors and CEO of
                OUTCESS will not discriminate against any employee or applicant
                in a manner that violates the law. OUTCESS is committed to
                providing equal opportunity for all employees and applicants
                without regard to race, color, religion, national origin, sex,
                age, marital status, sexual orientation, disability, political
                affiliation, family responsibilities, or any other
                characteristic protected under federal, state or local law. Each
                person is evaluated on the basis of personal skill and merit.
                OUTCESS’s policy regarding equal employment opportunity applies
                to all aspects of employment, including recruitment, hiring, job
                assignments, promotions, working conditions, scheduling,
                benefits, wage and salary administration, disciplinary action,
                termination, and social, educational programs. The Human
                Resource Manager shall act as the responsible agent in the full
                implementation of the Equal Employment Opportunity policy.{" "}
              </p>

              <p>
                OUTCESS will not tolerate any form of unlawful discrimination.
                All employees are expected to cooperate fully in implementing
                this policy
              </p>
              <p>
                If OUTCESS determines that a violation of this policy has
                occurred, it will take appropriate disciplinary action against
                the offending party, which can include counseling, warnings,
                suspensions, and termination. Employees who report, in good
                faith, violations of this policy and employees who cooperate
                with investigations into alleged violations of this policy will
                not be subject to retaliation. Upon completion of the
                investigation, OUTCESS will inform the employee who made the
                complaint of the results of the investigation.{" "}
              </p>

              <p>
                OUTCESS is also committed to complying fully with applicable
                disability discrimination laws, and ensuring that equal
                opportunity in employment exists at ISN for qualified persons
                with disabilities. All employment practices and activities are
                conducted on a non‐discriminatory basis.{" "}
              </p>
              <h4>HOURS OF WORK, ATTENDANCE AND PUNCTUALITY </h4>
              <h6>A. Hours of Work </h6>
              <p>
                The normal workweek for OUTCESS shall consist of five (5), seven
                (9)-hour days. Ordinarily, work hours are from 8:00 a.m. ‐ 6:00
                p.m., Monday through Friday, including one hour for lunch.
                Employees may request the opportunity to vary their work
                schedules (within employer‐defined limits) to better accommodate
                personal responsibilities however the approval of such requests
                is solely at the discretion of the managing director.{" "}
              </p>
              <h6>B. Attendance and Punctuality </h6>
              <p>
                Attendance is a key factor in your job performance. Punctuality
                and regular attendance are expected of all employees. Excessive
                absences (whether excused or unexcused), tardiness or leaving
                early is unacceptable. If you are absent for any reason or plan
                to arrive late or leave early, you must notify your supervisor
                and the Head of Human resources as far in advance as possible
                and no later than 1 day before the planned date of absence in
                the event of an emergency, you must notify your supervisor as
                soon as possible.{" "}
              </p>
              <p>
                For all absences extending longer than one day, you must
                telephone your immediate supervisor prior to the start of each
                scheduled workday. When reporting an absence, you should
                indicate the nature of the problem causing your absence and your
                expected return‐to‐ work date. A physician’s statement may be
                required as proof of the need for any illness‐related absence
                regardless of the length of the absence.{" "}
              </p>
              <p>
                Except as provided in other policies, an employee who is absent
                from work for three consecutive days without notification to his
                or her supervisor or the Executive Director will be considered
                to have voluntarily terminated his or her employment and
                forfeited their salary and benefits for the month or said
                period.
              </p>
              <p>
                Excessive absences, tardiness or leaving early will be grounds
                for disciplinary actions up to and including termination.
                Depending on the circumstances, including the employee’s length
                of employment, OUTCESS may counsel employees prior to
                termination for excessive absences, tardiness or leaving early.{" "}
              </p>
              <h6>C. Overtime </h6>
              <p>
                Overtime pay, which is applicable only when applicable and
                approved by the managing director in line with personnel
                contract.
              </p>
              <h4>EMPLOYMENT POLICIES AND PRACTICES </h4>
              <h6>A. Definition of Terms </h6>
              <p>
                1. Employer. OUTCESS is the employer of all full‐time, part‐time
                and temporary employees. An employee is hired, provided
                compensation and applicable benefits based on individual
                contracts, and have his or her work directed and evaluated by
                OUTCESS via the Human Resource department using various methods
                of evaluations.{" "}
              </p>
              <p>
                2. Full‐Time Employee. A Full Time Employee is a member of staff
                of OUTCESS.
              </p>
              <p>
                3. Part‐Time Employee. A Part Time Employee regularly is a
                contract employee of OUTCESS and whose work scope is determined
                solely on the client request and project duration.
              </p>
              <h4> POSITION DESCRIPTION AND SALARY ADMINISTRATION </h4>
              <p>
                Each position shall have a written job description. In general,
                the description will include the: purpose of the position, areas
                of responsibilities, immediate supervisor(s), qualifications
                required, salary range, and working conditions affecting the
                job, e.g., working hours, use of car, etc. The Head of Human
                Resources shall at the request of the supervisors, shall have
                discretion to modify the job description to meet the needs of
                OUTCESS.{" "}
              </p>
              <p>
                Pay slips are distributed electronically on the 4th day of the
                new month by the Human Resource department, except when either
                of those days falls on a Saturday, Sunday or holiday, in which
                case pay slips will be distributed on the preceding workday.
                Timesheets are due to the Human Resource department within two
                days of each pay period. All salary deductions are itemized and
                presented to employees in their pay slips. Approved salary
                deductions may include: state income taxes, voluntary medical
                and group hospitalization insurance premiums (if in force and if
                paid by employee) and other benefits (e.g., life insurance).{" "}
              </p>
              <h4> WORK REVIEW </h4>
              <p>
                The work of each employee is reviewed on an ongoing basis with
                the supervisor and the Human Resource department to provide a
                systematic means of evaluating performance. The first review
                shall be done at the end of your first three months of
                employment with OUTCESS. Thereafter, the performance reviews
                shall range from quarterly to yearly reviews.
              </p>
              <p>
                The annual performance review is a formal opportunity for the
                management and employee to exchange ideas that will strengthen
                their working relationship, review the past year, and anticipate
                OUTCESSʹs needs in the coming year. The purpose of the review is
                to encourage the exchange of ideas in order to create positive
                change within OUTCESS. To that end, it is incumbent upon both
                parties to have an open, and honest discussion concerning the
                employee’s performance. It is further incumbent upon the
                supervisor to clearly communicate the needs of OUTCESS and what
                is expected of the employee in contributing to the success of
                OUTCESS for the coming year.{" "}
              </p>
              <p>
                Both the management and employee should attempt to arrive at an
                understanding regarding the objectives for the coming year. This
                having been done, both parties should sign the performance
                review form, which will be kept as part of the employee’s
                personnel record and used as a guide during the course of the
                year to monitor employee progress relative to the agreed upon
                objectives.{" "}
              </p>
              <p>
                Factors considered in your review include the quality of your
                job performance, your attendance, meeting the requirements of
                your job description, dependability, attitude, cooperation,
                compliance with Company employment policies, any disciplinary
                actions, and year-to-year improvement in overall performance.
                The Company at its discretion in consideration of various
                factors, including your performance review, gives compensation
                increases.
              </p>
              <h4> ECONOMIC BENEFITS AND INSURANCE </h4>
              <p>
                OUTCESS shall provide a competitive package of benefits to all
                eligible full‐time employees. The following outline of available
                benefits is provided with the understanding that benefit plans
                may change from time to time, Continuation of any benefits after
                termination of employment will be solely at the employee’s
                expense.{" "}
              </p>
              <h6>A. Medicals</h6>
              <p>
                OUTCESS currently provides individual health benefits for
                eligible full‐time. This benefit covers individuals, their
                spouse and four children for Full Time Employee. This benefit
                cover for employee on contact status shall be determined{" "}
              </p>
              <p>
                by existing contract with the client. Information about
                OUTCESS’s health plan(s) will be provided to the employee at the
                time of employment.{" "}
              </p>
              <h6>GROUP LIFE INSURANCE</h6>
              <p>
                OUTCESS currently provides all employee life insurance. This is
                strictly in line with the insurance policies.{" "}
              </p>
              <h4> LEAVE BENEFITS AND OTHER WORK POLICIES </h4>
              <h6>A. Holidays </h6>
              <p>
                All employees are allowed to observe government-approved
                holidays unless otherwise stated in the employee contract.
                OUTCESS reserves the right to modify or terminate any employee
                benefits, at any time.{" "}
              </p>
              <h6>B. Vacation </h6>
              <p>
                During the first 365 days of employment full time employees will
                not earn Vacation benefits.{" "}
              </p>
              <p>
                Vacation benefits are paid accordingly based on the employees’
                contract terms and conditions. Use of Vacation is subject to
                approval by the employee line manager and Head of Human
                Resources using the appropriate leave request form.{" "}
              </p>
              <p>
                Employees may not carry over unused Vacation from one year to
                the next year.{" "}
              </p>
              <h6>C. Sick Leave </h6>
              <p>
                Sick leave benefits are earned on a prorated basis of one day
                per month for full‐time employees beginning at first day of
                employment. Temporary/contract employees are not eligible for
                paid sick leave benefits. Use of sick leave is subject to
                approval by the supervisor and the Executive Director and must
                be requested in advance.{" "}
              </p>
              <p>
                If an employee’s illness or injury requires a consecutive
                absence of five (5) days or more, physician documentation will
                be required.{" "}
              </p>
              <h6>d. Maternity leave</h6>
              <p>
                Maternity Leave is granted for a maximum period of 12weeks with
                half pay during maternity leave (50%) during such leave. If an
                employee has not spent up to one year (12 calendar month)
                concerned employee will not be entitled to pay during maternity
                leave.
              </p>
              <p>
                During this period, the employee shall make adequate
                arrangements for her duties to be effectively carried out during
                her absence. She shall do this by properly handing over all
                necessary company documents and information in her possession to
                the designated persons who will be filling in for her in her
                absence.
              </p>
              <h6>e. Meetings and Conferences </h6>
              <p>
                Staff may be given limited time off by the Executive Director
                with pay to participate in educational opportunities related to
                the staff member’s current or anticipated work with OUTCESS. An
                employee serving as an official representative of OUTCESS at a
                conference or meeting is considered on official business and not
                on leave.{" "}
              </p>
              <h4> REIMBURSEMENT OF EXPENSES </h4>
              <p>
                Reimbursement is authorized for reasonable and necessary
                expenses incurred in carrying out job responsibilities. Mileage
                or transportation, parking fees, business telephone calls, and
                meal costs when required to attend a luncheon or banquet, are
                all illustrative of reasonable and necessary expenses.{" "}
              </p>
              <p>
                Employees serving in an official capacity for OUTCESS at
                conferences and meetings are reimbursed for actual and necessary
                expenses incurred, such as travel expenses, meal costs, lodging
                and registration fees. When attending meetings that have been
                approved by the Executive Director, employees are reimbursed for
                travel expenses, course fees, and costs of meals and lodging at
                the current rates. Employees may also request a travel advance
                to cover anticipated expenses approved travel. Employees also
                may be granted leave to attend a conference or professional
                meeting related to their professional development, and/or
                OUTCESSʹs current and anticipated work. Expenses for these
                purposes can be paid by OUTCESS, if funds are available, and the
                employee obtains prior written approval from the managing
                director for such expenses.{" "}
              </p>
              <p>
                Employees are responsible for transportation costs between the
                office and home during normal work hours. Transportation costs
                are paid by OUTCESS for work outside normal work hours if the
                employee is on official business for OUTCESS. Employees
                authorized to use their personal cars for OUTCESS business are
                reimbursed at the approved rate.{" "}
              </p>
              <p>
                Forms are provided to request reimbursement for actual expenses
                and advance payment for travel. Receipts must be provided for
                all expenditures made in order to claim reimbursement.{" "}
              </p>
              <h4>SEPARATION </h4>
              <p>
                Either OUTCESS or the employee may initiate separation. OUTCESS
                encourages employees to provide at least 1 month (30days)
                written notice prior to intended separation or as stated in the
                employee’s contract. After receiving such notice, a letter of
                separation will be provided to the employee by the Head of Human
                Resources. However, in the event of termination, OUTCESS is
                under no obligation to provide prior notice.
              </p>
              <h5>Circumstances under which separation may occur include: </h5>
              <div id="include__ol">
                {" "}
                <div id="include__ol-h5">
                  {" "}
                  <h5>1.</h5>
                </div>
                <div>
                  <span>
                    Resignation. Employees are encouraged to give at least 1
                    month of written notice. Since a longer period is desired,
                    the intention to resign should be made known as far in
                    advance as possible. Employees who resign are entitled to
                    receive accrued benefits except in the vent{" "}
                  </span>
                </div>
              </div>
              <div id="include__ol">
                {" "}
                <div id="include__ol-h5">
                  {" "}
                  <h5>2.</h5>
                </div>
                <div>
                  <span>
                    2. Termination or Lay‐off. Under certain circumstances, the
                    termination or lay‐off of an employee may be necessary.{" "}
                  </span>
                </div>
              </div>
              <p>
                The Managing Director has the authority to discharge an employee
                from the employ of OUTCESS. As stated above, all employment at
                OUTCESS is “at‐will.” That means that employees may be
                terminated from employment with OUTCESS with or without cause,
                and employees are free to leave the employment of OUTCESS with
                or without cause. Reasons for discharge may include, but are not
                limited to:{" "}
              </p>
              <p>
                • Falsifying or withholding information on your employment
                application that did or would have affected OUTCESS’s decision
                to hire you (this conduct will result in your immediate
                termination);{" "}
              </p>
              <p>
                • Falsifying or withholding information in other personnel
                records including personnel questionnaires, performance
                evaluations or any other records;{" "}
              </p>
              <p>
                • Performance at work below a level acceptable to OUTCESS or the
                failure to perform assigned duties;{" "}
              </p>
              <p>
                • Failure to complete required time records or falsification of
                such time records;{" "}
              </p>
              <p>• Insubordination; </p>
              <p>• Refusing to work reasonable overtime; </p>
              <p>
                • Negligence in the performance of duties likely to cause or
                actually causing personal injury or property damage, reduce
                productivity or delay the job.
              </p>
              <p>• Fighting, arguing or attempting to injure another; </p>
              <p>
                • Destroying or willfully damaging the personal property of
                another, including OUTCESS’s property;{" "}
              </p>
              <p>• Breach of confidentiality; </p>
              <p>
                • Using or appearing to use for personal gain any information
                obtained on the job, which is not readily available to the
                general public or disclosing such information that damages the
                interests of OUTCESS or its customers or vendors;{" "}
              </p>
              <p>
                • Placing oneself in a position in which personal interests and
                those of OUTCESS are or appear to be in conflict or might
                interfere with the ability of the employee to perform the job as
                well as possible;{" "}
              </p>
              <p>
                • Using OUTCESS property or services for personal gain or
                taking, removing or disposing of OUTCESS material, supplies or
                equipment without proper authority;{" "}
              </p>
              <p>• Gambling in any form on OUTCESS property; </p>
              <p>• Dishonesty; </p>
              <p>• Theft; </p>
              <p>
                • The possession, use, sale or being under the influence of
                drugs or other controlled substances or alcoholic beverages
                during working hours or on OUTCESS premises at any time in
                violation of OUTCESS’s policies.{" "}
              </p>
              <p>
                • Carrying or possessing firearms or weapons on OUTCESS
                property;{" "}
              </p>
              <p>
                • Excessive tardiness or absenteeism whether excused or
                unexcused;{" "}
              </p>
              <div className="nauthorized__span">
                <p className="nauthorized__span-space">
                  • Unauthorized absence from work without proper notice; and{" "}
                </p>
                <p>
                  • Engaging in discriminatory or abusive behavior, including
                  sexual harassment.{" "}
                </p>
              </div>
              <p>
                At the discretion of the Managing Director and the
                Administrative Manager, the employee may be asked to leave
                immediately or be given a period of notice.{" "}
              </p>
              <h5>Drug-Free Workplace</h5>
              <p>
                The Company takes seriously the problem of drug and alcohol
                abuse, and is committed to providing a substance abuse-free
                workplace for its employees. Substance abuse of any kind is
                inconsistent with the behaviour expected of our employees,
                subjects all employees and visitors to our facilities to
                unacceptable safety risks, and undermines our ability to operate
                effectively and efficiently. The Company has adopted a formal
                policy related to substance abuse. A copy of the complete policy
                is contained in this Handbook.{" "}
              </p>
              <h5>Smoking</h5>
              <p>
                In order to provide a safe and comfortable working environment
                for all employees, smoking is strictly prohibited at all times
                inside any Company building.
              </p>
              <h5>Safety and Accident Rules</h5>
              <p>
                Safety is a joint venture at the Company. We provide a clean,
                hazard-free, healthy, safe environment in which to work and make
                every effort to comply with all relevant federal, state and
                local occupational health and safety laws. As an employee, you
                have a duty to comply with the safety rules of the Company, and
                you are expected to take an active part in maintaining this
                hazard-free environment. You should observe all posted safety
                rules, adhere to all safety instructions provided by your
                supervisor and use safety equipment where required. Your
                workspace should be kept neat, clean and orderly. You are
                required to report any accidents or injuries – including any
                breaches of safety – and to promptly report any unsafe
                equipment, working condition, process or procedure to a
                supervisor. In addition, if you become ill or get hurt while at
                work, you must notify your manager immediately.{" "}
              </p>
              <p>
                Failure to abide by the Company’s safety and accident rules may
                result in disciplinary action, up to and including termination.{" "}
              </p>
              <h5>RETURN OF PROPERTY </h5>
              <p>
                Employees are responsible for OUTCESS equipment, property and
                work products that may be issued to them and/or are in their
                possession or control, including but not limited to:{" "}
              </p>
              <div>
                <ol>
                  <li>Telephone cards,</li>
                  <li>Credit cards,</li>
                  <li>Identification badges,</li>
                  <li>Office/building keys,</li>
                  <li>Office/building security passes,</li>
                  <li>
                    Computers, computerized diskettes, electronic/voice mail
                    codes, and Intellectual property (e.g., written materials,
                    work products).{" "}
                  </li>
                </ol>
              </div>
              <p>
                In the event of separation from employment, or immediately upon
                request by the Head of Human Resources or his or her designee,
                Employees must return all OUTCESS property that is in their
                possession or control. Where permitted by applicable law(s) or
                where there is a violation of company rules and regulations,
                OUTCESS may withhold from the employee’s final paycheck the cost
                of any property, including intellectual property, which is not
                returned when required. OUTCESS also may take any action deemed
                appropriate to recover or protect its property.{" "}
              </p>
              <h5>PERSONNEL RECORDS </h5>
              <p>
                Personnel records are the property of OUTCESS and access to the
                information they contain is restricted and confidential. A
                personnel file shall be kept for each employee and should
                include the employee’s job application, copy of the letter of
                employment and position description, performance reviews,
                disciplinary records, records of salary increases and any other
                relevant personnel information. It is the responsibility of each
                employee to promptly notify his/ or her supervisor in writing of
                any changes in personnel data, including personal mailing
                addresses, telephone numbers, names of dependents, and
                individuals to be contacted in the event of an emergency.
                Accurately recording time worked is the responsibility of every
                employee. Tampering, altering, or falsifying time records, or
                recording time on another employee’s time record may result in
                disciplinary action, including separation from employment with
                OUTCESS.
              </p>
              <h5>OUTSIDE EMPLOYMENT </h5>
              <p>
                Outside employment that constitutes a conflict of interest is
                prohibited. Employees may not receive any income or material
                gain from individuals or organizations for materials produced or
                services rendered while performing their jobs with OUTCESS.{" "}
              </p>
              <h5>NON‐DISCLOSURE OF CONFIDENTIAL INFORMATION </h5>
              <p>
                Any information that an employee learns about OUTCESS, or its
                members or donors, as a result of working for OUTCESS that is
                not otherwise publicly available constitutes confidential
                information. Employees may not disclose confidential information
                to anyone who is not employed by OUTCESS or to other persons
                employed by OUTCESS who do not need to know such information to
                assist in rendering services.{" "}
              </p>
              <p>
                The protection of privileged and confidential information,
                including trade secrets, is vital to the interests and the
                success of OUTCESS The disclosure, distribution, electronic
                transmission or copying of OUTCESS confidential information is
                prohibited. Such information includes, but is not limited to the
                following examples:{" "}
              </p>
              <p>
                Compensation data. Program and financial information, including
                information related to donors, and pending projects and
                proposals.
              </p>
              <p>
                Employees are required to sign a non‐disclosure agreement as a
                condition of employment. Any employee who discloses confidential
                OUTCESS information will be subject to disciplinary action
                including but not limited to possible separation and
                prosecution, even if he or she does not actually benefit from
                the disclosure of such information.{" "}
              </p>
              <p>
                Discussions involving sensitive information should always be
                held in confidential settings to safeguard the confidentiality
                of the information. Conversations regarding confidential
                information generally should not be conducted on cellular
                phones, or in elevators, restrooms, restaurants, or other places
                where conversations might be overheard.{" "}
              </p>
              <h5>COMPUTER AND INFORMATION SECURITY </h5>
              <p>
                This section sets forth some important rules relating to the use
                of OUTCESS’s computer and communications systems. These systems
                include individual PCs provided to employees, centralized
                computer equipment, all associated software, and OUTCESS’s
                telephone, voice mail and electronic mail systems.{" "}
              </p>
              <p>
                OUTCESS has provided these systems to support its mission.
                Although limited personal use of OUTCESS’s systems is allowed,
                subject to the restrictions outlined below, no use of these
                systems should ever conflict with the primary purpose for which
                they have been provided, OUTCESS’s ethical responsibilities or
                with applicable laws and regulations. Each user is personally
                responsible to ensure that these guidelines are followed.{" "}
              </p>
              <p>
                All data in OUTCESS’s computer and communication systems
                (including documents, other electronic files, e‐mail and
                recorded voice mail messages) are the property of OUTCESS and
                OUTCESS may inspect and monitor such data at any time. No
                individual should have any expectation of privacy for messages
                or other data recorded in OUTCESS’s systems. This includes
                documents or messages marked “private,” which may be
                inaccessible to most users but remain available to OUTCESS
                Likewise, the deletion of a document or message may not prevent
                access to the item or completely eliminate the item from the
                system.{" "}
              </p>
              <p>
                OUTCESS’s systems must not be used to create or transmit
                material that is derogatory, defamatory, obscene or offensive,
                such as slurs, epithets or anything that might be construed as
                harassment or disparagement based on race, color, national
                origin, sex, sexual orientation, age, physical or mental
                disability, medical condition, marital status, or religious or
                political beliefs. Similarly, OUTCESS’s systems must not be used
                to solicit or proselytize others for commercial purposes,
                causes, outside organizations, chain messages or other
                non‐job‐related purposes.{" "}
              </p>
              <p>
                Security procedures in the form of unique user sign‐on
                identification and passwords have been provided to control
                access to OUTCESS’s host computer system, networks and voice
                mail system. In addition, security facilities have been provided
                to restrict access to certain documents and files for the
                purpose of safeguarding information. The following activities,
                which present security risks, should be avoided.{" "}
              </p>
              <p>
                • Attempts should not be made to bypass, or render ineffective,
                security facilities provided by the company.{" "}
              </p>
              <p>
                • Passwords should not be shared between users. If written down,
                password should be kept in locked drawers or other places not
                easily accessible.
              </p>
              <div className="nauthorized__span">
                <p>
                  • Document libraries of other users should not be browsed
                  unless there is a legitimate business reason to do so.{" "}
                </p>
                <p>
                  • Individual users should never make changes or modifications
                  to the hardware configuration of computer equipment. Requests
                  for such changes should be directed to computer support.{" "}
                </p>
              </div>
              <p>
                • Additions to or modifications of the standard software
                configuration provided on OUTCESS’s PCs should never be
                attempted by individual users (e.g., autoexec.bat and config.sys
                files). Requests for such changes should be directed to computer
                support or the Managing Director.{" "}
              </p>

              <p>
                • Individual users should never load personal software
                (including outside email services) to company computers. This
                practice risks the introduction of a computer virus into the
                system. Requests for loading such software should be directed to
                computer support or the Managing Director.{" "}
              </p>
              <p>
                • Programs should never be downloaded from bulletin board
                systems or copied from other computers outside the company onto
                company computers. Downloading or copying such programs also
                risks the introduction of a computer virus. If there is a need
                for such programs, a request for assistance should be directed
                to computer support or management. Downloading or copying
                documents from outside the company may be performed not to
                present a security risk.{" "}
              </p>
              <p>
                • Users should not attempt to boot PCs from floppy diskettes.
                This practice also risks the introduction of a computer virus.{" "}
              </p>
              <p>
                • OUTCESS’s computer facilities should not be used to attempt
                unauthorized access to or use of other organizations’ computer
                systems and data.{" "}
              </p>
              <p>• Computer games should not be loaded on OUTCESS’s PCs. </p>
              <p>
                • Unlicensed software should not be loaded or executed on
                OUTCESS’s PCs.{" "}
              </p>
              <p>
                • Company software (whether developed internally or licensed)
                should not be copied onto floppy diskettes or other media other
                than for the purpose of backing up your hard drive. Software
                documentation for programs developed and/or licensed by the
                company should not be removed from the company’s offices.{" "}
              </p>
              <p>
                • Individual users should not change the location or
                installation of computer equipment in offices and work areas.
                Requests for such changes should be directed to computer support
                or management.{" "}
              </p>
              <p>
                There are a number of practices that individual users should
                adopt that will foster a higher level of security. Among them
                are the following:{" "}
              </p>
              <p>
                • Turn off your personal computer when you are leaving your work
                area or office for an extended period of time.{" "}
              </p>
              <p>
                • Exercise judgment in assigning an appropriate level of
                security to documents stored on the company’s networks, based on
                a realistic appraisal of the need for confidentiality or
                privacy.{" "}
              </p>
              <p>
                • Remove previously written information from media before
                copying documents on such media for delivery outside OUTCESS.{" "}
              </p>
              <p>
                • Back up any information stored locally on your personal
                computer (other than network based software and documents) on a
                frequent and regular basis under the supervision of the IT
                technician and only with the company approved back up modules.{" "}
              </p>
              <h5>INTERNET ACCEPTABLE USE POLICY </h5>
              <p>
                At this time, desktop access to the Internet is provided to
                employees when there is a necessity and the access has been
                specifically approved. OUTCESS has provided access to the
                Internet for authorized users to support its mission. No use of
                the Internet should conflict with the primary purpose of OUTCESS
                its ethical responsibilities or with applicable laws and
                regulations. Each user is personally responsible to ensure that
                these guidelines are followed. Serious repercussions, including
                termination, may result if the guidelines are not followed.{" "}
              </p>
              <p>
                OUTCESS may monitor usage of the Internet by employees,
                including reviewing a list of sites accessed by an individual.
                No individual should have any expectation of privacy in terms of
                his or her usage of the Internet. In addition, OUTCESS may
                restrict access to certain sites that it deems are not necessary
                for business purposes.{" "}
              </p>
              <p>
                OUTCESS’s connection to the Internet may not be used for any of
                the following activities:{" "}
              </p>
              <p>
                • The Internet must not be used to access, create, transmit,
                print or download material that is derogatory, defamatory,
                obscene, or offensive, such as slurs, epithets, or anything that
                may be construed as harassment or disparagement based on race,
                color, national origin, sex, sexual orientation, age,
                disability, medical condition, marital status, or religious or
                political beliefs.{" "}
              </p>
              <p>
                • The Internet must not be used to access, send, receive or
                solicit sexually‐oriented messages or images.{" "}
              </p>
              <p>
                • Downloading or disseminating of copyrighted material that is
                available on the Internet is an infringement of copyright law.
                Permission to copy the material must be obtained from the
                publisher.{" "}
              </p>
              <p>
                {" "}
                Without prior approval of the Managing Director, software should
                not be downloaded from the Internet as the download could
                introduce a computer virus onto OUTCESS’s computer equipment. In
                addition, copyright laws may cover the software so the
                downloading could be an infringement of copyright law.{" "}
              </p>
              <p>
                • Employees should safeguard against using the Internet to
                transmit personal comments or statements through e‐mail or to
                post information to news groups that may be mistaken as the
                position of OUTCESS.{" "}
              </p>
              <p>
                • Employees should guard against the disclosure of confidential
                information through the use of Internet e‐mail or news groups.{" "}
              </p>
              <p>
                • Employees should not download personal e‐mail or Instant
                Messaging software to OUTCESS computers.{" "}
              </p>
              <p>
                • The Internet should not be used to send or participate in
                chain letters, pyramid schemes or other illegal schemes.{" "}
              </p>
              <p>
                • The Internet should not be used to solicit or proselytize
                others for commercial purposes, causes, outside organizations,
                chain messages or other non‐job related purposes.{" "}
              </p>
              <p>
                • The Internet should not be used to endorse political
                candidates or campaigns{" "}
              </p>
              <p>
                The Internet provides access to many sites that charge a
                subscription or usage fee to access and use the information on
                the site. Requests for approval must be submitted to your
                supervisor.{" "}
              </p>
              <h5>V. POLICY AGAINST WORKPLACE HARASSMENT </h5>
              <p>
                OUTCESS is committed to providing a work environment for all
                employees that is free from harassment and other types of
                discriminatory harassment. Employees are expected to conduct
                themselves in a professional manner and to show respect for
                their co‐workers.{" "}
              </p>
              <p>
                OUTCESS’s commitment begins with the recognition and
                acknowledgment that sexual harassment and other types of
                discriminatory harassment are, of course, unlawful. To reinforce
                this commitment, OUTCESS has developed a policy against
                harassment and a reporting procedure for employees who have been
                subjected to or witnessed harassment. This policy applies to all
                work‐related settings and activities, whether inside or outside
                the workplace, and includes business trips and business‐related
                social events. OUTCESS’s property (e.g. telephones, copy
                machines, facsimile machines, computers, and computer
                applications such as e‐mail and Internet access) may not be used
                to engage in conduct that violates this policy.{" "}
              </p>
              <p>
                Prohibition of Sexual Harassment: OUTCESS’s policy against
                sexual harassment prohibits sexual advances or requests for
                sexual favors or other physical or verbal conduct of a sexual
                nature, when: (1) submission to such conduct is made an express
                or implicit condition of employment; (2) submission to or
                rejection of such{" "}
              </p>
              <p>
                Conduct is used as a basis for employment decisions affecting
                the individual who submits to or rejects such conduct; or (3)
                such conduct has the purpose or effect of unreasonably
                interfering with an employee’s work performance or creating an
                intimidating, hostile, humiliating, or offensive working
                environment.{" "}
              </p>
              <p>
                While it is not possible to list all of the circumstances which
                would constitute sexual harassment, the following are some
                examples: (1) unwelcome sexual advances (2) requests for sexual
                favors in exchange for actual benefits (3) Coerced sexual acts.{" "}
              </p>
              <p>
                While such behavior, depending on the circumstances, may not be
                severe or pervasive enough to create a sexually hostile work
                environment, it can nonetheless make co‐workers uncomfortable.
                Accordingly, such behavior is inappropriate and may result in
                disciplinary action regardless of whether it is unlawful.{" "}
              </p>
              <p>
                It is also unlawful and expressly against OUTCESS policy to
                retaliate against an employee for filing a complaint of sexual
                harassment or for cooperating with an investigation of a
                complaint of sexual harassment.{" "}
              </p>
              <p>
                Prohibition of Other Types of Discriminatory Harassment: It is
                also against OUTCESS’s policy to engage in verbal or physical
                conduct that denigrates or shows hostility or aversion toward an
                individual because of his or her race, color, gender, religion,
                sexual orientation, age, national origin, disability, or other
                protected category (1) has the purpose or effect of creating an
                intimidating, hostile, humiliating, or offensive working
                environment; (2) has the purpose or effect of unreasonably
                interfering with an individual’s work performance; or (3)
                otherwise adversely affects an individual’s employment
                opportunities.
              </p>
              <p>
                Depending on the circumstances, the following conduct may
                constitute discriminatory harassment: (1) epithets, slurs,
                negative stereotyping, jokes, or threatening, intimidating, or
                hostile acts that relate to race, color, gender, religion,
                sexual orientation, age, national origin, or disability; and (2)
                written or graphic material that denigrates or shows hostility
                toward an individual or group because of race, color, gender,
                religion, sexual orientation, age, national origin, or
                disability and that is circulated in the workplace, or placed
                anywhere in OUTCESS’s premises such as on an employee’s desk or
                workspace or on OUTCESS’s equipment or bulletin boards. Other
                conduct may also constitute discriminatory harassment if it
                falls within the definition of discriminatory harassment set
                forth above.
              </p>
              <p>
                It is also against OUTCESS’s policy to retaliate against an
                employee for filing a complaint of discriminatory harassment or
                for cooperating in an investigation of a complaint of
                discriminatory harassment.{" "}
              </p>
              <p>
                <i>Reporting of Harassment:</i> If you believe that you have
                experienced or witnessed sexual harassment or other
                discriminatory harassment by any employee of OUTCESS, you should
                report the incident immediately to your supervisor or to the
                Executive Director. OUTCESS will promptly and thoroughly
                investigate all reports of harassment as discreetly and
                confidentially as practicable. The investigation would generally
                include a private interview with the person making a report of
                harassment. It would also generally be necessary to discuss
                allegations of harassment with the accused individual and others
                who may have information relevant to the investigation.
                OUTCESS’s goal is to conduct a thorough investigation, to
                determine whether harassment occurred, and to determine what
                action to take if it is determined that improper behavior
                occurred.{" "}
              </p>
              <p>
                If OUTCESS determines that a violation of this policy has
                occurred, it will take appropriate disciplinary action against
                the offending party, which can include counseling, warnings,
                suspensions, and termination. Employees who report violations of
                this policy and employees who cooperate with investigations into
                alleged violations of this policy will not be subject to
                retaliation. Upon completion of the investigation, OUTCESS will
                inform the employee who made the complaint of the results of the
                investigation.{" "}
              </p>
              <p>
                Compliance with this policy is a condition of each employee’s
                employment. Employees are encouraged to raise any questions or
                concerns about this policy or about possible discriminatory
                harassment with the Human Resource Manager.{" "}
              </p>
              <h5>VI. SOLICITATION </h5>
              <p>
                Employees are prohibited from soliciting (personally or via
                electronic mail) for membership, pledges, subscriptions, the
                collection of money or for any other unauthorized purpose
                anywhere on OUTCESS property during work time, especially those
                of a partisan or political nature. “Work time” includes time
                spent in actual performance of job duties but does not include
                lunch periods or breaks. Non‐working employees may not solicit
                or distribute to working employees. Persons who are not employed
                by OUTCESS may not solicit or distribute literature on OUTCESS’s
                premises at any time for any reason.{" "}
              </p>
              <p>
                Employees are prohibited from distributing, circulating or
                posting (on bulletin boards, refrigerators, walls, etc.)
                literature, petitions or other materials at any time for any
                purpose without the prior approval of the Administrative Manager
                or his/her designee.{" "}
              </p>
              <footer>
                Revision 01-Jan-2022 Approved by the Managing Director
              </footer>
              <div className="container__nav">
                <small>
                  By clicking 'Accept' you are agreeing to our terms and
                  conditions.
                </small>
                <button
                  className="Accept-button"
                  type="button"
                  disabled={loading && true}
                  onClick={PolicyInfo}>
                  {loading ? "Accepting..." : "Accept"}
                </button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
    </div>
  );
};

export default PolicyModal;
