import { GET_JOB_ID_REQUEST, GET_JOB_ID_SUCCESS, GET_JOB_ID_RESET, GET_JOB_ID_FAIL, JOB_APPLICANTS_CREATE_REQUEST, JOB_APPLICANTS_CREATE_SUCCESS, JOB_APPLICANTS_CREATE_FAIL, JOB_APPLICANTS_CREATE_RESET, JOB_APPLICANTS_DETAILS_REQUEST, JOB_APPLICANTS_DETAILS_SUCCESS, JOB_APPLICANTS_DETAILS_FAIL, JOB_APPLICANTS_DETAILS_RESET } from '../constants/jobApplicantsConstants'


export const createJobApplicationReducer = (state = {}, action) => {
    switch (action.type) {
        case JOB_APPLICANTS_CREATE_REQUEST:
            return { loading: true }
        case JOB_APPLICANTS_CREATE_SUCCESS:
            return { loading: false, success: true }
        case JOB_APPLICANTS_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case JOB_APPLICANTS_CREATE_RESET:
            return {}
        default:
            return state
    }
}


export const getAllApplicantsDetails = (state = { data: [] }, action) => {
    switch (action.type) {
        case JOB_APPLICANTS_DETAILS_REQUEST:
            return { ...state, loading: true }
        case JOB_APPLICANTS_DETAILS_SUCCESS:
            return {
                loading: false,
                data: action.payload.data,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case JOB_APPLICANTS_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        case JOB_APPLICANTS_DETAILS_RESET:
            return { leaveapplication: {} }
        default:
            return state
    }
}


export const getJobByIdReducer = (state = { job: {} }, action) => {
    switch (action.type) {
        case GET_JOB_ID_REQUEST:
            return { ...state, loading: true }
        case GET_JOB_ID_SUCCESS:
            return { loading: false, job: action.payload.job }
        case GET_JOB_ID_FAIL:
            return { loading: false, error: action.payload }
        case GET_JOB_ID_RESET:
            return { job: {} }
        default:
            return state
    }
}