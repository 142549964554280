import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Message from "../../components/Message";
import { useDispatch, useSelector } from "react-redux";
import { updateUserPassword } from "../../actions/userActions";
import { USER_UPDATE_PASSWORD_RESET } from "../../constants/userConstants";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);

  const [showMessage, setShowMessage] = useState(false);
  const dispatch = useDispatch();

  const userUpdatePassword = useSelector((state) => state.userUpdatePassword);
  const { error, success, loading } = userUpdatePassword;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else {
      if (success) {
        dispatch({
          type: USER_UPDATE_PASSWORD_RESET,
        });
      }
    }
  }, [dispatch, success, navigate, userInfo]);

  useEffect(() => {
    if (success) {
      setShowMessage(true);
      dispatch({
        type: USER_UPDATE_PASSWORD_RESET,
      });
    }
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  }, [dispatch, success]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      //Dispatch
      dispatch(
        updateUserPassword({
          currentPassword,
          newPassword,
        })
      );
      //setShow(true)
    }
  };

  return (
    <div className="dashboard-container">
      <div className="profilescreen-wrapper">
        <div className="dashboard-body">
          <div className="page-header">
            <h3>Dashboard</h3>
            <p>Reset {userInfo.firstname}'s password</p>
          </div>
          {message && (
            <Message variant="danger" className="error-border" dismissible>
              {message}
            </Message>
          )}
          {error && (
            <Message variant="danger" className="error-border" dismissible>
              {error}
            </Message>
          )}
          {showMessage && (
            <Message variant="success" className="success-border">
              {" "}
              <p className="success-color">
                Password Updated successfully!
              </p>{" "}
            </Message>
          )}
          <Form onSubmit={submitHandler} className="form-shadow">
            <Form.Group controlId="crrentPassword">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Current Password"
                value={currentPassword}
                onChange={(e) =>
                  setCurrentPassword(e.target.value)
                }></Form.Control>
            </Form.Group>

            <Form.Group controlId="newpassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}></Form.Control>
            </Form.Group>
            <Form.Group controlId="confirrmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) =>
                  setConfirmPassword(e.target.value)
                }></Form.Control>
            </Form.Group>
            <Button
              variant="primary"
              disabled={loading && true}
              type="submit"
              value="Update">
              {loading ? "Updating..." : "Update"}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
