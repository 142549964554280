import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
import Message from "../../components/Message";
import Logo from "../../img/outcess-logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { css } from "@emotion/css";
import { resetPassword } from "../../actions/userActions";
import { BsEye, BsEyeSlash } from "react-icons/bs";

//Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const ResetPasswordScreen = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const token = id;

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [passwordShown, setPasswordShown] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const dispatch = useDispatch();

  const userResetPassword = useSelector((state) => state.userResetPassword);
  const { loading, error, success } = userResetPassword;

  useEffect(() => {
    if (success) {
      setShowMessage(true);
      setTimeout(() => {
        window.location.replace("/home");
        setShowMessage(false);
      }, 4000);
    }
  }, [navigate, success]);

  const submitHandler = (e) => {
    e.preventDefault();
    //Dispatch will go here
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      dispatch(resetPassword(password, token));
    }
  };

  return (
    <div className="body-login">
      <div class="route" id="welcome"></div>
      <form id="appLogin" onSubmit={submitHandler}>
        <div class="app-view">
          <header class="app-header">
            <div className="" style={{ width: "200px", height: "100px" }}>
              <img
                src={Logo}
                alt="Outcess"
                style={{ width: "100%", height: "32%", objectFit: "cover" }}
              />
            </div>
            Hello there!
            <br />
            <span class="app-subheading">
              Enter your new password to continue.
            </span>
            {error && (
              <Message variant="danger" className="error-border" dismissible>
                {error}
              </Message>
            )}
            {message && (
              <Message variant="danger" className="error-border" dismissible>
                {message}
              </Message>
            )}
            {showMessage && (
              <Message variant="success" className="success-border" dismissible>
                <p className="success-color">
                  Your password have been reset successfully!
                </p>
              </Message>
            )}
          </header>

          <input
            type="email"
            required
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            type={passwordShown ? "text" : "password"}
            required
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <span id="togglePassword" onClick={togglePasswordVisiblity}>
            {passwordShown ? <BsEye /> : <BsEyeSlash />}
          </span>

          <button type="submit" className="app-button" onClick={submitHandler}>
            {loading ? (
              <ScaleLoader
                css={override}
                sizeUnit={"px"}
                size={40}
                color={"#fff"}
                loading={loading}
              />
            ) : (
              "Send"
            )}
          </button>

          <div class="app-register" onClick={() => navigate("/")}>
            Login
          </div>
          <svg
            id="svg-lines"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 284.2 152.7">
            <path
              class="st0"
              d="M37.7,107.3h222.6c12,0,21.8,9.7,21.8,21.7s-9.7,21.8-21.8,21.8c0,0-203.6,0-222.6,0S2.2,138.6,2.2,103.3   c0-52,113.5-101.5,141-101.5c13.5,0,21.8,9.7,21.8,21.8s-9.7,21.7-21.8,21.7s-21.8-9.7-21.8-21.7s9.7-21.8,21.8-21.8"
            />
            <path
              class="st1"
              d="M260.2,76.3L250,87.8l-9-9c-6.2-6.2,2-24.7,17.2-24.7c15.2,0,23.9,17.7,23.9,29.7s-11.7,23.5-23.9,23.5h-10.2"></path>
            <g class="svg-loader" xmlns="http://www.w3.org/2000/svg">
              <path
                class="svg-loader-segment -cal"
                d="M164.7,23.5c0-12-9.7-21.8-21.8-21.8"
              />
              <path
                class="svg-loader-segment -heart"
                d="M143,45.2c12,0,21.8-9.7,21.8-21.7"
              />
              <path
                class="svg-loader-segment -steps"
                d="M121.2,23.5c0,12,9.7,21.7,21.8,21.7"
              />
              <path
                class="svg-loader-segment -temp"
                d="M143,1.7c-12,0-21.8,9.7-21.8,21.8"
              />
            </g>
          </svg>
        </div>
      </form>
      <aside class="meta"></aside>
    </div>
  );
};

export default ResetPasswordScreen;
