import React, { useState } from "react";
import { useParams } from "react-router-dom";
import StaffEditScreen from "../Profile/StaffEditScreen";
import AdminProfileScreen from "./AdminProfileScreen";
import AdminEducationScreen from "./AdminEducationScreen";
import AdminNextOfKinScreen from "./AdminNextOfKinScreen";
import PasswordScreen from "../Profile/PasswordScreen";

const AdminEditScreen = () => {
  const { id } = useParams();

  const [activeTab, setActiveTab] = useState(1);
  return (
    <div>
      <div>
        <div id="reports">
          <div className="Inventory-flex-icon">
            <div className="nav-tabs">
              <li
                className={activeTab === 1 ? "active" : ""}
                onClick={() => setActiveTab(1)}>
                Employee Details
              </li>
              <li
                className={activeTab === 2 ? "active" : ""}
                onClick={() => setActiveTab(2)}>
                Profile
              </li>
              <li
                className={activeTab === 3 ? "active" : ""}
                onClick={() => setActiveTab(3)}>
                Education
              </li>
              <li
                className={activeTab === 4 ? "active" : ""}
                onClick={() => setActiveTab(4)}>
                Next of Kin
              </li>
              <li
                className={activeTab === 5 ? "active" : ""}
                onClick={() => setActiveTab(5)}>
                Reset Password
              </li>
            </div>
          </div>
          <div className="tab-panel">
            <div>
              {activeTab === 1 && <StaffEditScreen id={id} />}
              {activeTab === 2 && <AdminProfileScreen id={id} />}
              {activeTab === 3 && <AdminEducationScreen id={id} />}
              {activeTab === 4 && <AdminNextOfKinScreen id={id} />}
              {activeTab === 5 && <PasswordScreen id={id} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminEditScreen;
