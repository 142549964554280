import { Modal } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { GoGraph } from "react-icons/go";
import ModalHeader from "../ModalHeader";

const FullBarChart = ({ employees, setShow, show }) => {
  // Group data by department
  const groupedData = employees?.reduce((acc, employee) => {
    const { department } = employee;
    if (!acc[department]) {
      acc[department] = [];
    }
    acc[department].push(employee);
    return acc;
  }, {});

  // Get the length of each group
  const departmentLengths =
    Object.keys(!groupedData ? [] : groupedData)?.map((department) => ({
      department,
      length: groupedData[department]?.length,
    })) ?? [];

  const data = {
    labels: departmentLengths?.map((item) => item?.department),
    datasets: [
      {
        label: "People in Department",
        data: departmentLengths?.map((item) => item?.length),
        backgroundColor: "#124383",
        barPercentage: 0.2,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: true,
    },
    scales: {
      x: {
        ticks: {
          color: "#252733",
        },
        grid: {
          display: false,
          borderColor: "#252733",
          color: "#005B90",
        },
      },

      y: {
        ticks: {
          padding: 5,
          color: " #252733",
        },
        grid: {
          borderDash: [8, 6],
          color: "#adb5bd",
          drawBorder: false,
          drawTicks: false,
        },
      },
    },
  };

  return (
    <div id="new">
      <Modal show={show} onHide={() => setShow(false)} size="xl">
        <ModalHeader
          setLgShow={setShow}
          icon={<GoGraph size={30} />}
          title={"Full Chat"}
          subtitle={"Employee in Department!"}
        />

        <Modal.Body>
          <Bar data={data} options={options} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FullBarChart;
