import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'

const Message = ({ variant, children, dismissible, className }) => {
    const [show, setShow] = useState(true);

    return (
        <Alert show={show} variant={variant} className={className} onClick={() => setShow(false)} dismissible={dismissible} >
            {children}
        </Alert>
    )
}

Message.defaultProps = {
    variant: 'info',
    color: '#fff'
}

export default Message
