import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getAllEmployeeLeaveApplications } from "../../actions/leaveApplication";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import {
  NoRecordFound,
  SearchComponent,
  TableFetch,
} from "../../components/TableOptions";
import Pagination from "../../components/Pagination";

const AllLeaveApplications = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [displayData, setDisplayData] = useState([]);
  const getLeaveAppDetails = useSelector((state) => state.getLeaveAppDetails);
  const { data: datainfo, loading, error } = getLeaveAppDetails;

  const [data, setData] = useState([]);
  const [filtered, setFilterd] = useState([]);
  const [result, setResult] = useState("");

  const [entriesPerPage, setEntriesPerPage] = useState(() => {
    return localStorage.getItem("rowsPerPage") || "8";
  });

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role === "Human Resource Executive" ||
        userInfo.role === "Head Of Department - HR" ||
        userInfo.role === "CEO" ||
        userInfo.role === "Super Admin" ||
        userInfo.role === "Assistant Manager - Human Resources" ||
        userInfo.role === "Human Resource Manager")
    ) {
      dispatch(getAllEmployeeLeaveApplications());
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    setFilterd(datainfo);
    localStorage.setItem("rowsPerPage", entriesPerPage);
  }, [datainfo, entriesPerPage]);

  useEffect(() => {
    const results = filtered?.filter((data) =>
      data?.employee?.email?.toLowerCase()?.includes(result)
    );
    setData(results);
  }, [result, filtered]);

  const onChange = (e) => {
    setResult(e.target.value);
  };

  return (
    <div id="reports">
      <h5 className="page-title mb-4">Agent Leave Applications</h5>

      <div className="half-background">
        <SearchComponent
          sortData={data}
          entriesPerPage={entriesPerPage}
          setEntriesPerPage={setEntriesPerPage}
          placeholder={"All Leave Application"}
          value={result}
          onChange={onChange}
          LeaveDownloadert={true}
          LeaveDownloaderdata={data}
          userInfo={userInfo}
        />

        <section className="md-ui component-data-table">
          {error && (
            <Message variant="danger" className="error-border" dismissible>
              {error}
            </Message>
          )}
          {loading && <Loader />}
          <div className="main-table-wrapper">
            <table className="main-table-content">
              <thead className="data-table-header">
                <tr className="data-table-row">
                  <th>Email Address</th>
                  <th>Leave Type</th>
                  <th>Start Date</th>
                  <th>N0: Days</th>
                  <th>HOD Approval</th>
                  <th>Final Approval</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <TableFetch colSpan={8} />
                ) : displayData?.length === 0 || displayData == null ? (
                  <NoRecordFound colSpan={8} />
                ) : (
                  displayData?.map((user) => (
                    <tr key={user?._id}>
                      <td>{user?.employee?.email}</td>
                      <td>{user?.leaveType}</td>
                      <td>{moment(user?.fromDate).format("DD-MM-YYYY")}</td>
                      <td>{user?.noOfDays}</td>
                      <td>
                        {user?.hodApproval ? (
                          <i
                            className="fas fa-check-circle"
                            style={{ color: "green" }}></i>
                        ) : (
                          <i
                            className="fas fa-times-circle"
                            style={{ color: "red" }}></i>
                        )}
                      </td>
                      <td>
                        {user?.finalApproval ? (
                          <i
                            className="fas fa-check-circle"
                            style={{ color: "green" }}></i>
                        ) : (
                          <i
                            className="fas fa-times-circle"
                            style={{ color: "red" }}></i>
                        )}
                      </td>
                      <td>
                        <NavLink
                          to={`/myleave/${user?._id}/update`}
                          exact
                          className="update-btn rounded-5"
                          style={{ background: "#E2522E", boxShadow: "none" }}>
                          View
                        </NavLink>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </section>
        <footer className="main-table-footer">
          <Pagination
            setDisplayData={setDisplayData}
            data={data}
            entriesPerPage={entriesPerPage}
            Total={"Leave Applications"}
          />
        </footer>
      </div>
    </div>
  );
};

export default AllLeaveApplications;
