const getUserPrivileges = () => {
  const userStrings = localStorage.getItem("userInfo");
  const userString = JSON.parse(userStrings);

  const privileges = userString ? userString : null;

  const isSuperAdmin = privileges.role === "Super Admin";
  const isCEO = privileges.role === "CEO";
  const isHRHead = privileges.role === "Head Of Department - HR";
  const isHod = privileges.role === "Head Of Department";
  const isHRAssistantManager =
    privileges.role === "Assistant Manager - Human Resources";
  const isHRManager = privileges.role === "Human Resource Manager";
  const isHRExecutive = privileges.role === "Human Resource Executive";
  const isTeamLead = privileges.role === "Team Lead";
  const isMis = privileges.role === "MIS Lead";
  const isAgent = privileges.role === "Agent";
  const isIT = privileges.role === "IT Support Specialist";
  const isQA = privileges.role === "QA - Lead";
  const isHeadofDepartment = privileges.role === "Head Of Department";

  return {
    isHRHead,
    isCEO,
    isSuperAdmin,
    isHRAssistantManager,
    isHRManager,
    isHRExecutive,
    isTeamLead,
    isMis,
    isAgent,
    isHod,
    isIT,
    isQA,
    isHeadofDepartment,
  };
};

export default getUserPrivileges;
