import React from "react";
import { Line } from "react-chartjs-2";

const LineChart = ({ resultArray }) => {
  // Function to filter data for a specific year and quarter
  // const filterDataForYearAndQuarter = (data, targetYear, targetQuarter) => {
  //   // Check if data is defined before attempting to filter
  //   if (data) {
  //     return data?.filter(
  //       (item) => item?.year === targetYear && item?.quarter === targetQuarter
  //     );
  //   } else {
  //     // Return an empty array or handle the case when data is undefined
  //     return [];
  //   }
  // };

  // // Function to extract employee performance information for a specific year and quarter
  // const getEmployeePerformanceForYearAndQuarter = (
  //   data,
  //   targetYear,
  //   targetQuarter
  // ) => {
  //   const filteredData = filterDataForYearAndQuarter(
  //     data,
  //     targetYear,
  //     targetQuarter
  //   );

  //   // Extract employee performance information with month names
  //   const employeePerformance = filteredData.map((item) => {
  //     const monthNumber = parseInt(item?.quarter, 10); // Assuming quarter is a string representation of the month
  //     const monthName = new Date(targetYear, monthNumber - 1, 1).toLocaleString(
  //       "en-US",
  //       { month: "long" }
  //     );

  //     return {
  //       month: monthName,
  //       performanceAverage: item.performanceAverage,
  //     };
  //   });

  //   return employeePerformance;
  // };

  // // Get the current year and the previous year
  // const currentYear = "" + new Date().getFullYear();
  // const previousYear = "" + (new Date().getFullYear() - 1);

  // // Define the quarters
  // const quarters = ["1", "2", "3", "4"];
  // const quarterslabel = ["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4"];

  // // Example usage for both current and previous years
  // const categorizedDataForCurrentYear = {};
  // const categorizedDataForPreviousYear = {};

  // quarters.forEach((quarter) => {
  //   categorizedDataForCurrentYear[quarter] =
  //     getEmployeePerformanceForYearAndQuarter(
  //       resultArray,
  //       currentYear,
  //       quarter
  //     );

  //   categorizedDataForPreviousYear[quarter] =
  //     getEmployeePerformanceForYearAndQuarter(
  //       resultArray,
  //       previousYear,
  //       quarter
  //     );
  // });

  // const datasets = [
  //   {
  //     label: `My KPI for ${previousYear}`,
  //     data: quarters.flatMap((quarter) =>
  //       categorizedDataForPreviousYear[quarter]?.map(
  //         (item) => item?.performanceAverage
  //       )
  //     ),
  //     backgroundColor: "#124383",
  //     borderColor: "#124383",
  //     borderWidth: 2,
  //     pointRadius: 4,
  //     pointBackgroundColor: "#ffffff",
  //     pointBorderColor: "#124383",
  //     pointBorderWidth: 2,
  //     fill: false,
  //   },
  //   {
  //     label: `My KPI for ${currentYear}`,
  //     data: quarters.flatMap((quarter) =>
  //       categorizedDataForCurrentYear[quarter]?.map(
  //         (item) => item?.performanceAverage
  //       )
  //     ),
  //     backgroundColor: "#FF5733",
  //     borderColor: "#FF5733",
  //     borderWidth: 2,
  //     pointRadius: 4,
  //     pointBackgroundColor: "#ffffff",
  //     pointBorderColor: "#FF5733",
  //     pointBorderWidth: 2,
  //     fill: false,
  //   },
  // ];

  // const data = {
  //   labels: quarterslabel, // Assuming you want the quarters on the x-axis
  //   datasets,
  // };

  // Function to filter data for a specific year and quarter
  const filterDataForYearAndQuarter = (data, targetYear, targetQuarter) => {
    // Check if data is defined before attempting to filter
    if (data) {
      return data?.filter(
        (item) => item?.year === targetYear && item?.quarter === targetQuarter
      );
    } else {
      // Return an empty array or handle the case when data is undefined
      return [];
    }
  };

  // Function to extract employee performance information for a specific year and quarter
  const getEmployeePerformanceForYearAndQuarter = (
    data,
    targetYear,
    targetQuarter
  ) => {
    // Function to get month name from quarter
    const getMonthNameFromQuarter = (quarter, targetYear) => {
      const monthNumber = parseInt(quarter, 10); // Assuming quarter is a string representation of the month
      return new Date(targetYear, monthNumber - 1, 1).toLocaleString("en-US", {
        month: "long",
      });
    };

    const filteredData = filterDataForYearAndQuarter(
      data,
      targetYear,
      targetQuarter
    );

    // Extract employee performance information with month names
    const employeePerformance = filteredData.map((item) => {
      const monthName = getMonthNameFromQuarter(item?.quarter, targetYear);

      return {
        month: monthName,
        performanceAverage: item?.performanceAverage || 0,
      };
    });

    return employeePerformance;
  };

  // Get the current year and the previous year
  const currentYear = "" + new Date().getFullYear();
  const previousYear = "" + (new Date().getFullYear() - 1);

  // Define the quarters
  const quarters = ["1", "2", "3", "4"];
  const quarterslabel = ["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4"];

  // Example usage for both current and previous years
  const categorizedDataForCurrentYear = {};
  const categorizedDataForPreviousYear = {};

  quarters.forEach((quarter) => {
    categorizedDataForCurrentYear[quarter] =
      getEmployeePerformanceForYearAndQuarter(
        resultArray,
        currentYear,
        quarter
      );

    categorizedDataForPreviousYear[quarter] =
      getEmployeePerformanceForYearAndQuarter(
        resultArray,
        previousYear,
        quarter
      );

    // Replace empty quarters with an array of zeros
    if (!categorizedDataForCurrentYear[quarter]) {
      categorizedDataForCurrentYear[quarter] = Array(quarters.length).fill(0);
    }

    if (!categorizedDataForPreviousYear[quarter]) {
      categorizedDataForPreviousYear[quarter] = Array(quarters.length).fill(0);
    }
  });

  const datasets = [
    {
      label: `My KPI for ${previousYear}`,
      data: quarters.flatMap((quarter) =>
        categorizedDataForPreviousYear[quarter]?.map(
          (item) => item?.performanceAverage || Array(quarters.length).fill(0)
        )
      ),
      backgroundColor: "#124383",
      borderColor: "#124383",
      borderWidth: 2,
      pointRadius: 4,
      pointBackgroundColor: "#ffffff",
      pointBorderColor: "#124383",
      pointBorderWidth: 2,
      fill: false,
    },
    {
      label: `My KPI for ${currentYear}`,
      data: quarters.flatMap((quarter) =>
        categorizedDataForCurrentYear[quarter]?.map(
          (item) => item?.performanceAverage || Array(quarters.length).fill(0)
        )
      ),
      backgroundColor: "#FF5733",
      borderColor: "#FF5733",
      borderWidth: 2,
      pointRadius: 4,
      pointBackgroundColor: "#ffffff",
      pointBorderColor: "#FF5733",
      pointBorderWidth: 2,
      fill: false,
    },
  ];

  // Filter quarters and labels for which data is available
  const availableQuarters = quarters.filter((quarter) => {
    return (
      categorizedDataForPreviousYear[quarter]?.length > 0 ||
      categorizedDataForCurrentYear[quarter]?.length > 0
    );
  });

  const availableLabels = availableQuarters.map((quarter) => {
    return quarterslabel[parseInt(quarter) - 1]; // Adjusting for zero-based indexing
  });

  const data = {
    labels: availableLabels, // Assuming you want the quarters on the x-axis
    datasets,
  };

  // Options for the Line chart
  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Quarters",
        },
      },
      y: {
        min: 0,
        max: 100,
        title: {
          display: true,
          text: "Performance Average",
        },
      },
    },
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
