import { HOD_LAPTOP_DETAILS_REQUEST, HOD_LAPTOP_DETAILS_SUCCESS, HOD_LAPTOP_DETAILS_RESET, HOD_LAPTOP_DETAILS_FAIL, LAPTOP_APPLICATION_CREATE_REQUEST, LAPTOP_APPLICATION_CREATE_SUCCESS, LAPTOP_APPLICATION_CREATE_FAIL, LAPTOP_APPLICATION_CREATE_RESET, LAPTOP_APPLICATION_DETAILS_REQUEST, LAPTOP_APPLICATION_DETAILS_SUCCESS, LAPTOP_APPLICATION_DETAILS_FAIL, LAPTOP_APPLICATION_DETAILS_RESET, LAPTOP_REQUEST_DETAILS_ID_HOD_REQUEST, LAPTOP_REQUEST_DETAILS_ID_HOD_SUCCESS, LAPTOP_REQUEST_DETAILS_ID_HOD_FAIL, LAPTOP_REQUEST_DETAILS_ID_HOD_RESET, APPROVE_HOD_LAPTOP_ID_REQUEST, APPROVE_HOD_LAPTOP_ID_SUCCESS, APPROVE_HOD_LAPTOP_ID_FAIL, APPROVE_HOD_LAPTOP_ID_RESET, REJECT_HOD_LAPTOP_ID_REQUEST, REJECT_HOD_LAPTOP_ID_SUCCESS, REJECT_HOD_LAPTOP_ID_FAIL, REJECT_HOD_LAPTOP_ID_RESET, ADMIN_APPROVE_HOD_LAPTOP_ID_REQUEST, ADMIN_APPROVE_HOD_LAPTOP_ID_SUCCESS, ADMIN_APPROVE_HOD_LAPTOP_ID_FAIL, ADMIN_APPROVE_HOD_LAPTOP_ID_RESET, CEO_APPROVE_HOD_LAPTOP_ID_REQUEST, CEO_APPROVE_HOD_LAPTOP_ID_SUCCESS, CEO_APPROVE_HOD_LAPTOP_ID_FAIL, CEO_APPROVE_HOD_LAPTOP_ID_RESET, GET_LAPTOP_INFO_DETAILS_REQUEST, GET_LAPTOP_INFO_DETAILS_SUCCESS, GET_LAPTOP_INFO_DETAILS_FAIL, GET_LAPTOP_INFO_DETAILS_RESET, CREATE_LAPTOP_INFO_REQUEST, CREATE_LAPTOP_INFO_SUCCESS, CREATE_LAPTOP_INFO_FAIL, CREATE_LAPTOP_INFO_RESET, LAPTOP_INFO_DETAILS_ID_REQUEST, LAPTOP_INFO_DETAILS_ID_SUCCESS, LAPTOP_INFO_DETAILS_ID_FAIL, LAPTOP_INFO_DETAILS_ID_RESET, LAPTOP_INFO_UPDATE_ID_REQUEST, LAPTOP_INFO_UPDATE_ID_SUCCESS, LAPTOP_INFO_UPDATE_ID_FAIL, LAPTOP_INFO_UPDATE_ID_RESET } from '../constants/laptopApplicationContants'



export const createLaptopAppReducer = (state = {}, action) => {
    switch (action.type) {
        case LAPTOP_APPLICATION_CREATE_REQUEST:
            return { loading: true }
        case LAPTOP_APPLICATION_CREATE_SUCCESS:
            return { loading: false, success: true }
        case LAPTOP_APPLICATION_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case LAPTOP_APPLICATION_CREATE_RESET:
            return {}
        default:
            return state
    }
}


export const getHodLaptopDetailsReducer = (state = { data: [] }, action) => {
    switch (action.type) {
        case HOD_LAPTOP_DETAILS_REQUEST:
            return { ...state, loading: true }
        case HOD_LAPTOP_DETAILS_SUCCESS:
            return {
                loading: false,
                data: action.payload.data,
            }
        case HOD_LAPTOP_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        case HOD_LAPTOP_DETAILS_RESET:
            return { laptoprequest: {} }
        default:
            return state
    }
}


export const getAllApplicantsDetailsReducer = (state = { data: [] }, action) => {
    switch (action.type) {
        case LAPTOP_APPLICATION_DETAILS_REQUEST:
            return { ...state, loading: true }
        case LAPTOP_APPLICATION_DETAILS_SUCCESS:
            return {
                loading: false,
                data: action.payload.data,
            }
        case LAPTOP_APPLICATION_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        case LAPTOP_APPLICATION_DETAILS_RESET:
            return { laptoprequest: {} }
        default:
            return state
    }
}


export const laptopRequestDetailsByIdReducer = (state = { laptoprequest: {} }, action) => {
    switch (action.type) {
        case LAPTOP_REQUEST_DETAILS_ID_HOD_REQUEST:
            return { ...state, loading: true }
        case LAPTOP_REQUEST_DETAILS_ID_HOD_SUCCESS:
            return { loading: false, laptoprequest: action.payload.data }
        case LAPTOP_REQUEST_DETAILS_ID_HOD_FAIL:
            return { loading: false, error: action.payload }
        case LAPTOP_REQUEST_DETAILS_ID_HOD_RESET:
            return { laptoprequest: {} }
        default:
            return state
    }
}

export const approveHodLaptopRequestReducer = (state = {}, action) => {
    switch (action.type) {
        case APPROVE_HOD_LAPTOP_ID_REQUEST:
            return { loading: true }
        case APPROVE_HOD_LAPTOP_ID_SUCCESS:
            return { loading: false, success: true, userInfo: action.payload }
        case APPROVE_HOD_LAPTOP_ID_FAIL:
            return { loading: false, error: action.payload }
        case APPROVE_HOD_LAPTOP_ID_RESET:
            return {}
        default:
            return state
    }
}
export const adminApproveHodLaptopRequestReducer = (state = {}, action) => {
    switch (action.type) {
        case ADMIN_APPROVE_HOD_LAPTOP_ID_REQUEST:
            return { loading: true }
        case ADMIN_APPROVE_HOD_LAPTOP_ID_SUCCESS:
            return { loading: false, success: true, userInfo: action.payload }
        case ADMIN_APPROVE_HOD_LAPTOP_ID_FAIL:
            return { loading: false, error: action.payload }
        case ADMIN_APPROVE_HOD_LAPTOP_ID_RESET:
            return {}
        default:
            return state
    }
}
export const ceoApproveHodLaptopRequestReducer = (state = {}, action) => {
    switch (action.type) {
        case CEO_APPROVE_HOD_LAPTOP_ID_REQUEST:
            return { loading: true }
        case CEO_APPROVE_HOD_LAPTOP_ID_SUCCESS:
            return { loading: false, success: true, userInfo: action.payload }
        case CEO_APPROVE_HOD_LAPTOP_ID_FAIL:
            return { loading: false, error: action.payload }
        case CEO_APPROVE_HOD_LAPTOP_ID_RESET:
            return {}
        default:
            return state
    }
}


export const rejectHodLaptopRequestReducer = (state = {}, action) => {
    switch (action.type) {
        case REJECT_HOD_LAPTOP_ID_REQUEST:
            return { loading: true }
        case REJECT_HOD_LAPTOP_ID_SUCCESS:
            return { loading: false, success: true, userInfo: action.payload }
        case REJECT_HOD_LAPTOP_ID_FAIL:
            return { loading: false, error: action.payload }
        case REJECT_HOD_LAPTOP_ID_RESET:
            return {}
        default:
            return state
    }
}

export const createSystemInfoReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_LAPTOP_INFO_REQUEST:
            return { loading: true }
        case CREATE_LAPTOP_INFO_SUCCESS:
            return { loading: false, success: true }
        case CREATE_LAPTOP_INFO_FAIL:
            return { loading: false, error: action.payload }
        case CREATE_LAPTOP_INFO_RESET:
            return {}
        default:
            return state
    }
}


export const getAllLaptopInfoDetailsReducer = (state = { data: [] }, action) => {
    switch (action.type) {
        case GET_LAPTOP_INFO_DETAILS_REQUEST:
            return { ...state, loading: true }
        case GET_LAPTOP_INFO_DETAILS_SUCCESS:
            return {
                loading: false,
                data: action.payload.data,
            }
        case GET_LAPTOP_INFO_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        case GET_LAPTOP_INFO_DETAILS_RESET:
            return { laptoprequest: {} }
        default:
            return state
    }
}


export const getAllLaptopInfoByIdReducer = (state = { laptop: {} }, action) => {
    switch (action.type) {
        case LAPTOP_INFO_DETAILS_ID_REQUEST:
            return { ...state, loading: true }
        case LAPTOP_INFO_DETAILS_ID_SUCCESS:
            return { loading: false, laptop: action.payload.career }
        case LAPTOP_INFO_DETAILS_ID_FAIL:
            return { loading: false, error: action.payload }
        case LAPTOP_INFO_DETAILS_ID_RESET:
            return { laptop: {} }
        default:
            return state
    }
}


export const updateLaptopInfoByIdReducer = (state = { laptop: {} }, action) => {
    switch (action.type) {
        case LAPTOP_INFO_UPDATE_ID_REQUEST:
            return { ...state, loading: true }
        case LAPTOP_INFO_UPDATE_ID_SUCCESS:
            return { loading: false, success: true, laptop: action.payload.data }
        case LAPTOP_INFO_UPDATE_ID_FAIL:
            return { loading: false, error: action.payload }
        case LAPTOP_INFO_UPDATE_ID_RESET:
            return { laptop: {} }
        default:
            return state
    }
}