export const LEAVE_APPLICATION_CREATE_REQUEST = 'LEAVE_APPLICATION_CREATE_REQUEST';
export const LEAVE_APPLICATION_CREATE_SUCCESS = 'LEAVE_APPLICATION_CREATE_SUCCESS';
export const LEAVE_APPLICATION_CREATE_FAIL = 'LEAVE_APPLICATION_CREATE_FAIL';
export const LEAVE_APPLICATION_CREATE_RESET = 'LEAVE_APPLICATION_CREATE_RESET';

export const LEAVE_APPLICATION_REJECT_REQUEST = 'LEAVE_APPLICATION_REJECT_REQUEST';
export const LEAVE_APPLICATION_REJECT_SUCCESS = 'LEAVE_APPLICATION_REJECT_SUCCESS';
export const LEAVE_APPLICATION_REJECT_FAIL = 'LEAVE_APPLICATION_REJECT_FAIL';
export const LEAVE_APPLICATION_REJECT_RESET = 'LEAVE_APPLICATION_REJECT_RESET';

export const EMPLOYEE_UPDATE_LEAVE_APPLICATION_REQUEST = 'EMPLOYEE_UPDATE_LEAVE_APPLICATION_REQUEST';
export const EMPLOYEE_UPDATE_LEAVE_APPLICATION_SUCCESS = 'EMPLOYEE_UPDATE_LEAVE_APPLICATION_SUCCESS';
export const EMPLOYEE_UPDATE_LEAVE_APPLICATION_FAIL = 'EMPLOYEE_UPDATE_LEAVE_APPLICATION_FAIL';
export const EMPLOYEE_UPDATE_LEAVE_APPLICATION_RESET = 'EMPLOYEE_UPDATE_LEAVE_APPLICATION_RESET';

export const LEAVE_APPLICATION_DETAILS_REQUEST = 'LEAVE_APPLICATION_DETAILS_REQUEST';
export const LEAVE_APPLICATION_DETAILS_SUCCESS = 'LEAVE_APPLICATION_DETAILS_SUCCESS';
export const LEAVE_APPLICATION_DETAILS_FAIL = 'LEAVE_APPLICATION_DETAILS_FAIL';
export const LEAVE_APPLICATION_DETAILS_RESET = 'LEAVE_APPLICATION_DETAILS_RESET';

export const LEAVE_APPLICATION_UPDATE_EMPLOYEE_REQUEST = 'LEAVE_APPLICATION_UPDATE_EMPLOYEE_REQUEST';
export const LEAVE_APPLICATION_UPDATE_EMPLOYEE_SUCCESS = 'LEAVE_APPLICATION_UPDATE_EMPLOYEE_SUCCESS';
export const LEAVE_APPLICATION_UPDATE_EMPLOYEE_FAIL = 'LEAVE_APPLICATION_UPDATE_EMPLOYEE_FAIL';
export const LEAVE_APPLICATION_UPDATE_EMPLOYEE_RESET = 'LEAVE_APPLICATION_UPDATE_EMPLOYEE_RESET';

export const HOD_LEAVE_APPLICATION_UPDATE_EMPLOYEE_REQUEST = 'HOD_LEAVE_APPLICATION_CREATE_EMPLOYEE_REQUEST';
export const HOD_LEAVE_APPLICATION_UPDATE_EMPLOYEE_SUCCESS = 'HOD_LEAVE_APPLICATION_CREATE_EMPLOYEE_SUCCESS';
export const HOD_LEAVE_APPLICATION_UPDATE_EMPLOYEE_FAIL = 'HOD_LEAVE_APPLICATION_CREATE_EMPLOYEE_FAIL';
export const HOD_LEAVE_APPLICATION_UPDATE_EMPLOYEE_RESET = 'HOD_LEAVE_APPLICATION_CREATE_EMPLOYEE_RESET';

export const LEAVE_APPLICATION_DETAILS_EMPLOYEE_REQUEST = 'LEAVE_APPLICATION_DETAILS_EMPLOYEE_REQUEST';
export const LEAVE_APPLICATION_DETAILS_EMPLOYEE_SUCCESS = 'LEAVE_APPLICATION_DETAILS_EMPLOYEE_SUCCESS';
export const LEAVE_APPLICATION_DETAILS_EMPLOYEE_FAIL = 'LEAVE_APPLICATION_DETAILS_EMPLOYEE_FAIL';
export const LEAVE_APPLICATION_DETAILS_EMPLOYEE_RESET = 'LEAVE_APPLICATION_DETAILS_EMPLOYEE_RESET';

export const LEAVE_APPLICATION_DETAILS_ID_REQUEST = 'LEAVE_APPLICATION_DETAILS_ID_REQUEST';
export const LEAVE_APPLICATION_DETAILS_ID_SUCCESS = 'LEAVE_APPLICATION_DETAILS_ID_SUCCESS';
export const LEAVE_APPLICATION_DETAILS_ID_FAIL = 'LEAVE_APPLICATION_DETAILS_ID_FAIL';
export const LEAVE_APPLICATION_DETAILS_ID_RESET = 'LEAVE_APPLICATION_DETAILS_ID_RESET';

export const LEAVE_APPLICATION_DETAILS_ID_HOD_REQUEST = 'LEAVE_APPLICATION_DETAILS_ID_HOD_REQUEST';
export const LEAVE_APPLICATION_DETAILS_ID_HOD_SUCCESS = 'LEAVE_APPLICATION_DETAILS_ID_HOD_SUCCESS';
export const LEAVE_APPLICATION_DETAILS_ID_HOD_FAIL = 'LEAVE_APPLICATION_DETAILS_ID_HOD_FAIL';
export const LEAVE_APPLICATION_DETAILS_ID_HOD_RESET = 'LEAVE_APPLICATION_DETAILS_ID_HOD_RESET';

export const DEPARTMENT_LEAVE_APPLICATIONS_REQUEST = 'DEPARTMENT_LEAVE_APPLICATIONS_REQUEST';
export const DEPARTMENT_LEAVE_APPLICATIONS_SUCCESS = 'DEPARTMENT_LEAVE_APPLICATIONS_SUCCESS';
export const DEPARTMENT_LEAVE_APPLICATIONS_FAIL = 'DEPARTMENT_LEAVE_APPLICATIONS_FAIL';
export const DEPARTMENT_LEAVE_APPLICATIONS_RESET = 'DEPARTMENT_LEAVE_APPLICATIONS_RESET';
