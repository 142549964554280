export const ADMIN_CREATE_JOB_POST_REQUEST = "ADMIN_CREATE_JOB_POST_REQUEST";
export const ADMIN_CREATE_JOB_POST_SUCCESS = "ADMIN_CREATE_JOB_POST_SUCCESS";
export const ADMIN_CREATE_JOB_POST_FAIL = "ADMIN_CREATE_JOB_POST_FAIL";
export const ADMIN_CREATE_JOB_POST_RESET = "ADMIN_CREATE_JOB_POST_RESET";
 
export const GET_ALL_JOB_POST_BY_ID_REQUEST = "GET_ALL_JOB_POST_BY_ID_REQUEST";
export const GET_ALL_JOB_POST_BY_ID_SUCCESS = "GET_ALL_JOB_POST_BY_ID_SUCCESS";
export const GET_ALL_JOB_POST_BY_ID_FAIL = "GET_ALL_JOB_POST_BY_ID_FAIL";
export const GET_ALL_JOB_POST_BY_ID_RESET = "GET_ALL_JOB_POST_BY_ID_RESET";

export const UPDATE_JOB_POST_BY_ID_REQUEST = "UPDATE_JOB_POST_BY_ID_REQUEST";
export const UPDATE_JOB_POST_BY_ID_SUCCESS = "UPDATE_JOB_POST_BY_ID_SUCCESS";
export const UPDATE_JOB_POST_BY_ID_FAIL = "UPDATE_JOB_POST_BY_ID_FAIL";
export const UPDATE_JOB_POST_BY_ID_RESET = "UPDATE_JOB_POST_BY_ID_RESET";
