import React from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Button } from "@material-ui/core";

const LeaveDownloader = ({ data }) => {
  const headers = [
    { label: "Employee Email", key: "email" },
    { label: "Leave Type", key: "leaveType" },
    { label: "Date", key: "createdAt" },
    { label: "N0: Days", key: "noOfDays" },
    { label: "Start: Date", key: "fromDate" },
    { label: "End Date", key: "toDate" },
    { label: "Reason For Leave", key: "reasonForLeave" },
    { label: "HOD Approval", key: "hodApproval" },
    { label: "Final Approval", key: "finalApproval" },
  ];

  const loopData = (data) => {
    const newData = [];
    data?.forEach((item) => {
      newData?.push({
        email: item.employee.email,
        leaveType: item.leaveType,
        createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
        noOfDays: item.noOfDays,
        fromDate: moment(item.fromDate).format("DD-MM-YYYY"),
        toDate: moment(item.toDate).format("DD-MM-YYYY"),
        reasonForLeave: item.reasonForLeave,
        hodApproval: item.hodApproval,
        finalApproval: item.finalApproval,
      });
    });
    return newData;
  };
  const exportData = loopData(data);

  return (
    <CSVLink data={exportData} headers={headers} filename={"Leave Details.csv"}>
      <Button className="CSV-button">Download-csv</Button>
    </CSVLink>
  );
};

export default LeaveDownloader;
