import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
import Message from "../../components/Message";
import Logo from "../../img/outcess-logo.png";
import { useNavigate } from "react-router-dom";
import { login, forgotPasswordAction } from "../../actions/userActions";
import { css } from "@emotion/css";
import { USER_FORGOT_PASSWORD_RESET } from "../../constants/userConstants";
import emailjs from "emailjs-com";
import { BsEye, BsEyeSlash } from "react-icons/bs";

//Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
`;

function LoginTestScreen() {
  const navigate = useNavigate();
  const form = useRef();
  const [email, setEmail] = useState("");
  const [forgetEmail, setForgetEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [forgetMassage, setForgetMassage] = useState([]);
  const [resetUrl, setresetUrl] = useState("");

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const userForgotPassword = useSelector((state) => state.userForgotPassword);
  const {
    user,
    loading: loadingForgot,
    error: errorForgot,
    success: successForgot,
  } = userForgotPassword;

  useEffect(() => {
    if (user?.resetUrl !== undefined) {
      setresetUrl(
        "Kindly Click on the link below to reset your password " +
          user?.resetUrl
      );
    }
  }, [user]);

  useEffect(() => {
    if (userInfo) {
      // navigate("/home");
      window.location.replace("/home");
    }
  }, [dispatch, navigate, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  const submitForgetHandler = (e) => {
    e.preventDefault();
    dispatch(forgotPasswordAction(forgetEmail));
  };

  useEffect(() => {
    if (successForgot) {
      setShowMessage(true);
      setTimeout(() => {
        emailjs
          .sendForm(
            "service_v4rxd7i",
            "template_19s5pkw",
            form.current,
            "nsrAhgU-pHN9kQRIs"
          )
          .then(
            (result) => console.log(result.text),
            (error) => console.log(error.text)
          );

        setForgetEmail(" ");
        dispatch({
          type: USER_FORGOT_PASSWORD_RESET,
        });
      }, 5000);
    }

    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  }, [dispatch, successForgot]);

  // Automatic Mail Notification
  useEffect(() => {
    setForgetMassage([
      "Kindly Click on the link below to reset your password" + resetUrl,
    ]);
  }, [resetUrl]);

  const handleForget = () => {
    if (!forgetPassword) {
      setForgetPassword(true);
    } else {
      setForgetPassword(false);
    }
  };

  return (
    <div className="body-login">
      <div class="route" id="welcome"></div>
      <form id="appLogin" onSubmit={submitForgetHandler} ref={form}>
        {forgetPassword ? (
          <div class="app-view">
            <header class="app-header">
              <div className="" style={{ width: "200px", height: "100px" }}>
                <img
                  src={Logo}
                  alt="Outcess"
                  style={{ width: "100%", height: "32%", objectFit: "cover" }}
                />
              </div>
              Hello there!
              <br />
              <span class="app-subheading">
                Enter your
                <br />
                Email.
              </span>
              {error && (
                <Message variant="danger" className="error-border" dismissible>
                  {error}
                </Message>
              )}
              {errorForgot && (
                <Message variant="danger" className="error-border" dismissible>
                  {errorForgot}
                </Message>
              )}
              {showMessage && (
                <Message variant="success" className="success-border">
                  {" "}
                  <p className="success-color">
                    Email have been sent to your mail!
                  </p>{" "}
                </Message>
              )}
            </header>

            <input
              type="email"
              required
              pattern=".*\.\w{2,}"
              placeholder="Email Address"
              value={forgetEmail}
              onChange={(e) => setForgetEmail(e.target.value)}
            />
            <div style={{ display: "none" }}>
              <input
                className="display-Forget-input"
                name="email"
                id="email"
                defaultValue={forgetEmail}
              />

              <input
                name="from_name"
                id="from_name"
                defaultValue={"You got a mail from Outcess Solution"}
              />
              <input
                as="textarea"
                name="message"
                id="message"
                rows={4}
                placeholder="Enter Your Reason"
                style={{ display: "none" }}
                defaultValue={resetUrl}
              />
            </div>
            <button type="submit" className="app-button">
              {loadingForgot ? (
                <ScaleLoader
                  css={override}
                  sizeUnit={"px"}
                  size={40}
                  color={"#fff"}
                  loading={loadingForgot}
                />
              ) : (
                "Send"
              )}
            </button>

            <div class="app-register" onClick={handleForget}>
              Login
            </div>
            <svg
              id="svg-lines"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 284.2 152.7">
              <path
                class="st0"
                d="M37.7,107.3h222.6c12,0,21.8,9.7,21.8,21.7s-9.7,21.8-21.8,21.8c0,0-203.6,0-222.6,0S2.2,138.6,2.2,103.3   c0-52,113.5-101.5,141-101.5c13.5,0,21.8,9.7,21.8,21.8s-9.7,21.7-21.8,21.7s-21.8-9.7-21.8-21.7s9.7-21.8,21.8-21.8"
              />
              <path
                class="st1"
                d="M260.2,76.3L250,87.8l-9-9c-6.2-6.2,2-24.7,17.2-24.7c15.2,0,23.9,17.7,23.9,29.7s-11.7,23.5-23.9,23.5h-10.2"></path>
              <g class="svg-loader" xmlns="http://www.w3.org/2000/svg">
                <path
                  class="svg-loader-segment -cal"
                  d="M164.7,23.5c0-12-9.7-21.8-21.8-21.8"
                />
                <path
                  class="svg-loader-segment -heart"
                  d="M143,45.2c12,0,21.8-9.7,21.8-21.7"
                />
                <path
                  class="svg-loader-segment -steps"
                  d="M121.2,23.5c0,12,9.7,21.7,21.8,21.7"
                />
                <path
                  class="svg-loader-segment -temp"
                  d="M143,1.7c-12,0-21.8,9.7-21.8,21.8"
                />
              </g>
            </svg>
          </div>
        ) : (
          <div class="app-view">
            <header class="app-header">
              <div className="" style={{ width: "200px", height: "100px" }}>
                <img
                  src={Logo}
                  alt="Outcess"
                  style={{ width: "100%", height: "32%", objectFit: "cover" }}
                />
              </div>
              Hello there!
              <br />
              <span class="app-subheading">
                Login in to continue
                <br />
                to Outcess.
              </span>
              {error && (
                <Message variant="danger" className="error-border" dismissible>
                  {error}
                </Message>
              )}
              {errorForgot && (
                <Message variant="danger" className="error-border" dismissible>
                  {errorForgot}
                </Message>
              )}
              {showMessage && (
                <Message variant="success" className="success-border">
                  {" "}
                  <p className="success-color">
                    Email have been sent to your mail!
                  </p>{" "}
                </Message>
              )}
            </header>

            <input
              type="email"
              required
              pattern=".*\.\w{2,}"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type={passwordShown ? "text" : "password"}
              required
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span id="togglePassword" onClick={togglePasswordVisiblity}>
              {passwordShown ? <BsEye /> : <BsEyeSlash />}
            </span>

            <button
              type="submit"
              className="app-button"
              onClick={submitHandler}>
              {loading ? (
                <ScaleLoader
                  css={override}
                  sizeUnit={"px"}
                  size={40}
                  color={"#fff"}
                  loading={loading}
                />
              ) : (
                "Send"
              )}
            </button>

            <div class="app-register" onClick={handleForget}>
              Forget Password?
            </div>
            <svg
              id="svg-lines"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 284.2 152.7">
              <path
                class="st0"
                d="M37.7,107.3h222.6c12,0,21.8,9.7,21.8,21.7s-9.7,21.8-21.8,21.8c0,0-203.6,0-222.6,0S2.2,138.6,2.2,103.3   c0-52,113.5-101.5,141-101.5c13.5,0,21.8,9.7,21.8,21.8s-9.7,21.7-21.8,21.7s-21.8-9.7-21.8-21.7s9.7-21.8,21.8-21.8"
              />
              <path
                class="st1"
                d="M260.2,76.3L250,87.8l-9-9c-6.2-6.2,2-24.7,17.2-24.7c15.2,0,23.9,17.7,23.9,29.7s-11.7,23.5-23.9,23.5h-10.2"></path>
              <g class="svg-loader" xmlns="http://www.w3.org/2000/svg">
                <path
                  class="svg-loader-segment -cal"
                  d="M164.7,23.5c0-12-9.7-21.8-21.8-21.8"
                />
                <path
                  class="svg-loader-segment -heart"
                  d="M143,45.2c12,0,21.8-9.7,21.8-21.7"
                />
                <path
                  class="svg-loader-segment -steps"
                  d="M121.2,23.5c0,12,9.7,21.7,21.8,21.7"
                />
                <path
                  class="svg-loader-segment -temp"
                  d="M143,1.7c-12,0-21.8,9.7-21.8,21.8"
                />
              </g>
            </svg>
          </div>
        )}
      </form>
      <aside class="meta"></aside>
    </div>
  );
}

export default LoginTestScreen;
