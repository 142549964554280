import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import { createPerformanceAction } from "../../actions/performanceActions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../shared/baseUrl";
import Message from "../../components/Message";
import emailjs from "emailjs-com";
import { useNavigate } from "react-router-dom";
import ModalHeader from "../../components/ModalHeader";
import { SlGraph } from "react-icons/sl";
import Loader from "../../components/Loader";
import { PERFORMANCE_ASSESSMENT_CREATE_RESET } from "../../constants/performanceConstants";

const UserKPIAssessment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const form = useRef();

  // --- Handle Modal Display
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // --- Performance Assessment Fields - Handles Adding & Deleting Input Fields --- //
  const [defaultPerformanceField, setDefaultPerformanceField] = useState([
    { performanceFactor: "Punctuality", performanceRating: "" },
    { performanceFactor: "Attitude to Work", performanceRating: "" },
    { performanceFactor: "Interpersonal Skill", performanceRating: "" },
  ]);

  // const supervisorPerformanceField = [
  //   { performanceFactor: "Leadership & Management", performanceRating: "" },
  //   { performanceFactor: "Project Management Skills", performanceRating: "" },
  //   { performanceFactor: "Effective Reporting", performanceRating: "" }
  // ]

  const [newPerformanceField, setNewPerformanceField] = useState([
    { performanceFactor: "", performanceRating: 0 },
  ]);

  // --- State For Other Details --- //
  const [performanceFactor, setPerformanceFactor] = useState([]);
  const [performanceRating, setPerformanceRating] = useState([]);
  const [employeeComment, setEmployeeComment] = useState("");
  const [singlehods, setSinglehods] = useState([]);
  const [hodName, setHodName] = useState([]);
  const [hodEmail, setHodEmail] = useState([]);
  // console.log(performanceFactor,performanceRating,employeeComment)
  // const year = new Date().getFullYear().toString();
  // const year = 2022;

  const [year, setYear] = useState("");
  const [quarter, setQuarter] = useState(0);
  const [reportsTo, setReportsTo] = useState("");

  const [employeePerformanceAverage, setEmployeePerformanceAverage] =
    useState(0);
  const [totalRating, setTotalRating] = useState(0);

  const [hods, setHods] = useState([]);
  const [superadmins, setSuperAdmins] = useState([]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const createPerformance = useSelector((state) => state.createPerformance);
  const {
    success: successCreate,
    error: errorCreate,
    loading,
  } = createPerformance;

  const [performanceMessage, setPerfomanceMessage] = useState([]);
  const [kpiverage, setKpiAverage] = useState([]);
  const [total, setTotal] = useState([]);

  useEffect(() => {
    setPerfomanceMessage([
      `Quater: ${quarter},

      Year: ${year},
      
      Employee Code: ${userInfo?.employeeCode},

      Email: ${userInfo?.email},

      Perfomance - factor: ${performanceFactor},

      Employee - comment: ${employeeComment}`,
    ]);
    setKpiAverage(`KPI-Average: ${employeePerformanceAverage}%`);
    setTotal(`Total: ${totalRating}`);
    setHodName(singlehods[0]?.firstname);
    setHodEmail(singlehods[0]?.email);
  }, [
    userInfo,
    singlehods,
    performanceFactor,
    employeePerformanceAverage,
    employeeComment,
    quarter,
    year,
    totalRating,
  ]);

  const [message, setMessage] = useState(false);
  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else if (successCreate) {
      // --- Close Modal on successCreate --- //
      setShow(false);
      setMessage(true);
      emailjs
        .sendForm(
          "service_v4rxd7i",
          "template_7lx11g7",
          form.current,
          "nsrAhgU-pHN9kQRIs"
        )
        .then(
          (result) => console.log(result.text),
          (error) => console.log(error.text)
        );
    } else {
      setMessage(false);
    }
  }, [navigate, successCreate, userInfo]);

  // const closeMessage = () => {
  //   setMessage(false);
  // };

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else {
      if (
        userInfo.role === "Team Lead" ||
        userInfo.role === "Head Of Department" ||
        userInfo.role === "Head Of Department - HR" ||
        userInfo.role === "Super Admin" ||
        userInfo.role === "CEO"
      ) {
        setDefaultPerformanceField([]);
      }

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      async function fetchHODS() {
        const request = await axios.get(
          baseUrl + "/api/v1/employees/hods",
          config
        );
        setHods(request.data.data);
        return request;
      }
      async function fetchAdmins() {
        const request = await axios.get(
          baseUrl + "/api/v1/employees/superadmins",
          config
        );
        setSuperAdmins(request.data.data);
        return request;
      }

      fetchHODS();
      fetchAdmins();
    }
  }, [navigate, userInfo, dispatch, successCreate]);

  // --- Adds New Performance Field --- //
  const handleAddField = () => {
    setNewPerformanceField([
      ...newPerformanceField,
      { performanceFactor: "", performanceRating: "" },
    ]);
  };

  // --- Remove Performance Field --- //
  const handleRemoveField = (index) => {
    const field = [...newPerformanceField];
    field.splice(index, 1);
    setNewPerformanceField(field);
  };

  // --- onChange Event for all Default Performance --- //
  const handleChangeDefaultPerformance = (e, index) => {
    const list = [...defaultPerformanceField];
    if (e.target.name === "score") {
      list[index].performanceRating = e.target.value;
    }
    setDefaultPerformanceField(list);
  };

  // --- onChange Event for all New Performance --- //
  const handleChangeNewPerformance = (e, index) => {
    const list = [...newPerformanceField];
    if (e.target.name === "factor") {
      list[index].performanceFactor = e.target.value;
    } else {
      list[index].performanceRating = e.target.value;
    }
    setNewPerformanceField(list);
  };

  // --- Takes in all Ratings & Calculate --- //
  const handleSavePerformance = () => {
    // e.preventDefault(e)
    let performanceAssessments = [
      ...defaultPerformanceField,
      ...newPerformanceField,
    ];
    var factor = performanceAssessments.map((item) => item.performanceFactor);
    var ratings = performanceAssessments.map((item) =>
      Number(item.performanceRating)
    );

    // --- Calculating total --- //
    const calcTotalRating = ratings.reduce((x, y) => {
      return x + y;
    }, 0);

    // --- Calculates Total Rating in Percent --- //
    var expectedTotalRating = ratings.length * 4;
    var avgScore = calcTotalRating / expectedTotalRating;
    var avgScorePercent = (avgScore * 100).toFixed(0);

    // --- Set the states to the current value --- //
    setTotalRating(calcTotalRating);
    setEmployeePerformanceAverage(Number(avgScorePercent));
    setPerformanceFactor(factor);
    setPerformanceRating(ratings);
  };
  useEffect(() => {
    handleSavePerformance();
  }, [newPerformanceField]);

  // --- Submits The Performance For HOD's Review
  const submitHandler = (e) => {
    e.preventDefault(e);
    dispatch(
      createPerformanceAction(
        performanceFactor,
        performanceRating,
        quarter,
        year,
        employeePerformanceAverage,
        employeeComment,
        reportsTo
      )
    );
  };

  useEffect(() => {
    if (reportsTo) {
      const results = hods?.filter((res) => res?.id?.includes(reportsTo));
      setSinglehods(results);
    } else {
      setSinglehods("");
    }
  }, [hods, reportsTo]);

  const closeMessage = () => {
    setMessage(false);
    dispatch({
      type: PERFORMANCE_ASSESSMENT_CREATE_RESET,
    });
    navigate("/kpi/teamassessments");
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-body kpi">
        <Modal
          centered
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          className="kpi-modal">
          <ModalHeader
            setLgShow={setShow}
            icon={<SlGraph size={30} />}
            title={"KPI"}
            subtitle={"Submit Assessment"}
          />
          <Modal.Body>
            {loading && <Loader />}
            <p>Are you Sure?</p>
            <p className="mb-4">This action cannot be reversed</p>
            {/* <p className="last-line">
              Ensure you "Save & Check" before you hit "Yes"
            </p> */}
            <input
              type="button"
              value="No"
              className="KPI-close-btn"
              onClick={handleClose}
            />
            <input
              type="button"
              value="Yes"
              className="KPI-submit-btn"
              onClick={submitHandler}
            />
          </Modal.Body>
        </Modal>
        {message ? (
          <div className="info-status">
            <i className="fas fa-check-circle fa-2x" />
            <p>Thank you!</p>
            <p>Your assessment has been submitted successfully</p>
            <p>To follow up on your assessment update, navigate to dashboard</p>
            <NavLink
              to="/home"
              className="KPI-submit-btn"
              onClick={closeMessage}>
              Dashboard
            </NavLink>
          </div>
        ) : (
          <div className="kpi-body">
            <div className="kpi-performance-section">
              <div className="performance-intro">
                <div className="performance-intro-header">
                  <div className="quarter">
                    <p>
                      Year:{" "}
                      <select
                        name="year"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}>
                        <option> </option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                      </select>{" "}
                      Quarter:
                    </p>

                    <select
                      name="quatar"
                      value={quarter}
                      onChange={(e) => setQuarter(e.target.value)}>
                      <option> </option>
                      <option value="1">1st</option>
                      <option value="2">2nd</option>
                      <option value="3">3rd</option>
                      <option value="4">4th</option>
                    </select>
                  </div>
                  <div className="line-manager">
                    <p>Line Manager:</p>
                    <select
                      name="line-manager"
                      value={reportsTo}
                      onChange={(e) => setReportsTo(e.target.value)}>
                      <option> </option>
                      {userInfo?.role === "Head Of Department" ||
                      userInfo?.role === "Head Of Department - HR" ||
                      userInfo?.role === "Super Admin"
                        ? superadmins?.map((superadmin) => (
                            <option
                              key={superadmin?._id}
                              value={superadmin?._id}>
                              {superadmin?.firstname} {superadmin?.lastname}
                            </option>
                          ))
                        : hods?.map((hod) => (
                            <option key={hod?._id} value={hod?._id}>
                              {hod?.firstname} {hod?.lastname}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
                <div>
                  <input
                    type="button"
                    value="+ Add Field"
                    className="add-field-btn"
                    onClick={handleAddField}
                  />
                </div>
              </div>
              <div className="key-performance-box">
                <div className="key-perf-title per-title">
                  <p>Performance Factor</p>
                  <p>Rating</p>
                </div>
                {errorCreate && (
                  <Message
                    variant="danger"
                    className="error-border"
                    dismissible>
                    {errorCreate ===
                    `Cast to ObjectId failed for value "" at path "_id" for model "Employee"`
                      ? "Please select your line manager"
                      : message}
                  </Message>
                )}
                <Form ref={form}>
                  {defaultPerformanceField.map((item, index) => (
                    <div key={index} className="performance-field">
                      <input
                        type="text"
                        className="inputField"
                        value={item.performanceFactor}
                        disabled
                      />
                      <select
                        name="score"
                        required
                        value={item.performanceRating}
                        onChange={(e) =>
                          handleChangeDefaultPerformance(e, index)
                        }>
                        <option></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                    </div>
                  ))}
                  {newPerformanceField?.map((item, index) => (
                    <div key={index} className="performance-field">
                      <input
                        type="text"
                        name="factor"
                        placeholder="Enter Performance Factor"
                        className="inputField"
                        required
                        value={item.performanceFactor}
                        onChange={(e) => handleChangeNewPerformance(e, index)}
                      />
                      <select
                        name="score"
                        required
                        value={item.performanceRating}
                        onChange={(e) => handleChangeNewPerformance(e, index)}>
                        <option></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                      <i
                        title="Delete Field"
                        className="fas fa-trash-alt"
                        onClick={(e) => handleRemoveField(index)}
                      />
                    </div>
                  ))}
                  <div>
                    <textarea
                      placeholder="Add Comment"
                      rows="3"
                      className="inputField"
                      required
                      value={employeeComment}
                      onChange={(e) => setEmployeeComment(e.target.value)}
                    />
                  </div>
                  {/* <input
                    type="button"
                    value="Save & Check"
                    className="KPI-close-btn"
                    onClick={handleSavePerformance}
                  /> */}
                  <input
                    type="button"
                    value="Submit"
                    className="KPI-submit-btn"
                    onClick={handleShow}
                  />
                </Form>
              </div>
            </div>

            <form ref={form}>
              <div style={{ display: "none" }}>
                <input name="to_name" id="to_name" defaultValue={hodName} />
                <input name="email" id="email" defaultValue={hodEmail} />

                <input
                  name="from_name"
                  id="from_name"
                  defaultValue={
                    "You got a message from " +
                    userInfo?.firstname +
                    " " +
                    userInfo?.lastname
                  }
                />
                <input
                  name="from_wishes"
                  id="from_wishes"
                  defaultValue={userInfo?.firstname + " " + userInfo?.lastname}
                />

                <Form.Group>
                  <Form.Control
                    as="textarea"
                    name="message"
                    id="message"
                    rows={4}
                    placeholder="Enter Your Reason"
                    style={{
                      backgroundColor: "var(--input-field-color)",
                    }}
                    defaultValue={performanceMessage}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Control
                    as="textarea"
                    name="message"
                    id="message"
                    rows={4}
                    placeholder="Enter Your Reason"
                    style={{
                      backgroundColor: "var(--input-field-color)",
                    }}
                    defaultValue={kpiverage}
                  />

                  <Form.Control
                    as="textarea"
                    name="message"
                    id="message"
                    rows={4}
                    placeholder="Enter Your Reason"
                    style={{
                      backgroundColor: "var(--input-field-color)",
                    }}
                    defaultValue={total}
                  />
                </Form.Group>
              </div>
            </form>

            <div className="kpi-legend-section">
              <div className="grading-system">
                <div className="grading-system-title">
                  <p>Grading System</p>
                </div>
                <div className="grading-system-body">
                  <p>4</p>
                  <p>Exceptional</p>
                  <p>3</p>
                  <p>Meets Expectation</p>
                  <p>2</p>
                  <p>Marginal</p>
                  <p>1</p>
                  <p>Unsatisfactory</p>
                </div>
              </div>
              <div className="kpi-summary">
                <div className="kpi-summary-title">
                  <p>KPI Summary</p>
                </div>
                <div className="kpi-summary-body">
                  <p>Total</p>
                  <p id="total-rating">{totalRating}</p>
                  <p>Average Score</p>
                  <p id="avg-rating">{employeePerformanceAverage}%</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserKPIAssessment;
