import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import ModalHeader from "../../components/ModalHeader";
import { FiUserPlus } from "react-icons/fi";
import { listUsers, register } from "../../actions/userActions";
import { USER_REGISTER_RESET } from "../../constants/userConstants";
import {
  NoRecordFound,
  SearchComponent,
  TableFetch,
} from "../../components/TableOptions";
import Pagination from "../../components/Pagination";
import { useNavigate } from "react-router-dom";

const StaffListScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userList);
  const { data: infodata, loading, error } = userList;

  const [data, setData] = useState([]);
  const [filtered, setFilterd] = useState([]);
  const [result, setResult] = useState("");
  const [displayData, setDisplayData] = useState([]);

  const [entriesPerPage, setEntriesPerPage] = useState(() => {
    return localStorage.getItem("rowsPerPage") || "8";
  });

  const [firstname, setFirstname] = useState("");
  const [middlename, setMiddlename] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState("");
  const [department, setDepartment] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("");
  const [leaveDays, setLeaveDays] = useState(0);
  const [message, setMessage] = useState(null);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userRegister = useSelector((state) => state.userRegister);
  const {
    loading: loadingRegister,
    error: errorRegister,
    success: successRegister,
  } = userRegister;

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role === "Human Resource Executive" ||
        userInfo.role === "CEO" ||
        userInfo.role === "Head Of Department - HR" ||
        userInfo.role === "Super Admin" ||
        userInfo.role === "Assistant Manager - Human Resources" ||
        userInfo.role === "Human Resource Manager")
    ) {
      if (successRegister) {
        handleClose();
      }
    } else {
      navigate("/");
    }
  }, [dispatch, navigate, userInfo, successRegister]);

  useEffect(() => {
    if (successRegister) {
      dispatch({
        type: USER_REGISTER_RESET,
      });
    }
    setTimeout(() => {}, 5000);
  }, [dispatch, successRegister]);

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role === "Human Resource Executive" ||
        userInfo.role === "Head Of Department - HR" ||
        userInfo.role === "CEO" ||
        userInfo.role === "Super Admin" ||
        userInfo.role === "Assistant Manager - Human Resources" ||
        userInfo.role === "Human Resource Manager")
    ) {
      dispatch(listUsers());
    } else {
      navigate("/");
    }
  }, [userInfo, dispatch, navigate]);

  useEffect(() => {
    setData(infodata);
    setFilterd(infodata);
    localStorage.setItem("rowsPerPage", entriesPerPage);
  }, [infodata, entriesPerPage]);

  useEffect(() => {
    const results = filtered?.filter(
      (data) =>
        data.firstname.toLowerCase().includes(result) ||
        data.middlename.toLowerCase().includes(result) ||
        data.email.toLowerCase().includes(result) ||
        data.lastname.toLowerCase().includes(result) ||
        data.role.toLowerCase().includes(result) ||
        data.email.toLowerCase().includes(result) ||
        data.department.toLowerCase().includes(result) ||
        data.employeeCode.toLowerCase().includes(result)
    );
    setData(results);
  }, [result, filtered]);

  const onChange = (e) => {
    setResult(e.target.value);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      dispatch(
        register(
          firstname,
          middlename,
          lastname,
          email,
          dateOfJoining,
          department,
          employeeCode,
          role,
          leaveDays,
          password
        )
      );
      dispatch(listUsers());
    }
  };

  const departments = [
    "",
    "Admin",
    "Sales",
    "MIS",
    "Projects",
    "Operations",
    "QA",
    "Customer Service",
    "Human Resources",
    "IT",
    "Training & Development",
    "Accounts",
    "Enugu - MCN",
    "Branch",
    "Multichoice",
    "Ntel",
    "Fairmoney",
    "KYC",
    "Retail",
    "Sim swap",
    "Enterprise",
    "Access bank",
    "OUTCESS",
  ];

  const roles = [
    "",
    "Admin Executive",
    "Sales Executive",
    "MIS Executive",
    "Human Resource Manager",
    "Human Resource Lead",
    "Projects Executive",
    "Team Lead",
    "Quality Assessor",
    "Customer Service Officer",
    "Assistant Manager - Human Resources",
    "IT Support Specialist",
    "Frontdesk/Recruitment officer",
    "Trainer",
    "Human Resource Executive",
    "Intern",
    "Software Developer (Intern)",
    "Software Developer",
    "MIS Intern",
    "MIS",
    "MIS Lead",
    "Account Intern",
    "IT Support Intern",
    "HR Intern",
    "Project Intern",
    "Admin Intern",
    "Admin",
    "Sales Intern",
    "Trainer Intern",
    "Quality Intern",
    "Accounts Officer",
    "Customer Success Intern",
    "Recruitment Administrator",
    "Accountant",
    "Head Of Department",
    "Head Of Department - HR",
    "Assistant Manager",
    "QA - Lead",
    "Assistant Manager",
    "CEO",
    "Agent",
    "Executive Driver to the CEO",
    "Showroom",
    "AES",
  ];

  return (
    <>
      <div id="reports">
        <h5 className="page-title">Staff List</h5>
        <div className="nav-tabs">
          <li className="active" onClick={handleShow}>
            Create Employee
          </li>
        </div>
        <div className="half-background">
          <SearchComponent
            sortData={data}
            entriesPerPage={entriesPerPage}
            setEntriesPerPage={setEntriesPerPage}
            placeholder={"Search All Employee"}
            value={result}
            onChange={onChange}
          />
          {error && <Message variant="danger">{error}</Message>}
          {message && (
            <Message variant="danger" className="error-border">
              {message}
            </Message>
          )}
          {errorRegister && (
            <Message variant="danger" className="error-border" dismissible>
              {errorRegister}
            </Message>
          )}
          <section className="md-ui component-data-table">
            {loading && <Loader />}
            <div className="main-table-wrapper">
              <table className="main-table-content">
                <thead className="data-table-header">
                  <tr className="data-table-row">
                    <th>EMPLOYEE ID</th>
                    <th>FIRST NAME</th>
                    <th>MIDDLE NAME</th>
                    <th>LAST NAME</th>
                    <th>EMAIL</th>
                    <th>ROLE</th>
                    <th>DEPARTMENT</th>
                    <th>ACTIVE USER</th>
                    {userInfo &&
                      (userInfo.role === "Human Resource Executive" ||
                        userInfo.role === "Super Admin" ||
                        userInfo.role ===
                          "Assistant Manager - Human Resources" ||
                        userInfo.role === "Human Resource Manager") && (
                        <th></th>
                      )}
                  </tr>
                </thead>
                <tbody className="data-table-content">
                  {loading ? (
                    <TableFetch colSpan={10} />
                  ) : displayData?.length === 0 || displayData == null ? (
                    <NoRecordFound colSpan={10} />
                  ) : (
                    displayData?.map((user, i) => (
                      <tr className="data-table-row" key={i}>
                        <td>{user.employeeCode}</td>
                        <td>{user.firstname}</td>
                        <td>{user.middlename}</td>
                        <td>{user.lastname}</td>
                        <td>
                          <a href={`mailto:${user.email}`}>{user.email}</a>
                        </td>
                        <td>{user.role}</td>
                        <td>{user.department}</td>
                        <td>
                          {user.isActive ? (
                            <i
                              className="fas fa-check-circle"
                              style={{ color: "green" }}
                            />
                          ) : (
                            <i
                              className="fas fa-times-circle"
                              style={{ color: "#e2522e" }}
                            />
                          )}
                        </td>
                        {userInfo &&
                          (userInfo.role === "Human Resource Executive" ||
                            userInfo.role === "Head Of Department - HR" ||
                            userInfo.role === "Super Admin" ||
                            userInfo.role ===
                              "Assistant Manager - Human Resources" ||
                            userInfo.role === "Human Resource Manager") && (
                            <td>
                              <Link to={`/admin/user/${user._id}/edit`}>
                                <Button
                                  variant="light"
                                  className="btn-sm staff-list-btn-edit">
                                  <i className="fas fa-edit"></i>
                                </Button>
                              </Link>
                              <Link to={`/admin/user/${user._id}/role`}>
                                <Button
                                  variant="primary"
                                  className="btn-sm staff-list-btn-key">
                                  <i className="fas fa-key"></i>
                                </Button>
                              </Link>
                            </td>
                          )}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <>
              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="myleave-modal">
                <ModalHeader
                  setLgShow={setShow}
                  icon={<FiUserPlus size={30} />}
                  title={"Employee"}
                  subtitle={" Register An Employee"}
                />
                <Modal.Body>
                  <Form onSubmit={submitHandler}>
                    <Form.Row>
                      <Form.Group className="col-md-4" controlId="firstname">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter First name"
                          value={firstname}
                          onChange={(e) =>
                            setFirstname(e.target.value)
                          }></Form.Control>
                      </Form.Group>
                      <Form.Group className="col-md-4" controlId="middlename">
                        <Form.Label>Middle Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Middle name"
                          value={middlename}
                          onChange={(e) =>
                            setMiddlename(e.target.value)
                          }></Form.Control>
                      </Form.Group>
                      <Form.Group className="col-md-4" controlId="lastname">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Last name"
                          value={lastname}
                          onChange={(e) =>
                            setLastname(e.target.value)
                          }></Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group className="col-md-4" controlId="email">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter Email"
                          value={email}
                          onChange={(e) =>
                            setEmail(e.target.value)
                          }></Form.Control>
                      </Form.Group>
                      <Form.Group
                        className="col-md-4"
                        controlId="dateOfJoining">
                        <Form.Label>Date Of Joining</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Enter Date Of Joining"
                          value={dateOfJoining}
                          onChange={(e) => setDateOfJoining(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        className="col-md-4"
                        controlId="formGridDepartment">
                        <Form.Label>Department</Form.Label>

                        <Form.Control
                          as="select"
                          size="sm"
                          custom
                          value={department}
                          onChange={(e) => setDepartment(e.target.value)}>
                          {departments.map((option) => (
                            <option key={option} value={option}>
                              {option || "Select..."}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group className="col-md-4" controlId="employeeCode">
                        <Form.Label>Employee Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Employee Code"
                          value={employeeCode}
                          onChange={(e) =>
                            setEmployeeCode(e.target.value)
                          }></Form.Control>
                      </Form.Group>
                      <Form.Group className="col-md-4" controlId="formGridRole">
                        <Form.Label>Role</Form.Label>
                        <Form.Control
                          as="select"
                          size="sm"
                          custom
                          value={role}
                          onChange={(e) => setRole(e.target.value)}>
                          {roles?.map((option) => (
                            <option key={option} value={option}>
                              {option || "Select..."}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group className="col-md-4" controlId="leaveDays">
                        <Form.Label>Leave Balance</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter Leave Days Balance"
                          value={leaveDays}
                          onChange={(e) => setLeaveDays(e.target.value)}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group className="col-md-6" controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter Password"
                          value={password}
                          onChange={(e) =>
                            setPassword(e.target.value)
                          }></Form.Control>
                      </Form.Group>
                      <Form.Group
                        className="col-md-6"
                        controlId="confirrmPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={(e) =>
                            setConfirmPassword(e.target.value)
                          }></Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Button
                      className="applyleave-btn mt-3"
                      variant="primary"
                      disabled={loadingRegister && true}
                      type="submit"
                      value="Register">
                      {loadingRegister ? "Registering..." : "Register"}
                    </Button>
                  </Form>
                </Modal.Body>
              </Modal>
            </>
          </section>
          <footer className="main-table-footer">
            <Pagination
              setDisplayData={setDisplayData}
              data={data}
              entriesPerPage={entriesPerPage}
              Total={"Employees"}
            />
          </footer>
        </div>
      </div>
    </>
  );
};

export default StaffListScreen;
