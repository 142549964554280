export const NOK_DETAILS_REQUEST = 'NOK_DETAILS_REQUEST'
export const NOK_DETAILS_SUCCESS = 'NOK_DETAILS_SUCCESS'
export const NOK_DETAILS_FAIL = 'NOK_DETAILS_FAIL'
export const NOK_DETAILS_RESET = 'NOK_DETAILS_RESET'


export const NOK_UPDATE_REQUEST = 'NOK_UPDATE_REQUEST'
export const NOK_UPDATE_SUCCESS = 'NOK_UPDATE_SUCCESS'
export const NOK_UPDATE_FAIL = 'NOK_UPDATE_FAIL'
export const NOK_UPDATE_RESET = 'NOK_UPDATE_RESET'

export const NOK_CREATE_REQUEST = 'NOK_CREATE_REQUEST'
export const NOK_CREATE_SUCCESS = 'NOK_CREATE_SUCCESS'
export const NOK_CREATE_FAIL = 'NOK_CREATE_FAIL'
export const NOK_CREATE_RESET = 'NOK_CREATE_RESET'

export const NOK_CREATE_EMPLOYEE_REQUEST = 'NOK_CREATE_EMPLOYEE_REQUEST'
export const NOK_CREATE_EMPLOYEE_SUCCESS = 'NOK_CREATE_EMPLOYEE_SUCCESS'
export const NOK_CREATE_EMPLOYEE_FAIL = 'NOK_CREATE_EMPLOYEE_FAIL'
export const NOK_CREATE_EMPLOYEE_RESET = 'NOK_CREATE_EMPLOYEE_RESET'

export const NOK_DETAILS_EMPLOYEE_REQUEST = 'NOK_DETAILS_EMPLOYEE_REQUEST'
export const NOK_DETAILS_EMPLOYEE_SUCCESS = 'NOK_DETAILS_EMPLOYEE_SUCCESS'
export const NOK_DETAILS_EMPLOYEE_FAIL = 'NOK_DETAILS_EMPLOYEE_FAIL'
export const NOK_DETAILS_EMPLOYEE_RESET = 'NOK_DETAILS_EMPLOYEE_RESET'