import {
  GET_EMPLOYEE_ASSESSMENTS_REQUEST,
  GET_EMPLOYEE_ASSESSMENTS_SUCCESS,
  GET_EMPLOYEE_ASSESSMENTS_RESET,
  GET_EMPLOYEE_ASSESSMENTS_FAIL,
  ALL_PERFORMANCE_ASSESSMENTS_RESET,
  ALL_PERFORMANCE_ASSESSMENTS_REQUEST,
  ALL_PERFORMANCE_ASSESSMENTS_SUCCESS,
  ALL_PERFORMANCE_ASSESSMENTS_FAIL,
  UPDATE_TEAM_ASSESSMENT_ID_RESET,
  UPDATE_TEAM_ASSESSMENT_ID_REQUEST,
  UPDATE_TEAM_ASSESSMENT_ID_SUCCESS,
  UPDATE_TEAM_ASSESSMENT_ID_FAIL,
  GET_PERFORMANCE_ASSESSMENT_ID_RESET,
  GET_PERFORMANCE_ASSESSMENT_ID_REQUEST,
  GET_PERFORMANCE_ASSESSMENT_ID_SUCCESS,
  GET_PERFORMANCE_ASSESSMENT_ID_FAIL,
  TEAM_PERFORMANCE_ASSESSMENTS_RESET,
  TEAM_PERFORMANCE_ASSESSMENTS_REQUEST,
  TEAM_PERFORMANCE_ASSESSMENTS_SUCCESS,
  TEAM_PERFORMANCE_ASSESSMENTS_FAIL,
  PERFORMANCE_ASSESSMENT_CREATE_REQUEST,
  PERFORMANCE_ASSESSMENT_CREATE_SUCCESS,
  PERFORMANCE_ASSESSMENT_CREATE_FAIL,
  PERFORMANCE_ASSESSMENT_CREATE_RESET,
  EMPLOYEE_UPDATE_KPI_REQUEST,
  EMPLOYEE_UPDATE_KPI_SUCCESS,
  EMPLOYEE_UPDATE_KPI_FAIL,
  EMPLOYEE_UPDATE_KPI_RESET,
} from "../constants/performanceConstants";

export const createPerformanceReducer = (state = {}, action) => {
  switch (action.type) {
    case PERFORMANCE_ASSESSMENT_CREATE_REQUEST:
      return { loading: true };
    case PERFORMANCE_ASSESSMENT_CREATE_SUCCESS:
      return { loading: false, success: true };
    case PERFORMANCE_ASSESSMENT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PERFORMANCE_ASSESSMENT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const getTeamPerformancesReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case TEAM_PERFORMANCE_ASSESSMENTS_REQUEST:
      return { ...state, loading: true };
    case TEAM_PERFORMANCE_ASSESSMENTS_SUCCESS:
      return {
        loading: false,
        data: action.payload.data,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case TEAM_PERFORMANCE_ASSESSMENTS_FAIL:
      return { loading: false, error: action.payload };
    case TEAM_PERFORMANCE_ASSESSMENTS_RESET:
      return { data: [] };
    default:
      return state;
  }
};

export const getPerformanceByIdReducer = (state = { data: {} }, action) => {
  switch (action.type) {
    case GET_PERFORMANCE_ASSESSMENT_ID_REQUEST:
      return { ...state, loading: true };
    case GET_PERFORMANCE_ASSESSMENT_ID_SUCCESS:
      return { loading: false, data: action.payload.data };
    case GET_PERFORMANCE_ASSESSMENT_ID_FAIL:
      return { loading: false, error: action.payload };
    case GET_PERFORMANCE_ASSESSMENT_ID_RESET:
      return { data: {} };
    default:
      return state;
  }
};

export const updateTeamPerformanceByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TEAM_ASSESSMENT_ID_REQUEST:
      return { loading: true };
    case UPDATE_TEAM_ASSESSMENT_ID_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case UPDATE_TEAM_ASSESSMENT_ID_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_TEAM_ASSESSMENT_ID_RESET:
      return { success: false };
    default:
      return state;
  }
};

export const getAllPerformanceAssessmentsReducer = (
  state = { data: [] },
  action
) => {
  switch (action.type) {
    case ALL_PERFORMANCE_ASSESSMENTS_REQUEST:
      return { ...state, loading: true };
    case ALL_PERFORMANCE_ASSESSMENTS_SUCCESS:
      return {
        loading: false,
        data: action.payload.data,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case ALL_PERFORMANCE_ASSESSMENTS_FAIL:
      return { loading: false, error: action.payload };
    case ALL_PERFORMANCE_ASSESSMENTS_RESET:
      return { data: [] };
    default:
      return state;
  }
};

export const getEmployeeAssessmentsReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_EMPLOYEE_ASSESSMENTS_REQUEST:
      return { ...state, loading: true };
    case GET_EMPLOYEE_ASSESSMENTS_SUCCESS:
      return {
        loading: false,
        data: action.payload.data,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case GET_EMPLOYEE_ASSESSMENTS_FAIL:
      return { loading: false, error: action.payload };
    case GET_EMPLOYEE_ASSESSMENTS_RESET:
      return { data: [] };
    default:
      return state;
  }
};

export const employeeUpdateKPIReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_UPDATE_KPI_REQUEST:
      return { loading: true };
    case EMPLOYEE_UPDATE_KPI_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case EMPLOYEE_UPDATE_KPI_FAIL:
      return { loading: false, error: action.payload };
    case EMPLOYEE_UPDATE_KPI_RESET:
      return {};
    default:
      return state;
  }
};
