import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getAllTeamLeaveApplications } from "../../actions/agentLeaveActions";
import Message from "../../components/Message";
import { useNavigate } from "react-router-dom";
import { NoRecordFound, TableFetch } from "../../components/TableOptions";
import Pagination from "../../components/Pagination";
import { SearchComponent } from "../../components/TableOptions";

const TeamLeaveApplicationsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [displayData, setDisplayData] = useState([]);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [result, setResult] = useState("");

  const teamLeaveApplications = useSelector(
    (state) => state.teamLeaveApplications
  );
  const { data, error, loading } = teamLeaveApplications;

  const [entriesPerPage, setEntriesPerPage] = useState(() => {
    return localStorage.getItem("rowsPerPage") || "8";
  });

  useEffect(() => {
    if (userInfo && userInfo.role === "Team Lead") {
      dispatch(getAllTeamLeaveApplications());
    } else {
      navigate("/");
    }
  }, [dispatch, navigate, data, userInfo]);

  const onChange = (e) => {
    setResult(e.target.value);
  };

  return (
    <div id="reports">
      <h5 className="page-title mb-4">My Team Leave Applications</h5>
      <div className="half-background">
        <SearchComponent
          sortData={data}
          entriesPerPage={entriesPerPage}
          setEntriesPerPage={setEntriesPerPage}
          placeholder={"Team Leave"}
          value={result}
          onChange={onChange}
          userInfo={userInfo}
        />

        <section className="md-ui component-data-table">
          {error && (
            <Message variant="danger" className="error-border" dismissible>
              {error}
            </Message>
          )}
          <div className="main-table-wrapper">
            <table className="main-table-content">
              <thead className="data-table-header">
                <tr className="data-table-row">
                  <th>Date Applied</th>
                  <th>Email Address</th>
                  <th>Leave Type</th>
                  <th>Start Date</th>
                  <th>Leave Status</th>
                  <th>Update</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <TableFetch colSpan={8} />
                ) : displayData?.length === 0 || displayData == null ? (
                  <NoRecordFound colSpan={8} />
                ) : (
                  displayData?.map((user) => (
                    <tr key={user._id}>
                      <td>{moment(user.createdAt).format("DD-MM-YYYY")}</td>
                      <td>{user.employee.email}</td>
                      <td>{user.leaveType}</td>
                      <td>{moment(user.fromDate).format("DD-MM-YYYY")}</td>
                      <td>{user.leaveStatus}</td>
                      <td>
                        <NavLink
                          to={`/mydepartmentapplications/${user._id}/update`}
                          exact
                          className="update-btn">
                          Update
                        </NavLink>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </section>
      </div>
      <footer className="main-table-footer">
        <Pagination
          setDisplayData={setDisplayData}
          data={data}
          entriesPerPage={entriesPerPage}
          Total={"Team Leave Applications"}
        />
      </footer>
    </div>
  );
};

export default TeamLeaveApplicationsScreen;
