import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Form, Col } from "react-bootstrap";
import moment from "moment";
import {
  createLeaveApplication,
  getMyLeaveApplication,
} from "../../actions/leaveApplication";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../shared/baseUrl";
import Message from "../../components/Message";
import { LEAVE_APPLICATION_CREATE_RESET } from "../../constants/leaveApplicationConstants";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import { useNavigate } from "react-router-dom";
import {
  NoRecordFound,
  SearchComponent,
  TableFetch,
} from "../../components/TableOptions";
import Pagination from "../../components/Pagination";
import ModalHeader from "../../components/ModalHeader";
import { GoBriefcase } from "react-icons/go";

const MyLeaveApplicationScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const form = useRef();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const [leaveType, setLeaveType] = useState("");
  const [fromDate, setLeaveStartDate] = useState("");
  const [toDate, setLeaveEndDate] = useState("");
  const [reasonForLeave, setLeaveDescription] = useState("");
  const [reportsTo, setReportsTo] = useState("");
  const [noOfDays, setNoOfDays] = useState(0);
  const [leaveStatus] = useState("");
  const [hods, setHods] = useState([]);
  const [superadmins, setSuperAdmins] = useState([]);
  const [name, setName] = useState([]);

  const [data, setData] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [displayData, setDisplayData] = useState([]);

  const [entriesPerPage, setEntriesPerPage] = useState(() => {
    return localStorage.getItem("rowsPerPage") || "8";
  });

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const leaveAppDetails = useSelector((state) => state.leaveAppDetails);
  const { data: datainfo, error, loading } = leaveAppDetails;

  const updateLeaveApp = useSelector((state) => state.updateLeaveApp);
  const { success: successUpdate } = updateLeaveApp;

  const createLeaveApp = useSelector((state) => state.createLeaveApp);
  const {
    success: successCreate,
    error: errorCreate,
    loading: loadingCreate,
  } = createLeaveApp;

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else {
      if (successCreate || successUpdate) {
        emailjs
          .sendForm(
            "service_v4rxd7i",
            "template_7lx11g7",
            form.current,
            "nsrAhgU-pHN9kQRIs"
          )
          .then(
            (result) => console.log(result.text),
            (error) => console.log(error.text)
          );

        handleClose();
        setLeaveType("");
        setLeaveStartDate("");
        setLeaveEndDate("");
        setLeaveDescription("");
        setReportsTo("");
        setNoOfDays("");
      } else {
        dispatch(getMyLeaveApplication());

        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        };

        async function fetchHODS() {
          const request = await axios.get(
            baseUrl + "/api/v1/employees/hods",
            config
          );
          setHods(request.data.data);
          return request;
        }
        async function fetchAdmins() {
          const request = await axios.get(
            baseUrl + "/api/v1/employees/superadmins",
            config
          );
          setSuperAdmins(request?.data?.data);
          return request;
        }
        fetchHODS();
        fetchAdmins();
      }
    }
  }, [dispatch, navigate, successCreate, successUpdate, userInfo]);

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role === "Human Resource Executive" ||
        userInfo.role === "Head Of Department - HR" ||
        userInfo.role === "CEO" ||
        userInfo.role === "Super Admin" ||
        userInfo.role === "Assistant Manager - Human Resources" ||
        userInfo.role === "Human Resource Manager")
    ) {
      setName(superadmins);
    } else {
      setName(hods);
    }
  }, [hods, superadmins, userInfo]);

  useEffect(() => {
    if (successCreate) {
      setShowMessage(true);
      dispatch({
        type: LEAVE_APPLICATION_CREATE_RESET,
      });
    }
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  }, [dispatch, successCreate]);

  const createsubmitHandler = (e) => {
    e.preventDefault(e);

    dispatch(
      createLeaveApplication(
        leaveType,
        fromDate,
        toDate,
        noOfDays,
        reportsTo,
        reasonForLeave,
        leaveStatus
      )
    );
  };

  // --- Leave Application Eligibilty
  const dateJoined = new Date(userInfo?.dateOfJoining);
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate - dateJoined);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const daysLeft = 365 - diffDays;

  const [leaveMassage, setLeaveMassage] = useState([]);

  const [hodName, setHodName] = useState([]);
  const [hodEmail, setHodEmail] = useState([]);
  const [filtered, setFilterd] = useState([]);
  const [result, setResult] = useState("");

  // useEffect(() => {
  //   if (reportsTo) {
  //     async function searchHOD(reportsTo) {
  //       try {
  //         const { data } = await axios.get(
  //           baseUrl + `/api/v1/employees/single/${reportsTo}`,
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `Bearer ${userInfo.token}`,
  //             },
  //           }
  //         );
  //         setNewData(data);
  //       } catch (error) {
  //         console.log(
  //           error.response && error.response.data.message
  //             ? error.response.data.message
  //             : error.message
  //         );
  //       }
  //     }
  //     searchHOD(reportsTo);
  //   }
  // }, [hods, reportsTo, userInfo]);

  const [singlehods, setSinglehods] = useState([]);

  useEffect(() => {
    if (reportsTo) {
      const results = name?.filter((res) => res?.id?.includes(reportsTo));
      setSinglehods(results);
    } else {
      setSinglehods("");
    }
  }, [name, reportsTo]);

  // Automatic Mail Notification
  useEffect(() => {
    setLeaveMassage([
      `Employee Code,
    ${userInfo?.employeeCode},
     Email,
    ${userInfo?.email},
    leave Type,
    ${leaveType},
      No Of Days,
      ${noOfDays},
      From Date,
      ${fromDate},
      End Date,
      ${toDate},
      Reason For Leave,
      ${reasonForLeave},`,
    ]);
    setHodName(singlehods[0]?.firstname);
    setHodEmail(singlehods[0]?.email);
  }, [
    userInfo,
    leaveType,
    noOfDays,
    fromDate,
    toDate,
    reasonForLeave,
    singlehods,
  ]);

  useEffect(() => {
    setFilterd(datainfo);
    localStorage.setItem("rowsPerPage", entriesPerPage);
  }, [datainfo, entriesPerPage]);

  useEffect(() => {
    const results = filtered?.filter((item) =>
      item.leaveType?.toLowerCase().includes(result.toLowerCase())
    );
    setData(results);
  }, [result, filtered]);

  const onChange = (e) => {
    setResult(e.target.value);
  };

  return (
    <div id="reports">
      <h5 className="page-title mb-4">
        {" "}
        {userInfo?.firstname}'s Leave Details
      </h5>
      <div className="nav-tabs">
        <li className="active" onClick={handleShow}>
          Apply Leave
        </li>
      </div>
      <div className="half-background">
        <SearchComponent
          sortData={data}
          entriesPerPage={entriesPerPage}
          setEntriesPerPage={setEntriesPerPage}
          placeholder={"My Leave"}
          value={result}
          onChange={onChange}
        />

        <section className="md-ui component-data-table">
          <>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              className="myleave-modal"
              centered>
              <ModalHeader
                setLgShow={setShow}
                icon={<GoBriefcase size={30} />}
                title={"Leave"}
                subtitle={"Apply for Leave"}
              />
              <Modal.Body>
                {errorCreate && (
                  <Message
                    variant="danger"
                    className="error-border"
                    dismissible>
                    {errorCreate}
                  </Message>
                )}
                {diffDays > 365 ? (
                  <Form ref={form} onSubmit={createsubmitHandler}>
                    <Form.Group>
                      <Form.Label>Leave Type</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        size="sm"
                        value={leaveType}
                        onChange={(e) => setLeaveType(e.target.value)}>
                        <option value="">Select Leave Type...</option>
                        <option value="Sick-Leave">Sick Leave</option>
                        <option value="Paid-Leave">Paid Leave</option>
                        <option value="Casual-Leave">Casual Leave</option>
                        <option value="Compassionate-Leave">
                          Compassionate-Leave
                        </option>
                        <option value="Maternity-Leave">Maternity Leave</option>
                        <option value="Paternity-Leave">Paternity Leave</option>
                        <option value="Other">Other</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>Reports To</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          size="sm"
                          value={reportsTo}
                          onChange={(e) => setReportsTo(e.target.value)}>
                          <option>Reports To...</option>
                          {userInfo?.role === "Head Of Department" ||
                          userInfo?.role === "Head Of Department - HR" ||
                          userInfo?.role === "Super Admin"
                            ? superadmins?.map((superadmin) => (
                                <option
                                  key={superadmin?._id}
                                  value={superadmin?._id}>
                                  {superadmin?.firstname} {superadmin?.lastname}
                                </option>
                              ))
                            : hods?.map((hod) => (
                                <option key={hod?._id} value={hod?._id}>
                                  {hod?.firstname} {hod?.lastname}
                                </option>
                              ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>No Of Days</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="0"
                          value={noOfDays}
                          onChange={(e) =>
                            setNoOfDays(e.target.value)
                          }></Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Start Date"
                          value={fromDate}
                          onChange={(e) =>
                            setLeaveStartDate(e.target.value)
                          }></Form.Control>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="End Date"
                          value={toDate}
                          onChange={(e) =>
                            setLeaveEndDate(e.target.value)
                          }></Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Group controlId="description">
                      <Form.Label>
                        Detailed Reason For Leave Application
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter Your Reason"
                        style={{
                          backgroundColor: "var(--input-field-color)",
                        }}
                        value={reasonForLeave}
                        onChange={(e) => setLeaveDescription(e.target.value)}
                      />
                    </Form.Group>

                    <div style={{ display: "none" }}>
                      <input
                        name="to_name"
                        id="to_name"
                        defaultValue={hodName}
                      />
                      <input name="email" id="email" defaultValue={hodEmail} />

                      <input
                        name="from_name"
                        id="from_name"
                        defaultValue={
                          "Kindly see below my Leave Application " +
                          userInfo?.firstname +
                          " " +
                          userInfo?.lastname
                        }
                      />

                      <Form.Group>
                        <Form.Control
                          as="textarea"
                          name="message"
                          id="message"
                          rows={4}
                          placeholder="Enter Your Reason"
                          style={{
                            backgroundColor: "var(--input-field-color)",
                          }}
                          defaultValue={leaveMassage}
                        />
                      </Form.Group>
                    </div>
                    <Button
                      className="applyleave-btn mt-3"
                      variant="primary"
                      disabled={loadingCreate && true}
                      type="submit"
                      value="Apply">
                      {loadingCreate ? "Applying..." : "Apply"}
                    </Button>
                  </Form>
                ) : (
                  <div className="not-eligible">
                    <div className="not-eligible-container">
                      <i className="fas fa-exclamation-triangle fa-2x pb-3"></i>
                      <h2>Not eligible</h2>
                      <p>
                        You have <code>{daysLeft}</code> days left
                      </p>
                    </div>
                    <hr />
                    <Button
                      className="mb-2"
                      variant="secondary"
                      onClick={handleClose}>
                      Close
                    </Button>
                  </div>
                )}
              </Modal.Body>
            </Modal>
          </>
          {error && (
            <Message variant="danger" className="error-border">
              {error}
            </Message>
          )}
          {showMessage && (
            <Message variant="success" className="success-border">
              {" "}
              <p className="success-color">
                Leave have been applied successfully!
              </p>{" "}
            </Message>
          )}
          <div className="main-table-wrapper">
            <table className="main-table-content">
              <thead className="data-table-header">
                <tr className="data-table-row">
                  <th>Leave Type</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>HOD Approval</th>
                  <th>Final Approval</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <TableFetch colSpan={8} />
                ) : displayData?.length === 0 || displayData == null ? (
                  <NoRecordFound colSpan={8} />
                ) : (
                  displayData?.map((user) => (
                    <tr key={user?._id}>
                      <td>{user?.leaveType}</td>
                      <td>{moment(user?.fromDate)?.format("DD-MM-YYYY")}</td>
                      <td>{moment(user?.toDate)?.format("DD-MM-YYYY")}</td>
                      <td>
                        {user?.hodApproval ? (
                          <i
                            className="fas fa-check-circle"
                            style={{ color: "green" }}></i>
                        ) : (
                          <i
                            className="fas fa-times-circle"
                            style={{ color: "red" }}></i>
                        )}
                      </td>
                      <td>
                        {user?.finalApproval ? (
                          <i
                            className="fas fa-check-circle"
                            style={{ color: "green" }}></i>
                        ) : (
                          <i
                            className="fas fa-times-circle"
                            style={{ color: "red" }}></i>
                        )}
                      </td>
                      <td className="myleave-status-td">
                        {user?.hodApproval && user?.finalApproval ? (
                          <p className="status-a">Approved</p>
                        ) : user?.hodApproval ? (
                          <p className="status-p">Pending...</p>
                        ) : user?.rejected ? (
                          <p className="status-r">Rejected</p>
                        ) : (
                          <Link
                            to={`/employeeupdateleave/${user?._id}/update`}
                            exact
                            className="update-btn rounded-5"
                            style={{
                              background: "#E2522E",
                              boxShadow: "none",
                            }}>
                            Update
                          </Link>
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </section>
        <footer className="main-table-footer">
          <Pagination
            setDisplayData={setDisplayData}
            data={data}
            entriesPerPage={entriesPerPage}
            Total={"Leave Details"}
          />
        </footer>
      </div>
    </div>
  );
};

export default MyLeaveApplicationScreen;
