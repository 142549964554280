import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import ProfileScreen from "./ProfileScreen";
import NextOfKinScreen from "./NextOfKinScreen";
import EducationScreen from "./EducationScreen";

const UpdateProfile = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <div>
      <div className="dashboard-container ">
        <div className="dashboard-body ">
          <div className="page-header">
            <h4>{userInfo.firstname}, Profile Update </h4>
          </div>
          <Tabs
            justify
            defaultActiveKey="profile"
            id="uncontrolled-tab-example"
            className="update-profile-tab">
            <Tab
              eventKey="profile"
              tabClassName="active-tab"
              title={
                <>
                  <span className="tab-title">1</span>
                  <span>Profile Information</span>
                </>
              }>
              <ProfileScreen />
            </Tab>
            <Tab
              eventKey="education"
              tabClassName="active-tab"
              title={
                <>
                  <span className="tab-title">2</span>
                  <span>Education</span>
                </>
              }>
              <EducationScreen />
            </Tab>
            <Tab
              eventKey="next of Kin"
              tabClassName="active-tab"
              title={
                <>
                  <span className="tab-title">3</span>
                  <span>Next of Kin</span>
                </>
              }>
              <NextOfKinScreen />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default UpdateProfile;
