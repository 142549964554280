import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { baseUrl } from "../../shared/baseUrl";
import { useDispatch, useSelector } from "react-redux";
import { getAllApplicantsForm } from "../../actions/jobApplicantsAction";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import {
  NoRecordFound,
  SearchComponent,
  TableFetch,
} from "../../components/TableOptions";
import Pagination from "../../components/Pagination";
import { useNavigate } from "react-router-dom";

export default function AllJobApplications() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [filtered, setFilterd] = useState([]);
  const [result, setResult] = useState("");

  const [displayData, setDisplayData] = useState([]);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const [entriesPerPage, setEntriesPerPage] = useState(() => {
    return localStorage.getItem("rowsPerPage") || "8";
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await axios.get(baseUrl + "/api/v1/jobapplications/all");
        setData(res.data.data);
        setFilterd(res.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setErrorMsg(error.message);
      }
      if (
        userInfo &&
        (userInfo.role === "Human Resource Executive" ||
          userInfo.role === "Head Of Department - HR" ||
          userInfo.role === "CEO" ||
          userInfo.role === "Super Admin" ||
          userInfo.role === "Assistant Manager - Human Resources" ||
          userInfo.role === "Human Resource Manager")
      ) {
        dispatch(getAllApplicantsForm());
      } else {
        navigate("/");
      }
    };
    fetchData();
    localStorage.setItem("rowsPerPage", entriesPerPage);
  }, [dispatch, navigate, userInfo, entriesPerPage]);

  useEffect(() => {
    const results = filtered?.filter(
      (res) =>
        res.fullname.toLowerCase().includes(result) ||
        res.email.toLowerCase().includes(result) ||
        res.nyscStatus.toLowerCase().includes(result) ||
        res.gender.toLowerCase().includes(result) ||
        res.jobLocation.toLowerCase().includes(result)
    );
    setData(results);
  }, [result, filtered]);

  const onChange = (e) => {
    setResult(e.target.value);
  };

  return (
    <div id="reports">
      <h5 className="page-title mb-4">Job Applications</h5>
      <div className="half-background">
        <SearchComponent
          sortData={data}
          entriesPerPage={entriesPerPage}
          setEntriesPerPage={setEntriesPerPage}
          placeholder={"All Job Applications"}
          value={result}
          onChange={onChange}
          CSVLinkd={data}
          CSVLinkdt={true}
        />
        <section className="md-ui component-data-table">
          <div>
            {errorMsg && (
              <Message variant="danger" className="error-border" dismissible>
                {errorMsg}
              </Message>
            )}
            {loading && <Loader />}
            <div className="main-table-wrapper">
              <table className="main-table-content">
                <thead className="data-table-header">
                  <tr className="data-table-row">
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Gender</th>
                    <th>Location</th>
                    <th>Nysc Status</th>
                    <th>Igbo</th>
                    <th>Hausa</th>
                    <th>Yoruba</th>
                    <th>...</th>
                  </tr>
                </thead>
                <tbody className="data-table-content">
                  {loading ? (
                    <TableFetch colSpan={9} />
                  ) : displayData?.length === 0 || displayData == null ? (
                    <NoRecordFound colSpan={9} />
                  ) : (
                    displayData?.map((user) => (
                      <tr key={user?._id}>
                        <td>{user?.fullname}</td>
                        <td>{user?.email}</td>
                        <td>{user?.gender}</td>
                        <td>{user?.jobLocation}</td>
                        <td>{user?.nyscStatus}</td>
                        <td>
                          {user.igboLanguage ? (
                            <i
                              className="fas fa-check-circle"
                              style={{ color: "green" }}
                            />
                          ) : (
                            <i
                              className="fas fa-times-circle"
                              style={{ color: "#e2522e" }}
                            />
                          )}
                        </td>
                        <td>
                          {user.hausaLanguage ? (
                            <i
                              className="fas fa-check-circle"
                              style={{ color: "green" }}
                            />
                          ) : (
                            <i
                              className="fas fa-times-circle"
                              style={{ color: "#e2522e" }}
                            />
                          )}
                        </td>
                        <td>
                          {user.yorubaLanguage ? (
                            <i
                              className="fas fa-check-circle"
                              style={{ color: "green" }}
                            />
                          ) : (
                            <i
                              className="fas fa-times-circle"
                              style={{ color: "#e2522e" }}
                            />
                          )}
                        </td>
                        <td>
                          <NavLink
                            to={`/getjobapplication/user/${user?._id}`}
                            className="update-btn rounded-5"
                            style={{
                              background: "#E2522E",
                              boxShadow: "none",
                            }}>
                            View
                          </NavLink>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <footer className="main-table-footer">
              <Pagination
                setDisplayData={setDisplayData}
                data={data}
                entriesPerPage={entriesPerPage}
                Total={"Job Applications"}
              />
            </footer>
          </div>
        </section>
      </div>
    </div>
  );
}
