import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { baseUrl } from "../../shared/baseUrl";
import axios from "axios";
import {
  NoRecordFound,
  SearchComponent,
  TableFetch,
} from "../../components/TableOptions";
import Pagination from "../../components/Pagination";

const AllQuoteRequest = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // const getAllRequestQuote = useSelector(state => state.getAllRequestQuote)
  // const { requests: dataRequest, loading, error } = getAllRequestQuote

  const [data, setData] = useState([]);
  const [filtered, setFilterd] = useState([]);
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [displayData, setDisplayData] = useState([]);
  const [entriesPerPage, setEntriesPerPage] = useState(() => {
    return localStorage.getItem("rowsPerPage") || "8";
  });

  // useEffect(() => {

  //  if (userInfo && (userInfo.role === 'Human Resource Executive' || userInfo.role === 'Head Of Department - HR' || userInfo.role === 'CEO' || userInfo.role === 'Super Admin' || userInfo.role === 'Assistant Manager - Human Resources' || userInfo.role === 'Manager - Human Resources')) {
  //   dispatch(AllQuoteRequestActions())
  //  } else {
  //   history.push('/')
  //  }
  // }, [dispatch, history, userInfo])

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role === "Human Resource Executive" ||
        userInfo?.role === "Head Of Department - HR" ||
        userInfo?.role === "CEO" ||
        userInfo?.role === "Super Admin" ||
        userInfo?.role === "Assistant Manager - Human Resources" ||
        userInfo?.role === "Manager - Human Resources")
    ) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const fetchData = async () => {
        try {
          setLoading(true);
          const res = await axios.get(baseUrl + "/api/v1/requests", config);
          setData(res?.data?.requests);
          setFilterd(res?.data?.requests);
          setLoading(false);
        } catch (error) {
          setErrorMsg(error?.message);
          console.log(error?.message);
        }
      };
      fetchData();
      localStorage.setItem("rowsPerPage", entriesPerPage);
    }
  }, [dispatch, entriesPerPage, userInfo]);

  useEffect(() => {
    const results = filtered?.filter(
      (data) =>
        data?.companyName?.toLowerCase()?.includes(result) ||
        data?.email?.toLowerCase()?.includes(result) ||
        data?.service?.toLowerCase()?.includes(result) ||
        data?.description?.toLowerCase()?.includes(result)
    );
    setData(results);
  }, [result, filtered]);

  const onChange = (e) => {
    setResult(e.target.value);
  };

  return (
    <div id="reports">
      <h5 className="page-title mb-4">Support Service</h5>
      <div className="half-background">
        <SearchComponent
          sortData={data}
          entriesPerPage={entriesPerPage}
          setEntriesPerPage={setEntriesPerPage}
          placeholder={"All Quote request"}
          value={result}
          onChange={onChange}
        />
        {errorMsg && (
          <Message variant="danger" className="error-border" dismissible>
            {errorMsg}
          </Message>
        )}
        <section className="md-ui component-data-table">
          {loading && <Loader />}
          <div className="main-table-wrapper">
            <table className="main-table-content">
              <thead className="data-table-header">
                <tr className="data-table-row">
                  <th>Company Name</th>
                  <th>Email</th>
                  <th>Service</th>
                  <th>Description</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody className="data-table-content">
                {false ? (
                  <TableFetch colSpan={8} />
                ) : displayData?.length === 0 || displayData == null ? (
                  <NoRecordFound colSpan={8} />
                ) : (
                  displayData?.map((user, i) => (
                    <tr className="data-table-row" key={i}>
                      <td>{user?.companyName}</td>
                      <td>{user?.email}</td>
                      <td>{user?.service}</td>
                      <td>{user?.description}</td>
                      <td>{moment(user?.createdAt)?.format("DD-MM-YYYY")}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </section>
        <footer className="main-table-footer">
          <Pagination
            setDisplayData={setDisplayData}
            data={data}
            entriesPerPage={entriesPerPage}
            Total={"Support Service"}
          />
        </footer>
      </div>
    </div>
  );
};

export default AllQuoteRequest;
