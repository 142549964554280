import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import moment from "moment";
import Message from "../../components/Message";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, updateUserProfile } from "../../actions/userActions";
import { USER_UPDATE_PROFILE_RESET } from "../../constants/userConstants";
import { useNavigate } from "react-router-dom";

const DashboardScreen = () => {
  const navigate = useNavigate();
  const [firstname, setFirstname] = useState("");
  const [middlename, setMiddlename] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [employeeCode, setEmployeeCode] = useState("");
  const [department, setDepartment] = useState("");
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { error, user } = userDetails;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success, loading } = userUpdateProfile;

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (success) {
      dispatch({
        type: USER_UPDATE_PROFILE_RESET,
      });
    } else {
      if (!user || !user.firstname) {
        dispatch(getUserDetails("me"));
      } else {
        setFirstname(user.firstname);
        setLastname(user.lastname);
        setMiddlename(user.middlename);
        setDateOfJoining(moment(user.dateOfJoining).format("DD-MM-YYYY"));
        setDepartment(user.department);
        setEmployeeCode(user.employeeCode);
      }
    }
  }, [dispatch, navigate, success, userInfo, user]);

  useEffect(() => {
    if (success) {
      setShowMessage(true);
      dispatch({
        type: USER_UPDATE_PROFILE_RESET,
      });
    }
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  }, [dispatch, success]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      dispatch(
        updateUserProfile({
          _id: user._id,
          firstname,
          lastname,
          password,
          middlename,
        })
      );
    }
  };

  return (
    <div className="profilescreen-wrapper mt-3">
      {message && (
        <Message variant="danger" className="error-border" dismissible>
          {message}
        </Message>
      )}
      {error && (
        <Message variant="danger" className="error-border" dismissible>
          {error}
        </Message>
      )}
      {/* {success && <Message variant='success'>Profile Updated</Message>} */}
      {showMessage && (
        <Message variant="success" className="success-border">
          {" "}
          <p className="success-color">Profile Updated successfully!</p>{" "}
        </Message>
      )}
      <Form onSubmit={submitHandler} className="form-shadow">
        <Form.Row>
          <Form.Group className="col-md-4" controlId="firstname">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              disabled
              placeholder="Enter First name"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}></Form.Control>
          </Form.Group>
          <Form.Group className="col-md-4" controlId="middlename">
            <Form.Label>Middle Name</Form.Label>
            <Form.Control
              type="text"
              disabled
              placeholder="Enter Middle name"
              value={middlename}
              onChange={(e) => setMiddlename(e.target.value)}></Form.Control>
          </Form.Group>
          <Form.Group className="col-md-4" controlId="lastname">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              disabled
              placeholder="Enter Last name"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}></Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group className="col-md-4" controlId="department">
            <Form.Label>Department</Form.Label>
            <Form.Control
              type="text"
              disabled
              placeholder="Enter Department"
              value={department}
              onChange={(e) => setDepartment(e.target.value)}></Form.Control>
          </Form.Group>
          <Form.Group className="col-md-4" controlId="employeeCode">
            <Form.Label>Employee Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Employee Code"
              disabled
              value={employeeCode}
              onChange={(e) => setEmployeeCode(e.target.value)}></Form.Control>
          </Form.Group>
          <Form.Group className="col-md-4" controlId="dateOfJoining">
            <Form.Label>Date Of Joining</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Date"
              disabled
              value={dateOfJoining}
              onChange={(e) => setDateOfJoining(e.target.value)}></Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group className="col-md-6" controlId="password">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}></Form.Control>
          </Form.Group>
          <Form.Group className="col-md-6" controlId="confirrmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) =>
                setConfirmPassword(e.target.value)
              }></Form.Control>
          </Form.Group>
        </Form.Row>
        <Button
          className="btn-block"
          variant="primary"
          disabled={loading && true}
          type="submit"
          value="Update">
          {loading ? "Updating..." : "Update"}
        </Button>
      </Form>
    </div>
  );
};

export default DashboardScreen;
