export const PERFORMANCE_ASSESSMENT_CREATE_REQUEST = 'PERFORMANCE_ASSESSMENT_CREATE_REQUEST';
export const PERFORMANCE_ASSESSMENT_CREATE_SUCCESS = 'PERFORMANCE_ASSESSMENT_CREATE_SUCCESS';
export const PERFORMANCE_ASSESSMENT_CREATE_FAIL = 'PERFORMANCE_ASSESSMENT_CREATE_FAIL';
export const PERFORMANCE_ASSESSMENT_CREATE_RESET = 'PERFORMANCE_ASSESSMENT_CREATE_RESET';

export const TEAM_PERFORMANCE_ASSESSMENTS_REQUEST = 'TEAM_PERFORMANCE_ASSESSMENTS_REQUEST';
export const TEAM_PERFORMANCE_ASSESSMENTS_SUCCESS = 'TEAM_PERFORMANCE_ASSESSMENTS_SUCCESS';
export const TEAM_PERFORMANCE_ASSESSMENTS_FAIL = 'TEAM_PERFORMANCE_ASSESSMENTS_FAIL';
export const TEAM_PERFORMANCE_ASSESSMENTS_RESET = 'TEAM_PERFORMANCE_ASSESSMENTS_RESET';

export const GET_PERFORMANCE_ASSESSMENT_ID_REQUEST = 'GET_PERFORMANCE_ASSESSMENT_ID_REQUEST';
export const GET_PERFORMANCE_ASSESSMENT_ID_SUCCESS = 'GET_PERFORMANCE_ASSESSMENT_ID_SUCCESS';
export const GET_PERFORMANCE_ASSESSMENT_ID_FAIL = 'GET_PERFORMANCE_ASSESSMENT_ID_FAIL';
export const GET_PERFORMANCE_ASSESSMENT_ID_RESET = 'GET_PERFORMANCE_ASSESSMENT_ID_RESET';

export const UPDATE_TEAM_ASSESSMENT_ID_REQUEST = ' UPDATE_TEAM_ASSESSMENT_ID_REQUEST';
export const UPDATE_TEAM_ASSESSMENT_ID_SUCCESS = ' UPDATE_TEAM_ASSESSMENT_ID_SUCCESS';
export const UPDATE_TEAM_ASSESSMENT_ID_FAIL = '  UPDATE_TEAM_ASSESSMENT_ID_FAIL';
export const UPDATE_TEAM_ASSESSMENT_ID_RESET = ' UPDATE_TEAM_ASSESSMENT_ID_RESET';

export const ALL_PERFORMANCE_ASSESSMENTS_REQUEST = 'ALL_PERFORMANCE_ASSESSMENTS_REQUEST';
export const ALL_PERFORMANCE_ASSESSMENTS_SUCCESS = 'ALL_PERFORMANCE_ASSESSMENTS_SUCCESS';
export const ALL_PERFORMANCE_ASSESSMENTS_FAIL = 'ALL_PERFORMANCE_ASSESSMENTS_FAIL';
export const ALL_PERFORMANCE_ASSESSMENTS_RESET = 'ALL_PERFORMANCE_ASSESSMENTS_RESET';

export const GET_EMPLOYEE_ASSESSMENTS_REQUEST = 'GET_EMPLOYEE_ASSESSMENTS_REQUEST';
export const GET_EMPLOYEE_ASSESSMENTS_SUCCESS = 'GET_EMPLOYEE_ASSESSMENTS_SUCCESS';
export const GET_EMPLOYEE_ASSESSMENTS_FAIL = 'GET_EMPLOYEE_ASSESSMENTS_FAIL';
export const GET_EMPLOYEE_ASSESSMENTS_RESET = 'GET_EMPLOYEE_ASSESSMENTS_RESET';

export const EMPLOYEE_UPDATE_KPI_REQUEST = 'EMPLOYEE_UPDATE_KPI_REQUEST';
export const EMPLOYEE_UPDATE_KPI_SUCCESS = 'EMPLOYEE_UPDATE_KPI_SUCCESS';
export const EMPLOYEE_UPDATE_KPI_FAIL = 'EMPLOYEE_UPDATE_KPI_FAIL';
export const EMPLOYEE_UPDATE_KPI_RESET = 'EMPLOYEE_UPDATE_KPI_RESET';