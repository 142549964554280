import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { getAllDeptLeaveApplications } from "../../actions/leaveApplication";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import { NoRecordFound, TableFetch } from "../../components/TableOptions";
import Pagination from "../../components/Pagination";

const DepartmentLeavesScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [entriesPerPage] = useState(() => {
    return localStorage.getItem("rowsPerPage") || "8";
  });
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [displayData, setDisplayData] = useState([]);
  const departmentLeavesApps = useSelector(
    (state) => state.departmentLeavesApps
  );
  const { data, error, loading } = departmentLeavesApps;

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role === "Head Of Department" ||
        userInfo.role === "Head Of Department - HR" ||
        userInfo.role === "Assistant Manager" ||
        userInfo.role === "Human Resource Manager" ||
        userInfo.role === "QA - Lead")
    ) {
      dispatch(getAllDeptLeaveApplications());
    } else {
      navigate("/");
    }
  }, [dispatch, navigate, userInfo]);

  return (
    <div className="dashboard-container">
      <div>
        <div className="leave-body all-leave-wrapper">
          <div className="allLeave-title mb-4">
            <h3>My Team Leave Applications</h3>
          </div>
          {error && (
            <Message variant="danger" className="error-border" dismissible>
              {error}
            </Message>
          )}
          {loading && <Loader />}
          <table className="performance-table ">
            <thead>
              <tr>
                <th>Email Address</th>
                <th>Leave Type</th>
                <th>Start Date</th>
                <th>N0: Days</th>
                <th>HOD Approval</th>
                <th>Final Approval</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <TableFetch colSpan={8} />
              ) : displayData?.length === 0 || displayData == null ? (
                <NoRecordFound colSpan={8} />
              ) : (
                displayData?.map((user) => (
                  <tr key={user._id}>
                    <td>{user.employee.email}</td>
                    <td>{user.leaveType}</td>
                    <td>{moment(user.fromDate).format("DD-MM-YYYY")}</td>
                    <td>{user.noOfDays}</td>
                    <td>
                      {user.hodApproval ? (
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "green" }}></i>
                      ) : (
                        <i
                          className="fas fa-times-circle"
                          style={{ color: "red" }}></i>
                      )}
                    </td>
                    <td>
                      {user.finalApproval ? (
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "green" }}></i>
                      ) : (
                        <i
                          className="fas fa-times-circle"
                          style={{ color: "red" }}></i>
                      )}
                    </td>
                    <td>
                      <NavLink
                        to={`/mydepartmentapplications/${user._id}/update`}
                        exact
                        className="update-btn">
                        View
                      </NavLink>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <footer className="main-table-footer">
          <Pagination
            setDisplayData={setDisplayData}
            data={data}
            entriesPerPage={entriesPerPage}
            Total={"Department Leave"}
          />
        </footer>
      </div>
    </div>
  );
};

export default DepartmentLeavesScreen;
