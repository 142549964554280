import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getEmployeeAssessmentsAction } from "../../actions/performanceActions";
import PolicyModal from "../../components/PolicyModal";
import DonutChat from "../../components/AdminDashboardChat/DonutChat";
import Barchat from "../../components/AdminDashboardChat/Barchat";
import FullBarChart from "../../components/AdminDashboardChat/FullBarChart";
import LineChart from "../../components/AdminDashboardChat/LineChart";
import { useNavigate } from "react-router-dom";
import { NoRecordFound, TableFetch } from "../../components/TableOptions";
import { RiUser6Fill } from "react-icons/ri";
import { listUsers } from "../../actions/userActions";
import { getAllEmployeeLeaveApplications } from "../../actions/leaveApplication";
import { getAllPerformanceAssessmentsAction } from "../../actions/performanceActions";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const getLeaveAppDetails = useSelector((state) => state.getLeaveAppDetails);
  const { data: datainfo } = getLeaveAppDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userList = useSelector((state) => state.userList);
  const { data: employees } = userList;
  const getAllPerformanceAssessments = useSelector(
    (state) => state.getAllPerformanceAssessments
  );
  const { data: kpidata } = getAllPerformanceAssessments;
  const getEmployeeAssessments = useSelector(
    (state) => state.getEmployeeAssessments
  );
  const { data: infoData, loading } = getEmployeeAssessments;

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  useEffect(() => {
    if (userInfo) {
      dispatch(getEmployeeAssessmentsAction());
      dispatch(getAllEmployeeLeaveApplications());
      dispatch(getAllPerformanceAssessmentsAction());
    } else {
      navigate("/");
    }
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    setData(infoData);
  }, [infoData]);

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role === "Human Resource Executive" ||
        userInfo.role === "Head Of Department - HR" ||
        userInfo.role === "CEO" ||
        userInfo.role === "Super Admin" ||
        userInfo.role === "Assistant Manager - Human Resources" ||
        userInfo.role === "Human Resource Manager")
    ) {
      dispatch(listUsers());
    }
  }, [userInfo, dispatch, navigate]);

  function handleShow() {
    setFullscreen(true);
    setShow(true);
  }

  const currentYear = new Date().getFullYear();

  // Leave data
  const leaveRecordsForCurrentYear = datainfo?.filter((record) => {
    const fromDateYear = new Date(record.fromDate).getFullYear();
    const toDateYear = new Date(record.toDate).getFullYear();

    return fromDateYear === currentYear || toDateYear === currentYear;
  });

  // kpi data
  const performanceRecordsForCurrentYear = kpidata?.filter((record) => {
    const recordYear = new Date(record.createdAt).getFullYear();
    return recordYear === currentYear;
  });

  return (
    <div className="dashboard-container">
      <PolicyModal />
      <div className="dashboard-admin pb-2">
        <div className="dashboard-cards-admin">
          <div className="card">
            <RiUser6Fill size={30} color="#124383" />

            <div className="card-inner text-title text-title-flex">
              <p className="text-title-flex-margin">Total Employee</p>
              <span className="font-bold text-title text-title-flex-margin-span">
                {employees && employees?.length}
              </span>
            </div>
          </div>

          <div className="card">
            <i className="fa fa-calendar fa-2x"></i>
            <div className="card-inner">
              <p className="text-title-flex-margin">
                Total Leave {currentYear}
              </p>
              <span className="font-bold text-title text-title-flex-margin-span">
                {datainfo && leaveRecordsForCurrentYear?.length}
              </span>
            </div>
          </div>

          <div className="card">
            <i className="fas fa-chart-line fa-2x"></i>
            <div className="card-inner">
              <p className="text-title-flex-margin">Total KPI {currentYear}</p>
              <span className="font-bold text-title text-title-flex-margin-span">
                {kpidata && performanceRecordsForCurrentYear?.length}
              </span>
            </div>
          </div>

          <div className="card">
            <i className="fas fa-briefcase fa-2x"></i>
            <div className="card-inner">
              <p className="text-title-flex-margin">Leave Balance</p>
              <span className="font-bold text-title text-title-flex-margin-span">
                {userInfo?.leaveDays} Days
              </span>
            </div>
          </div>
        </div>

        <div className="charts">
          <div className="charts-left">
            <div className="charts-left-title">
              <div>
                <h1>KPI</h1>
                <p>My Performance Assessments</p>
              </div>
              <i className="fa fa-usd"></i>
            </div>
            <div id="reports">
              {/* <h5 className="page-title">All Performance Assessments</h5> */}
              <div className="half-background dashboard-table">
                <section className="md-ui component-data-table">
                  <div className="main-table-wrapper">
                    <table className="main-table-content">
                      <thead className="data-table-header">
                        <tr className="data-table-row">
                          <th>Year</th>
                          <th>Quarter</th>
                          <th>Average</th>
                          <th>Updated</th>
                          <th> ... </th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <TableFetch colSpan={5} />
                        ) : data?.length === 0 || data == null ? (
                          <NoRecordFound colSpan={5} />
                        ) : (
                          data?.map((user) => (
                            <tr key={user._id}>
                              <td>{user.year}</td>
                              <td>{user.quarter}</td>
                              <td className="table-avgScore">
                                {user.performanceAverage > 0 ? (
                                  <>{user.performanceAverage}%</>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>
                                {user.reportsToUpdate ? (
                                  <i
                                    className="fas fa-check-circle"
                                    style={{ color: "green" }}
                                  />
                                ) : (
                                  <i
                                    className="fas fa-times-circle"
                                    style={{ color: "#e2522e" }}
                                  />
                                )}
                              </td>
                              <td>
                                <NavLink
                                  to={`/kpi/${user._id}/summary`}
                                  exact
                                  className="update-btn rounded-5"
                                  style={{
                                    background: "#E2522E",
                                    boxShadow: "none",
                                  }}>
                                  View
                                </NavLink>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <div className="charts-right" style={{ background: "#fff" }}>
            <div className="charts-right-title">
              <div>
                <h1>employee Reports</h1>
                <p>Click the blue icon to see more department</p>
              </div>
              <i
                className="fa fa-usd"
                onClick={handleShow}
                data-toggle="modal"
                data-target="#fullScreenModal"></i>
            </div>

            <div className="charts-right-cards">
              <div>
                <div>
                  <Barchat employees={!employees?.length ? [] : employees} />
                  <FullBarChart
                    employees={!employees?.length ? [] : employees}
                    show={show}
                    fullscreen={fullscreen}
                    setFullscreen={setFullscreen}
                    setShow={setShow}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="charts mb-5">
          <div className="charts-left">
            <div className="charts-left-title">
              <div>
                <h1>KPI Performance</h1>
                <p>Your Performance Assessments in two Years</p>
              </div>
              <i className="fa fa-usd"></i>
            </div>
            <div>
              <LineChart resultArray={data} />
            </div>
          </div>
          <div className="charts-right" style={{ background: "#fff" }}>
            <div className="charts-right-title">
              <div>
                <h1>Policy</h1>
                <p>Length of employees policy with true & false</p>
              </div>
              <i
                className="fa fa-usd"
                data-toggle="modal"
                data-target="#fullScreenModal"></i>
            </div>

            <div className="charts-right-cards">
              <DonutChat employees={employees} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
