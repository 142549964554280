import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import mobileLogo from "../../img/outcess-icon-haf.png";
import { Link, useLocation } from "react-router-dom";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from "react-pro-sidebar";
import { FaRegCommentDots } from "react-icons/fa";
import { MdOutlinePolicy } from "react-icons/md";
import { TfiLayoutGrid2 } from "react-icons/tfi";
import { IoMdLaptop } from "react-icons/io";
import { MdOutlineInventory } from "react-icons/md";
import { FiPieChart } from "react-icons/fi";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { MdOutlineRequestQuote } from "react-icons/md";
import { GoFile } from "react-icons/go";
import { BsBriefcase } from "react-icons/bs";
import getUserPrivileges from "../../functions/auth";
import { RiTeamLine } from "react-icons/ri";
import { GiTeamIdea } from "react-icons/gi";
import { MdOutlinePrivacyTip } from "react-icons/md";

const MobileSideBar = ({
  ToggleSidebar,
  isOpen,
  toggled,
  handleToggleSidebar,
  collapseNav,
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const {
    isHRHead,
    isCEO,
    isSuperAdmin,
    isHRAssistantManager,
    isHRManager,
    isHRExecutive,
    isTeamLead,
    isMis,
    isAgent,
    isHod,
    isIT,
    isQA,
  } = getUserPrivileges();

  const { pathname } = useLocation();

  const [openDropdown, setOpenDropdown] = useState(null);

  const handleSubMenuClick = (submenu) => {
    if (openDropdown === submenu) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(submenu);
    }
  };
  const handleMenuItemClick = (event) => {
    // Stop the propagation of the click event to the parent SubMenu
    event.stopPropagation();
  };

  return (
    <>
      {width <= 799 && (
        <>
          <div className={`sidebar ${isOpen === true ? "active" : ""}`}>
            <div className="sd-header">
              <img
                className="login-body-img-nav"
                src={mobileLogo}
                alt="outcess-logo"
              />

              <div onClick={ToggleSidebar}>
                <FaTimes />
              </div>
            </div>

            <ProSidebar
              // image={image ? sidebarBg : false}
              collapsed={collapseNav}
              toggled={toggled}
              onToggle={handleToggleSidebar}
              breakPoint="md"
              // collapsedWidth="4.5rem"
              style={{
                height: "100%",
                overflowY: "scroll",
                top: "auto",
                // position: 'sticky',
                left: isOpen ? 0 : "-270px",
                padding: "0rem",
                margin: "0rem",
                fontSize: "12px",
              }}>
              <SidebarContent>
                <Menu iconShape="circle">
                  <MenuItem
                    className="Side__Content"
                    active={pathname === "/home"}
                    icon={<TfiLayoutGrid2 size={17} />}>
                    <Link to="/home">Dashboard</Link>
                  </MenuItem>

                  <SubMenu
                    title={"Leave"}
                    icon={<BsBriefcase size={17} />}
                    onClick={() => handleSubMenuClick("dropdown1")}
                    open={openDropdown === "dropdown1"}>
                    <MenuItem
                      onClick={handleMenuItemClick}
                      className="Side__Content"
                      active={pathname === "/myleave"}>
                      <Link to="/myleave">Employee leave</Link>
                    </MenuItem>

                    {isAgent && (
                      <MenuItem
                        onClick={handleMenuItemClick}
                        className="Side__Content"
                        active={pathname === "/agentleave"}
                        icon={<RiTeamLine size={17} />}>
                        <Link to="/agentleave">Agent Leave</Link>
                      </MenuItem>
                    )}
                    {isTeamLead && (
                      <MenuItem
                        onClick={handleMenuItemClick}
                        className="Side__Content"
                        active={pathname === "/myteamapplications"}
                        icon={<GiTeamIdea size={17} />}>
                        <Link to="/myteamapplications">
                          Team Leave Applications
                        </Link>
                      </MenuItem>
                    )}
                    {(isHod || isHRHead) && (
                      <MenuItem
                        onClick={handleMenuItemClick}
                        className="Side__Content"
                        active={pathname === "/mydepartmentapplications"}
                        icon={<GiTeamIdea size={17} />}>
                        <Link to="/mydepartmentapplications">
                          My Department Leave
                        </Link>
                      </MenuItem>
                    )}
                    {(isHRExecutive ||
                      isHRAssistantManager ||
                      isHod ||
                      isCEO ||
                      isSuperAdmin ||
                      isHRManager) && (
                      <>
                        <MenuItem
                          onClick={handleMenuItemClick}
                          className="Side__Content"
                          active={pathname === "/allagentleaveapplications"}
                          icon={<GiTeamIdea size={17} />}>
                          <Link to="/allagentleaveapplications">
                            {" "}
                            All Agent leave
                          </Link>
                        </MenuItem>
                        <MenuItem
                          onClick={handleMenuItemClick}
                          className="Side__Content"
                          active={pathname === "/leaveapplications"}
                          icon={<GiTeamIdea size={17} />}>
                          <Link to="/leaveapplications">
                            All Leave Applications
                          </Link>
                        </MenuItem>
                      </>
                    )}
                  </SubMenu>

                  {(isCEO ||
                    isHRHead ||
                    isSuperAdmin ||
                    isHRAssistantManager ||
                    isHRManager ||
                    isHRExecutive) && (
                    <MenuItem
                      onClick={handleMenuItemClick}
                      className="Side__Content"
                      active={pathname === "/admin/userlist"}
                      icon={<GoFile size={17} />}>
                      <Link to="/admin/userlist">All Employees</Link>
                    </MenuItem>
                  )}

                  <SubMenu
                    title={"KPI Assessment"}
                    icon={<FiPieChart size={17} />}
                    onClick={() => handleSubMenuClick("dropdown2")}
                    open={openDropdown === "dropdown2"}>
                    <MenuItem
                      onClick={handleMenuItemClick}
                      className="Side__Content"
                      active={pathname === "/kpi/user"}
                      icon={<TfiLayoutGrid2 size={17} />}>
                      <Link to="/kpi/user">My KPI Assessment</Link>
                    </MenuItem>
                    {(isCEO ||
                      isHRHead ||
                      isSuperAdmin ||
                      isHRAssistantManager ||
                      isHRManager ||
                      isHRExecutive ||
                      isQA ||
                      isMis ||
                      isTeamLead) && (
                      <MenuItem
                        onClick={handleMenuItemClick}
                        className="Side__Content"
                        active={pathname === "/kpi/teamassessments"}
                        icon={<RiTeamLine size={17} />}>
                        <Link to="/kpi/teamassessments">
                          Team KPI Assessments
                        </Link>
                      </MenuItem>
                    )}

                    {(isCEO ||
                      isHRHead ||
                      isSuperAdmin ||
                      isHRAssistantManager ||
                      isHRManager ||
                      isHRExecutive) && (
                      <MenuItem
                        onClick={handleMenuItemClick}
                        className="Side__Content"
                        active={pathname === "/kpi/allassessments"}
                        icon={<RiTeamLine size={17} />}>
                        <Link to="/kpi/allassessments">
                          All KPI Assessments
                        </Link>
                      </MenuItem>
                    )}
                  </SubMenu>

                  {(isHRHead ||
                    isSuperAdmin ||
                    isHRAssistantManager ||
                    isHRManager ||
                    isHRExecutive) && (
                    <SubMenu
                      title={"Job Application"}
                      icon={<VscWorkspaceTrusted size={17} />}
                      onClick={() => handleSubMenuClick("dropdown3")}
                      open={openDropdown === "dropdown3"}>
                      <MenuItem
                        onClick={handleMenuItemClick}
                        className="Side__Content"
                        active={pathname === "/createJobpost"}
                        icon={<TfiLayoutGrid2 size={17} />}>
                        <Link to="/createJobpost">Create Job Post</Link>
                      </MenuItem>
                      <MenuItem
                        onClick={handleMenuItemClick}
                        className="Side__Content"
                        active={pathname === "/alljobapplication"}
                        icon={<RiTeamLine size={17} />}>
                        <Link to="/alljobapplication">
                          All Job Applications
                        </Link>
                      </MenuItem>
                    </SubMenu>
                  )}

                  <SubMenu
                    title={"Support Service"}
                    icon={<FaRegCommentDots size={17} />}
                    onClick={() => handleSubMenuClick("dropdown4")}
                    open={openDropdown === "dropdown4"}>
                    <MenuItem
                      onClick={handleMenuItemClick}
                      className="Side__Content"
                      active={pathname === "/supportservice"}
                      icon={<TfiLayoutGrid2 size={17} />}>
                      <Link to="/supportservice">Support Service</Link>
                    </MenuItem>
                    {(isCEO ||
                      isHRHead ||
                      isSuperAdmin ||
                      isHRAssistantManager ||
                      isHRManager ||
                      isHRExecutive) && (
                      <MenuItem
                        onClick={handleMenuItemClick}
                        className="Side__Content"
                        active={pathname === "/allsupportservice"}
                        icon={<RiTeamLine size={17} />}>
                        <Link to="/allsupportservice">All Support Service</Link>
                      </MenuItem>
                    )}
                  </SubMenu>

                  {(isHod ||
                    isHRHead ||
                    isSuperAdmin ||
                    isHRManager ||
                    isHRExecutive ||
                    isIT) && (
                    <SubMenu
                      title={"Laptop Request"}
                      icon={<IoMdLaptop size={17} />}
                      onClick={() => handleSubMenuClick("dropdown5")}
                      open={openDropdown === "dropdown5"}>
                      <MenuItem
                        onClick={handleMenuItemClick}
                        className="Side__Content"
                        active={pathname === "/laptopapplication"}
                        icon={<TfiLayoutGrid2 size={17} />}>
                        <Link to="/laptopapplication">Request For Laptop</Link>
                      </MenuItem>
                      <MenuItem
                        onClick={handleMenuItemClick}
                        className="Side__Content"
                        active={pathname === "/hodgetallaptop"}
                        icon={<RiTeamLine size={17} />}>
                        <Link to="/hodgetallaptop">All Laptop Request</Link>
                      </MenuItem>
                    </SubMenu>
                  )}

                  {(isCEO || isIT) && (
                    <MenuItem
                      onClick={handleMenuItemClick}
                      className="Side__Content"
                      active={pathname === "/laptopinfo"}
                      icon={<MdOutlineInventory size={17} />}>
                      <Link to="/laptopinfo">Inventory</Link>
                    </MenuItem>
                  )}
                  {(isHod || isIT || isHRHead || isSuperAdmin || isHRHead) && (
                    <MenuItem
                      onClick={handleMenuItemClick}
                      className="Side__Content"
                      active={pathname === "/allquoterequest"}
                      icon={<MdOutlineRequestQuote size={17} />}>
                      <Link to="/allquoterequest">All Quote Request</Link>
                    </MenuItem>
                  )}

                  <MenuItem
                    onClick={handleMenuItemClick}
                    className="Side__Content"
                    active={pathname === "/policy"}
                    icon={<MdOutlinePolicy size={17} />}>
                    <Link to="/policy">Policy</Link>
                  </MenuItem>

                  <MenuItem
                    onClick={handleMenuItemClick}
                    className="Side__Content"
                    active={pathname === "/privacy-policy"}
                    icon={<MdOutlinePrivacyTip size={17} />}>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </MenuItem>
                </Menu>
              </SidebarContent>
            </ProSidebar>
          </div>
          <div
            className={`sidebar-overlay ${isOpen === true ? "active" : ""}`}
            onClick={ToggleSidebar}></div>
        </>
      )}
    </>
  );
};

export default MobileSideBar;
