export const JOB_APPLICANTS_DETAILS_REQUEST = 'JOB_APPLICANTS_DETAILS_REQUEST';
export const JOB_APPLICANTS_DETAILS_SUCCESS = 'JOB_APPLICANTS_DETAILS_SUCCESS';
export const JOB_APPLICANTS_DETAILS_FAIL = 'JOB_APPLICANTS_DETAILS_FAIL';
export const JOB_APPLICANTS_DETAILS_RESET = 'JOB_APPLICANTS_DETAILS_RESET';

export const JOB_APPLICANTS_CREATE_REQUEST = 'JOB_APPLICANTS_CREATE_REQUEST';
export const JOB_APPLICANTS_CREATE_SUCCESS = 'JOB_APPLICANTS_CREATE_SUCCESS';
export const JOB_APPLICANTS_CREATE_FAIL = 'JOB_APPLICANTS_CREATE_FAIL';
export const JOB_APPLICANTS_CREATE_RESET = 'JOB_APPLICANTS_CREATE_RESET';


export const GET_JOB_ID_REQUEST = 'GET_JOB_ID_REQUEST';
export const GET_JOB_ID_SUCCESS = 'GET_JOB_ID_SUCCESS';
export const GET_JOB_ID_FAIL = 'GET_JOB_ID_FAIL';
export const GET_JOB_ID_RESET = 'GET_JOB_ID_RESET';