import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Modal, Form, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createSystemInfomation,
  getLaptopInfo,
} from "../../actions/LaptopApplicationAction";
import { CREATE_LAPTOP_INFO_RESET } from "../../constants/laptopApplicationContants";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import {
  NoRecordFound,
  SearchComponent,
  TableFetch,
} from "../../components/TableOptions";
import Pagination from "../../components/Pagination";
import { useNavigate } from "react-router-dom";
import ModalHeader from "../../components/ModalHeader";
import { BiMessageRoundedDetail } from "react-icons/bi";

const LaptopInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const getAllLaptopInfoDetails = useSelector(
    (state) => state.getAllLaptopInfoDetails
  );
  const { data: datainfo, loading, error } = getAllLaptopInfoDetails;

  const createSystemInfo = useSelector((state) => state.createSystemInfo);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
  } = createSystemInfo;

  const [data, setData] = useState([]);
  const [filtered, setFilterd] = useState([]);
  const [result, setResult] = useState("");
  const [displayData, setDisplayData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const [entriesPerPage, setEntriesPerPage] = useState(() => {
    return localStorage.getItem("rowsPerPage") || "8";
  });

  const [fullname, setFullName] = useState("");
  const [department, setDepartment] = useState("");
  const [employeeStatus, setEmployeeStatus] = useState("");
  const [systemName, setSystemName] = useState("");
  const [systemType, setSystemType] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [monitor, setMonitor] = useState("");
  const [monitorSerialNumber, setMonitorSerialNumber] = useState("");
  const [HDD, setHDD] = useState("");
  const [ramSize, setRamSize] = useState("");
  const [windowsVersion, setWindowsVersion] = useState(false);
  const [status, setStatus] = useState("");
  const [systemCondition, setSystemCondition] = useState("");
  const [systemStatus, setSystemStatus] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (
      userInfo &&
      (userInfo?.role === "IT Support Specialist" || userInfo.role === "CEO")
    ) {
      if (successCreate) {
        handleClose();
      }
      dispatch(getLaptopInfo());
    } else {
      navigate("/");
    }
  }, [dispatch, navigate, userInfo, successCreate]);

  useEffect(() => {
    setFilterd(datainfo);
    localStorage.setItem("rowsPerPage", entriesPerPage);
  }, [datainfo, entriesPerPage]);

  useEffect(() => {
    if (successCreate) {
      setShowMessage(true);
      dispatch({
        type: CREATE_LAPTOP_INFO_RESET,
      });
    }
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  }, [dispatch, successCreate, datainfo]);

  useEffect(() => {
    const results = filtered?.filter(
      (data) =>
        data?.fullname?.toLowerCase().includes(result) ||
        data?.serialNumber?.toLowerCase().includes(result) ||
        data?.monitorSerialNumber?.toLowerCase().includes(result)
    );
    setData(results);
  }, [result, filtered]);

  const onChange = (e) => {
    setResult(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault(e);
    dispatch(
      createSystemInfomation(
        fullname,
        department,
        employeeStatus,
        systemName,
        systemType,
        serialNumber,
        monitor,
        monitorSerialNumber,
        HDD,
        windowsVersion,
        ramSize,
        status,
        systemStatus,
        systemCondition
      )
    );
  };

  return (
    <div id="reports">
      <h5 className="page-title mb-4">System Information</h5>
      <div className="nav-tabs">
        <li className="active" onClick={handleShow}>
          Create Information
        </li>
      </div>
      <div className="half-background">
        <SearchComponent
          sortData={data}
          entriesPerPage={entriesPerPage}
          setEntriesPerPage={setEntriesPerPage}
          placeholder={"System Information"}
          value={result}
          onChange={onChange}
        />

        <section className="md-ui component-data-table">
          <>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="myleave-modal pt-0">
              <ModalHeader
                setLgShow={setShow}
                icon={<BiMessageRoundedDetail size={30} />}
                title={"System Information"}
                subtitle={"Input Laptop Information"}
              />
              <Modal.Body>
                {errorCreate && (
                  <Message
                    variant="danger"
                    className="error-border"
                    dismissible>
                    {errorCreate}
                  </Message>
                )}
                <Form onSubmit={submitHandler}>
                  <Form.Row>
                    <Form.Group as={Col} controlId="FirstName">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        value={fullname}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridDepartment">
                      <Form.Label>Department</Form.Label>
                      <Form.Control
                        as="select"
                        size="sm"
                        custom
                        value={department}
                        onChange={(e) => setDepartment(e.target.value)}>
                        <option value="">Select...</option>
                        <option value="Admin">Admin</option>
                        <option value="Sales">Sales</option>
                        <option value="MIS">MIS</option>
                        <option value="Projects">Projects</option>
                        <option value="Operations">Operations</option>
                        <option value="QA">QA</option>
                        <option value="Customer Service">
                          Customer Service
                        </option>
                        <option value="Human Resources">Human Resources</option>
                        <option value="IT">IT</option>
                        <option value="Training & Development">
                          Training & Development
                        </option>
                        <option value="Accounts">Accounts</option>
                        <option value="Enugu - MCN">Enugu - MCN</option>
                        <option value="Branch">Branch</option>
                        <option value="Multichoice">Multichoice</option>
                        <option value="Ntel">Ntel</option>
                        <option value="Fairmoney">Fairmoney</option>
                        <option value="KYC">KYC</option>
                        <option value="Sim swap">Sim swap</option>
                        <option value="Enterprise">Total</option>
                        <option value="Access bank">Access bank</option>
                        <option value="OUTCESS">OUTCESS</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="EmployeeStatus">
                      <Form.Label>Employee Status</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        size="sm"
                        value={employeeStatus}
                        onChange={(e) => setEmployeeStatus(e.target.value)}>
                        <option>Select Status...</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="lastName">
                      <Form.Label>System Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="System Name"
                        value={systemName}
                        onChange={(e) =>
                          setSystemName(e.target.value)
                        }></Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="LaptopType">
                      <Form.Label>System Type</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        size="sm"
                        value={systemType}
                        onChange={(e) => setSystemType(e.target.value)}>
                        <option>Select System Type...</option>
                        <option value="Laptop">Laptop</option>
                        <option value="DeskTop">DeskTop</option>
                        <option value="All-in-one">All-in-one</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="laptopRamSize">
                      <Form.Label>Serial Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Serial Number"
                        value={serialNumber}
                        onChange={(e) =>
                          setSerialNumber(e.target.value)
                        }></Form.Control>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="LaptopType">
                      <Form.Label>Monitor</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Monitor"
                        value={monitor}
                        disabled={
                          systemType === "Laptop" || systemType === "All-in-one"
                        }
                        onChange={(e) =>
                          setMonitor(e.target.value)
                        }></Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="SerialNumber">
                      <Form.Label>Monitor Serial Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Monitor Serial Number"
                        value={monitorSerialNumber}
                        disabled={
                          systemType === "Laptop" || systemType === "All-in-one"
                        }
                        onChange={(e) =>
                          setMonitorSerialNumber(e.target.value)
                        }></Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="RamSize">
                      <Form.Label>HDD Size</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="HDD Size"
                        value={HDD}
                        onChange={(e) => setHDD(e.target.value)}></Form.Control>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} controlId="LaptopType">
                      <Form.Label>Windows Version</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        size="sm"
                        value={windowsVersion}
                        onChange={(e) => setWindowsVersion(e.target.value)}>
                        <option value="">Select Status...</option>
                        <option value="Windows 7 ">Windows 7 </option>
                        <option value="Windows 8  ">Windows 8 </option>
                        <option value="Windows 10">Windows 10</option>
                        <option value="Windows 11">Windows 11</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="laptopRamSize">
                      <Form.Label>Ram Size</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        size="sm"
                        value={ramSize}
                        onChange={(e) => setRamSize(e.target.value)}>
                        <option value="">Select Ram Size...</option>
                        <option value="2">2gb </option>
                        <option value="4">4gb </option>
                        <option value="6">6gb</option>
                        <option value="8">8gb</option>
                        <option value="10">10gb</option>
                        <option value="12"> 12gb</option>
                        <option value="16"> 16gb</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="Status">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        size="sm"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}>
                        <option value="">Select Status...</option>
                        <option value="Good ">Good </option>
                        <option value="Faulty">Faulty</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="System Status">
                      <Form.Label>System Status</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        size="sm"
                        value={systemStatus}
                        onChange={(e) => setSystemStatus(e.target.value)}>
                        <option value="">Select Status...</option>
                        <option value="In Use">In Use </option>
                        <option value="Not In Use">Not In Use</option>
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                  <Form.Group controlId="description">
                    <Form.Label>System Condition</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Enter System Condition"
                      style={{ backgroundColor: "#fff" }}
                      value={systemCondition}
                      onChange={(e) => setSystemCondition(e.target.value)}
                    />
                  </Form.Group>
                  <Button
                    className="applyleave-btn mt-3"
                    variant="primary"
                    disabled={loadingCreate && true}
                    type="submit"
                    value="Create">
                    {loadingCreate ? "Creating..." : "Create"}
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
          </>
          {error && (
            <Message variant="danger" className="error-border">
              {error}
            </Message>
          )}
          {showMessage && (
            <Message variant="success" className="success-border">
              {" "}
              <p className="success-color">
                Laptop information updated successfully!
              </p>{" "}
            </Message>
          )}
          {loading && <Loader />}
          <div className="main-table-wrapper">
            <table className="main-table-content">
              <thead className="data-table-header">
                <tr className="data-table-row">
                  <th>Full Name</th>
                  <th>Department</th>
                  <th>Employee Status</th>
                  <th>System Name</th>
                  <th>Laptop</th>
                  <th>Serial Number</th>
                  <th>Monitor</th>
                  <th>Monitor Serial Number</th>
                  <th>HDD Size</th>
                  <th>Windows Version</th>
                  <th>RAM Size</th>
                  <th>Status</th>
                  <th>System Status</th>
                  <th>...</th>
                </tr>
              </thead>

              <tbody className="data-table-content">
                {false ? (
                  <TableFetch colSpan={8} />
                ) : displayData?.length === 0 || displayData == null ? (
                  <NoRecordFound colSpan={8} />
                ) : (
                  displayData?.map((user) => (
                    <tr key={user._id}>
                      <td>{user.fullname}</td>
                      <td>{user.department}</td>
                      <td>{user.employeeStatus}</td>
                      <td>{user.systemName}</td>
                      <td>{user.systemType}</td>
                      <td>{user.serialNumber}</td>
                      <td>{user.monitor}</td>
                      <td>{user.monitorSerialNumber}</td>
                      <td>{user.HDD}GB</td>
                      <td>{user.windowsVersion}</td>
                      <td>{user.ramSize}GB</td>
                      <td>{user.status}</td>
                      <td>{user.systemStatus}</td>
                      <td>
                        <NavLink
                          to={`/laptopinform/${user._id}/update`}
                          className="update-btn rounded-5"
                          style={{ background: "#E2522E", boxShadow: "none" }}>
                          View
                        </NavLink>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </section>
        <footer className="main-table-footer">
          <Pagination
            setDisplayData={setDisplayData}
            data={data}
            entriesPerPage={entriesPerPage}
            Total={"Support Service"}
          />
        </footer>
      </div>
    </div>
  );
};

export default LaptopInfo;
