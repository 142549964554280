import React, { useState, useEffect, useRef } from "react";
import { Col, Form, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  approveEmployeeLeaveApplicationId,
  getAllEmployeeLeaveApplicationById,
  rejectEmployeeLeaveApplicationId,
} from "../../actions/leaveApplication";
import {
  LEAVE_APPLICATION_DETAILS_ID_RESET,
  HOD_LEAVE_APPLICATION_UPDATE_EMPLOYEE_RESET,
  LEAVE_APPLICATION_REJECT_RESET,
} from "../../constants/leaveApplicationConstants";
import Footer from "../../components/Footer";
import Message from "../../components/Message";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import emailjs from "emailjs-com";
import { useNavigate, useParams } from "react-router-dom";
import ModalHeader from "../../components/ModalHeader";
import { GoBriefcase } from "react-icons/go";

const ApproveLeaveScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const leaveApplicationId = id;
  const form = useRef();

  const [leaveType, setLeaveType] = useState("");
  const [fromDate, setLeaveStartDate] = useState("");
  const [toDate, setLeaveEndDate] = useState("");
  const [noOfDays, setNoOfDays] = useState(0);
  const [reasonForLeave, setLeaveDescription] = useState("");
  // const [leaveStatus, setLeaveStatus] = useState("");
  const [leaveBalance, setLeaveBalance] = useState(0);
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setStatus("Approved");
  };
  const handleShow = () => {
    setShow(true);
    setStatus("Rejected");
  };

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const leaveApplicationDetailsById = useSelector(
    (state) => state.leaveApplicationDetailsById
  );
  const { leaveapplication, loading } = leaveApplicationDetailsById;

  const departmentLeavesApps = useSelector(
    (state) => state.departmentLeavesApps
  );
  const { data } = departmentLeavesApps;

  const [datas, setDatas] = useState([]);

  useEffect(() => {
    // gets the array of Matched Objects
    const user = data?.filter((object) => {
      return JSON?.stringify(object)
        ?.toString()
        ?.toLowerCase()
        ?.includes(leaveApplicationId);
    });
    setDatas(user);
  }, [leaveApplicationId, data]);

  const approveLeaveApp = useSelector((state) => state.approveLeaveApp);
  const {
    success: successUpdate,
    error: errorUpdate,
    loading: loadingApprove,
  } = approveLeaveApp;

  const rejectLeave = useSelector((state) => state.rejectLeave);
  const { success: successReject, error: errorReject } = rejectLeave;

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role === "Head Of Department" ||
        userInfo.role === "Head Of Department - HR" ||
        userInfo.role === "Assistant Manager" ||
        userInfo.role === "QA - Lead")
    ) {
      if (successUpdate) {
        if (
          !leaveapplication ||
          !leaveapplication.data?.leaveType ||
          leaveapplication.data?._id !== leaveApplicationId
        ) {
          dispatch(getAllEmployeeLeaveApplicationById(leaveApplicationId));
        } else {
          setLeaveType(leaveapplication.data.leaveType);
          setLeaveStartDate(
            moment(leaveapplication.data.fromDate).format("YYYY-MM-DD")
          );
          setLeaveEndDate(
            moment(leaveapplication.data.toDate).format("YYYY-MM-DD")
          );
          setLeaveDescription(leaveapplication.data.reasonForLeave);
          setNoOfDays(leaveapplication.data.noOfDays);
          // setLeaveStatus(leaveapplication.data.leaveStatus);
          setApproved(leaveapplication.data.hodApproval);
          setRejected(leaveapplication.data.rejected);
          setLeaveBalance(leaveapplication.leaveBalance);
          setUserName(leaveapplication?.data?.employee?.firstname);
          setUserEmail(leaveapplication?.data?.employee?.email);
        }
      }
    } else {
      navigate("/");
    }
  }, [
    navigate,
    userInfo,
    leaveApplicationId,
    leaveapplication,
    dispatch,
    successUpdate,
  ]);

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role === "Head Of Department" ||
        userInfo.role === "Head Of Department - HR" ||
        userInfo.role === "Assistant Manager" ||
        userInfo.role === "QA - Lead")
    ) {
      if (successReject) {
        dispatch({
          type: HOD_LEAVE_APPLICATION_UPDATE_EMPLOYEE_RESET,
        });
        dispatch({
          type: LEAVE_APPLICATION_DETAILS_ID_RESET,
        });
        dispatch({
          type: LEAVE_APPLICATION_REJECT_RESET,
        });
        navigate("/mydepartmentapplications");
      } else {
        if (
          !leaveapplication ||
          !leaveapplication.data?.leaveType ||
          leaveapplication.data?._id !== leaveApplicationId
        ) {
          dispatch(getAllEmployeeLeaveApplicationById(leaveApplicationId));
        } else {
          setLeaveType(leaveapplication.data?.leaveType);
          setLeaveStartDate(
            moment(leaveapplication.data.fromDate).format("YYYY-MM-DD")
          );
          setLeaveEndDate(
            moment(leaveapplication.data.toDate).format("YYYY-MM-DD")
          );
          setLeaveDescription(leaveapplication.data.reasonForLeave);
          setNoOfDays(leaveapplication.data.noOfDays);
          // setLeaveStatus(leaveapplication.data.leaveStatus);
          setApproved(leaveapplication.data.hodApproval);
          setRejected(leaveapplication.data.rejected);
          setLeaveBalance(leaveapplication.leaveBalance);
          setUserName(leaveapplication?.data?.employee?.firstname);
          setUserEmail(leaveapplication?.data?.employee?.email);
        }
      }
    } else {
      navigate("/");
    }
  }, [
    navigate,
    userInfo,
    leaveApplicationId,
    leaveapplication,
    successReject,
    dispatch,
  ]);

  useEffect(() => {
    if (successUpdate) {
      setShowMessage(true);
      dispatch({
        type: HOD_LEAVE_APPLICATION_UPDATE_EMPLOYEE_RESET,
      });
      dispatch({
        type: LEAVE_APPLICATION_DETAILS_ID_RESET,
      });
      dispatch({
        type: LEAVE_APPLICATION_REJECT_RESET,
      });
    }
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  }, [dispatch, successUpdate, leaveapplication]);

  const [leaveMassage, setLeaveMassage] = useState([]);
  const [userName, setUserName] = useState([]);
  const [userEmail, setUserEmail] = useState([]);
  const [status, setStatus] = useState("");
  const [approvedStatus, setApprovedStatus] = useState(
    "  ....Your leave application have been APPROVED!"
  );

  useEffect(() => {
    if (status === "Approved") {
      setApprovedStatus("   ....Your leave application have been APPROVED!");
    } else if (status === "Rejected") {
      setApprovedStatus("   ....Your leave application have been REJECTED!");
    }
  }, [status]);

  useEffect(() => {
    setLeaveMassage([
      `Email -    ${userEmail}`,
      `leave Type -   ${leaveType}`,
      `No Of Days -    ${noOfDays}`,
      `From Date -     ${fromDate}`,
      `End Date -    ${toDate}`,
      `Reason For Leave -    ${reasonForLeave}`,
      `${approvedStatus}`,
    ]);
  }, [
    userInfo,
    leaveType,
    noOfDays,
    fromDate,
    toDate,
    reasonForLeave,
    datas,
    approvedStatus,
    userEmail,
  ]);

  const updateMyLeaveHandler = (e) => {
    e.preventDefault(e);
    dispatch(
      approveEmployeeLeaveApplicationId({
        _id: leaveApplicationId,
      })
    );

    emailjs
      .sendForm(
        "service_v4rxd7i",
        "template_7lx11g7",
        form.current,
        "nsrAhgU-pHN9kQRIs"
      )
      .then(
        (result) => console.log(result.text),
        (error) => console.log(error.text)
      );
  };

  const handleReject = (e) => {
    e.preventDefault(e);
    dispatch(
      rejectEmployeeLeaveApplicationId({
        _id: leaveApplicationId,
      })
    );

    emailjs
      .sendForm(
        "service_v4rxd7i",
        "template_7lx11g7",
        form.current,
        "nsrAhgU-pHN9kQRIs"
      )
      .then(
        (result) => console.log(result.text),
        (error) => console.log(error.text)
      );
  };

  return (
    <>
      <div className="dashboard-container">
        <main className="profilescreen-wrapper">
          <div className="dashboard-body">
            <div className="allLeave-title mb-4">
              <h3>Update Leave Request</h3>
            </div>
            {errorUpdate && (
              <Message variant="danger" className="error-border" dismissible>
                {errorUpdate}
              </Message>
            )}
            {errorReject && (
              <Message variant="danger" className="error-border" dismissible>
                {errorReject}
              </Message>
            )}
            {showMessage && (
              <Message variant="success" className="success-border">
                {" "}
                <p className="success-color">
                  Leave Approved successfully!
                </p>{" "}
              </Message>
            )}
            {loading && <Loader />}
            <Form
              ref={form}
              onSubmit={updateMyLeaveHandler}
              className="form-shadow">
              <Form.Row>
                <Form.Group as={Col} controlId="leaveType">
                  <Form.Label>Leave Type</Form.Label>
                  <Form.Control
                    type="text"
                    value={leaveType}
                    onChange={(e) => setLeaveType(e.target.value)}
                    disabled></Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId="noOfDays">
                  <Form.Label>No Of Days</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="0"
                    value={noOfDays}
                    onChange={(e) => setNoOfDays(e.target.value)}
                    disabled></Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId="leaveBalance">
                  <Form.Label>Leave Balance</Form.Label>
                  <Form.Control
                    type="number"
                    value={leaveBalance}
                    disabled></Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="startDate">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={fromDate}
                    onChange={(e) => setLeaveStartDate(e.target.value)}
                    disabled></Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId="endDate">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={toDate}
                    onChange={(e) => setLeaveEndDate(e.target.value)}
                    disabled></Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Group controlId="description">
                <Form.Label>Detailed Reason For Leave Application</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  style={{ backgroundColor: "var(--input-field-color)" }}
                  value={reasonForLeave}
                  onChange={(e) => setLeaveDescription(e.target.value)}
                  disabled
                />
              </Form.Group>
              <div style={{ display: "none" }}>
                <input name="to_name" id="to_name" defaultValue={userName} />
                <input name="email" id="email" defaultValue={userEmail} />

                <input
                  name="from_name"
                  id="from_name"
                  defaultValue={userInfo?.firstname + " " + userInfo?.lastname}
                />

                <Form.Group>
                  <Form.Control
                    as="textarea"
                    name="message"
                    id="message"
                    rows={4}
                    placeholder="Enter Your Reason"
                    style={{
                      backgroundColor: "var(--input-field-color)",
                    }}
                    defaultValue={leaveMassage}
                  />
                </Form.Group>
              </div>
              {approved ? (
                <Link
                  to="/mydepartmentapplications"
                  className="btn btn-light my-3 go-back-btn">
                  APPROVED || Go Back
                </Link>
              ) : rejected ? (
                <Link
                  to="/mydepartmentapplications"
                  className="btn btn-light my-3 go-back-btn">
                  REJECTED || Go Back
                </Link>
              ) : (
                <>
                  <Button
                    className="applyleave-btn "
                    type="submit"
                    variant="primary"
                    disabled={loadingApprove && true}
                    value="Update">
                    {loadingApprove ? "Approving..." : "Approve"}
                  </Button>
                  <Button className="mb-2 rejectleave-btn" onClick={handleShow}>
                    Reject
                  </Button>
                  <Modal show={show} onHide={handleClose}>
                    <div className="not-eligible">
                      <div className="not-eligible-container">
                        <ModalHeader
                          setLgShow={setShow}
                          icon={<GoBriefcase size={30} />}
                          title={"Leave"}
                          subtitle={"Reject Leave"}
                        />
                        <Modal.Body>
                          <i className="fas fa-exclamation-triangle fa-2x pb-3"></i>
                          <br />
                          <p>
                            <h3>Are you sure you want to Reject!</h3>
                          </p>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            className="mb-2 rejectleave-btn"
                            style={{
                              backgroundColor: "#e2522e",
                              borderRadius: 50,
                            }}
                            onClick={handleReject}>
                            Reject
                          </Button>
                        </Modal.Footer>
                      </div>
                    </div>
                  </Modal>
                </>
              )}
            </Form>
          </div>
          <Footer />
        </main>
      </div>
    </>
  );
};

export default ApproveLeaveScreen;
