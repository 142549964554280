export const LAPTOP_APPLICATION_CREATE_REQUEST = 'LAPTOP_APPLICATION_CREATE_REQUEST';
export const LAPTOP_APPLICATION_CREATE_SUCCESS = 'LAPTOP_APPLICATION_CREATE_SUCCESS';
export const LAPTOP_APPLICATION_CREATE_FAIL = 'LAPTOP_APPLICATION_CREATE_FAIL';
export const LAPTOP_APPLICATION_CREATE_RESET = 'LAPTOP_APPLICATION_CREATE_RESET';

export const HOD_LAPTOP_DETAILS_REQUEST = 'HOD_LAPTOP_DETAILS_REQUEST';
export const HOD_LAPTOP_DETAILS_SUCCESS = 'HOD_LAPTOP_DETAILS_SUCCESS';
export const HOD_LAPTOP_DETAILS_FAIL = 'HOD_LAPTOP_DETAILS_FAIL';
export const HOD_LAPTOP_DETAILS_RESET = 'HOD_LAPTOP_DETAILS_RESET';

export const LAPTOP_APPLICATION_DETAILS_REQUEST = 'LAPTOP_APPLICATION_DETAILS_REQUEST';
export const LAPTOP_APPLICATION_DETAILS_SUCCESS = 'LAPTOP_APPLICATION_DETAILS_SUCCESS';
export const LAPTOP_APPLICATION_DETAILS_FAIL = 'LAPTOP_APPLICATION_DETAILS_FAIL';
export const LAPTOP_APPLICATION_DETAILS_RESET = 'LAPTOP_APPLICATION_DETAILS_RESET';


export const LAPTOP_REQUEST_DETAILS_ID_HOD_REQUEST = 'LAPTOP_REQUEST_DETAILS_ID_HOD_REQUEST';
export const LAPTOP_REQUEST_DETAILS_ID_HOD_SUCCESS = 'LAPTOP_REQUEST_DETAILS_ID_HOD_SUCCESS';
export const LAPTOP_REQUEST_DETAILS_ID_HOD_FAIL = 'LAPTOP_REQUEST_DETAILS_ID_HOD_FAIL';
export const LAPTOP_REQUEST_DETAILS_ID_HOD_RESET = 'LAPTOP_REQUEST_DETAILS_ID_HOD_RESET';

export const APPROVE_HOD_LAPTOP_ID_REQUEST = 'APPROVE_HOD_LAPTOP_ID_REQUEST';
export const APPROVE_HOD_LAPTOP_ID_SUCCESS = 'APPROVE_HOD_LAPTOP_ID_SUCCESS';
export const APPROVE_HOD_LAPTOP_ID_FAIL = 'APPROVE_HOD_LAPTOP_ID_FAIL';
export const APPROVE_HOD_LAPTOP_ID_RESET = 'APPROVE_HOD_LAPTOP_ID_RESET';

export const ADMIN_APPROVE_HOD_LAPTOP_ID_REQUEST = 'ADMIN_APPROVE_HOD_LAPTOP_ID_REQUEST';
export const ADMIN_APPROVE_HOD_LAPTOP_ID_SUCCESS = 'ADMIN_APPROVE_HOD_LAPTOP_ID_SUCCESS';
export const ADMIN_APPROVE_HOD_LAPTOP_ID_FAIL = 'ADMIN_APPROVE_HOD_LAPTOP_ID_FAIL';
export const ADMIN_APPROVE_HOD_LAPTOP_ID_RESET = 'ADMIN_APPROVE_HOD_LAPTOP_ID_RESET';

export const CEO_APPROVE_HOD_LAPTOP_ID_REQUEST = 'CEO_APPROVE_HOD_LAPTOP_ID_REQUEST';
export const CEO_APPROVE_HOD_LAPTOP_ID_SUCCESS = 'CEO_APPROVE_HOD_LAPTOP_ID_SUCCESS';
export const CEO_APPROVE_HOD_LAPTOP_ID_FAIL = 'CEO_APPROVE_HOD_LAPTOP_ID_FAIL';
export const CEO_APPROVE_HOD_LAPTOP_ID_RESET = 'CEO_APPROVE_HOD_LAPTOP_ID_RESET';


export const REJECT_HOD_LAPTOP_ID_REQUEST = 'REJECT_HOD_LAPTOP_ID_REQUEST';
export const REJECT_HOD_LAPTOP_ID_SUCCESS = 'REJECT_HOD_LAPTOP_ID_SUCCESS';
export const REJECT_HOD_LAPTOP_ID_FAIL = 'REJECT_HOD_LAPTOP_ID_FAIL';
export const REJECT_HOD_LAPTOP_ID_RESET = 'REJECT_HOD_LAPTOP_ID_RESET';


export const CREATE_LAPTOP_INFO_REQUEST = 'CREATE_LAPTOP_INFO_REQUEST';
export const CREATE_LAPTOP_INFO_SUCCESS = 'CREATE_LAPTOP_INFO_SUCCESS';
export const CREATE_LAPTOP_INFO_FAIL = 'CREATE_LAPTOP_INFO_FAIL';
export const CREATE_LAPTOP_INFO_RESET = 'CREATE_LAPTOP_INFO_RESET';

export const GET_LAPTOP_INFO_DETAILS_REQUEST = 'GET_LAPTOP_INFO_DETAILS_REQUEST';
export const GET_LAPTOP_INFO_DETAILS_SUCCESS = 'GET_LAPTOP_INFO_DETAILS_SUCCESS';
export const GET_LAPTOP_INFO_DETAILS_FAIL = 'GET_LAPTOP_INFO_DETAILS_FAIL';
export const GET_LAPTOP_INFO_DETAILS_RESET = 'GET_LAPTOP_INFO_DETAILS_RESET';

export const LAPTOP_INFO_DETAILS_ID_REQUEST = 'LAPTOP_INFO_DETAILS_ID_REQUEST';
export const LAPTOP_INFO_DETAILS_ID_SUCCESS = 'LAPTOP_INFO_DETAILS_ID_SUCCESS';
export const LAPTOP_INFO_DETAILS_ID_FAIL = 'LAPTOP_INFO_DETAILS_ID_FAIL';
export const LAPTOP_INFO_DETAILS_ID_RESET = 'LAPTOP_INFO_DETAILS_ID_RESET';

export const LAPTOP_INFO_UPDATE_ID_REQUEST = 'LAPTOP_INFO_UPDATE_ID_REQUEST';
export const LAPTOP_INFO_UPDATE_ID_SUCCESS = 'LAPTOP_INFO_UPDATE_ID_SUCCESS';
export const LAPTOP_INFO_UPDATE_ID_FAIL = 'LAPTOP_INFO_UPDATE_ID_FAIL';
export const LAPTOP_INFO_UPDATE_ID_RESET = 'LAPTOP_INFO_UPDATE_ID_RESET';

