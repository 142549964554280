import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { jobPostAction } from "../../actions/CreateJobPostAction";
import Message from "../../components/Message";
import { ADMIN_CREATE_JOB_POST_RESET } from "../../constants/createJobPostContants";
import Loader from "../../components/Loader";
import { baseUrl } from "../../shared/baseUrl";
import axios from "axios";
import {
  NoRecordFound,
  SearchComponent,
  TableFetch,
} from "../../components/TableOptions";
import Pagination from "../../components/Pagination";
import ModalHeader from "../../components/ModalHeader";

import { MdWorkHistory } from "react-icons/md";

const CreatePostJob = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [displayData, setDisplayData] = useState([]);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [result, setResult] = useState("");
  // const [filtered, setFilterd] = useState([]);
  // const [result, setResult] = useState("");

  // const [pageNumber, setPageNumber] = useState(0);
  // const [entriesPerPage, setEntriesPerPage] = useState(() => {
  //   return localStorage.getItem("rowsPerPage") || "10";
  // });

  // const usersPerPage = parseInt(entriesPerPage);
  // const pagesVisited = pageNumber * usersPerPage;
  // const pageCount = Math.ceil(data?.length / usersPerPage);
  // const changePage = ({ selected }) => {
  //   setPageNumber(selected);
  // };

  const [jobTitle, setJobTitle] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const [qualifications, setQualifications] = useState("");
  const [jobOverview, setJobOverview] = useState("");
  const [responsilities, setResponsilities] = useState("");
  const [benefits, setBenefits] = useState("");
  const [err, setErr] = useState("");
  const [filtered, setFiltered] = useState([]);

  const admincreateJobPost = useSelector((state) => state.admincreateJobPost);
  const {
    success: successCreate,
    error: errorCreate,
    loading: loadingPost,
  } = admincreateJobPost;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await axios.get(baseUrl + "/api/v1/career");
        setData(res.data.careers);
        // setSent(res.data.careers);
        setFiltered(res.data.careers);
        setLoading(false);
      } catch (error) {
        setErr(error.message);
        console.log(error.message);
      }
    };
    fetchData();
  }, [userInfo, successCreate]);

  const onChange = (e) => {
    setResult(e.target.value);
  };

  // useEffect(() => {
  //   const results = data?.filter((data) =>
  //     data?.jobTitle?.toLowerCase().includes(result)
  //   );
  //   setData(results);
  // }, [data, result]);

  useEffect(() => {
    const results = filtered?.filter((data) =>
      data.jobTitle.toLowerCase().includes(result.toLowerCase())
    );
    setData(results);
  }, [filtered, result]);

  useEffect(() => {
    if (successCreate) {
      setShowMessage(true);
      setShow(false);
      setJobTitle("");
      setJobLocation("");
      setQualifications("");
      setJobOverview("");
      setResponsilities("");
      setBenefits("");
      setTimeout(() => {
        setShowMessage(false);
      }, 5000);
      dispatch({
        type: ADMIN_CREATE_JOB_POST_RESET,
      });
    }
  }, [successCreate, dispatch]);

  const handleShow = () => {
    setShow(true);
  };

  const submitHandler = (e) => {
    e.preventDefault(e);
    dispatch(
      jobPostAction(
        jobTitle,
        jobLocation,
        qualifications,
        jobOverview,
        responsilities,
        benefits
      )
    );
  };

  const [entriesPerPage, setEntriesPerPage] = useState(() => {
    return localStorage.getItem("rowsPerPage") || "8";
  });

  return (
    <div id="reports">
      <h5 className="page-title">Current Jobs</h5>
      <div className="nav-tabs">
        <li className="active" onClick={handleShow}>
          Create New Job
        </li>
      </div>
      <div className="half-background">
        <>
          <SearchComponent
            sortData={data}
            entriesPerPage={entriesPerPage}
            setEntriesPerPage={setEntriesPerPage}
            placeholder={"Current Jobs"}
            value={result}
            onChange={onChange}
          />
          <section className="md-ui component-data-table">
            <div className="main-table-wrapper">
              {err && (
                <Message variant="danger" className="error-border">
                  {err}
                </Message>
              )}
              {showMessage && (
                <Message variant="success" className="success-border">
                  <p className="success-color">
                    Job Post Created successfully!
                  </p>
                </Message>
              )}
              {loading && <Loader />}
              <table className="main-table-content">
                <thead className="data-table-header">
                  <tr className="data-table-row">
                    <th>Job Title</th>
                    <th>Job Location</th>
                    <th>Job Overview</th>
                    <th>Responsilities</th>
                    <th>Qualifications</th>
                    <th>Benefits</th>
                    <th>Update & Delete</th>
                  </tr>
                </thead>
                <tbody className="data-table-content">
                  {loading ? (
                    <TableFetch colSpan={7} />
                  ) : displayData?.length === 0 || displayData == null ? (
                    <NoRecordFound colSpan={7} />
                  ) : (
                    displayData?.map((user) => (
                      <tr key={user._id}>
                        <td>{user.jobTitle}</td>
                        <td>{user.jobLocation}</td>
                        <td>{user.qualifications}</td>
                        <td>{user.jobOverview}</td>
                        <td>{user.responsilities}</td>
                        <td>{user.benefits}</td>
                        <td>
                          <Link
                            to={`/updatajobpost/${user?._id}`}
                            exact="true"
                            className="btn btn-info"
                            style={{
                              background: "#E2522E",
                              boxShadow: "none",
                            }}>
                            Update
                          </Link>
                          {/* </td> */}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <>
              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="myleave-modal">
                <ModalHeader
                  setLgShow={setShow}
                  icon={<MdWorkHistory size={30} />}
                  title={"Job"}
                  subtitle={"Job Opennings"}
                />

                <Modal.Body>
                  {errorCreate && (
                    <Message variant="danger">{errorCreate}</Message>
                  )}

                  {successCreate && (
                    <Message
                      variant="danger"
                      className="error-border"
                      dismissible>
                      {successCreate}
                    </Message>
                  )}
                  <Form onSubmit={submitHandler}>
                    <Form.Row>
                      <Form.Group className="col-md-12" controlId="firstname">
                        <Form.Label>Job Title</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Job Title"
                          value={jobTitle}
                          onChange={(e) =>
                            setJobTitle(e.target.value)
                          }></Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group
                        className="col-md-12"
                        controlId="formGridDepartment">
                        <Form.Label> Job Location </Form.Label>
                        <Form.Control
                          as="select"
                          size="sm"
                          custom
                          value={jobLocation}
                          onChange={(e) => setJobLocation(e.target.value)}>
                          <option value="">Select...</option>
                          <option value="Lagos">Lagos</option>
                          <option value="Enugu">Enugu</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Job Overview</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={jobOverview}
                        onChange={(e) => setJobOverview(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Responsilities</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={responsilities}
                        onChange={(e) => setResponsilities(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Qualifications </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={qualifications}
                        onChange={(e) => setQualifications(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Benefits</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={benefits}
                        onChange={(e) => setBenefits(e.target.value)}
                      />
                    </Form.Group>

                    <Button
                      className="applyleave-btn w-100"
                      variant="primary"
                      disabled={loadingPost && true}
                      type="submit"
                      value="Register">
                      {loadingPost ? "Posting..." : "Post"}
                    </Button>
                  </Form>
                </Modal.Body>
              </Modal>
            </>
            <footer className="main-table-footer">
              <Pagination
                setDisplayData={setDisplayData}
                data={data}
                entriesPerPage={entriesPerPage}
                Total={"Support Service"}
              />
            </footer>
          </section>
        </>
      </div>
    </div>
  );
};

export default CreatePostJob;
