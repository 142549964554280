import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Modal, Form, Col } from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createAgentLeaveApplication,
  getAgentApplication,
} from "../../actions/agentLeaveActions";
import { baseUrl } from "../../shared/baseUrl";
import Message from "../../components/Message";
import { AGENT_LEAVE_APPLICATION_CREATE_RESET } from "../../constants/agentLeaveContants";
import {
  NoRecordFound,
  SearchComponent,
  TableFetch,
} from "../../components/TableOptions";
import Pagination from "../../components/Pagination";
import Loader from "../../components/Loader";
import ModalHeader from "../../components/ModalHeader";
import { GoBriefcase } from "react-icons/go";

const AgentLeaveApplicationScreen = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [leaveType, setLeaveType] = useState("");
  const [fromDate, setLeaveStartDate] = useState("");
  const [toDate, setLeaveEndDate] = useState("");
  const [noOfDays, setNoOfDays] = useState(0);
  const [supervisor, setSupervisor] = useState("");
  const [reasonForLeave, setLeaveDescription] = useState("");
  const [leaveStatus] = useState("");
  const [teamLeadss, setTeamLeadss] = useState([]);
  const [showMessage, setShowMessage] = useState(false);

  const dispatch = useDispatch();

  const [entriesPerPage, setEntriesPerPage] = useState(() => {
    return localStorage.getItem("rowsPerPage") || "8";
  });
  const [displayData, setDisplayData] = useState([]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const agentLeaveApp = useSelector((state) => state.agentLeaveApp);
  const { data, loading: loadingA } = agentLeaveApp;

  const updateLeaveApp = useSelector((state) => state.updateLeaveApp);
  const { success: successUpdate } = updateLeaveApp;

  const createAgentLeave = useSelector((state) => state.createAgentLeave);
  const {
    success: successCreate,
    error: errorCreate,
    loading: loadingCreate,
  } = createAgentLeave;

  const teamLeadList = useSelector((state) => state.teamLeadList);
  const { loading, teamLeads } = teamLeadList;

  useEffect(() => {
    if (userInfo) {
      if (successCreate) {
        handleClose();
      }
    } else {
      navigate("/");
    }
  }, [dispatch, navigate, userInfo, successCreate]);

  useEffect(() => {
    if (userInfo && userInfo.role === "Agent") {
      dispatch(getAgentApplication());
      // dispatch(listTeamLeads())
      // setTeamLeadss(teamLeads)

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      async function fetchData() {
        const request = await axios.get(
          baseUrl + "/api/v1/employees/teamleads",
          config
        );
        setTeamLeadss(request.data.data);
        return request;
      }
      fetchData();
    } else {
      navigate("/");
    }
  }, [
    dispatch,
    navigate,
    successCreate,
    successUpdate,
    userInfo,
    loading,
    teamLeads,
  ]);

  useEffect(() => {
    if (successCreate) {
      setShowMessage(true);
      dispatch({
        type: AGENT_LEAVE_APPLICATION_CREATE_RESET,
      });
    }
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  }, [dispatch, successCreate]);

  const createsubmitHandler = (e) => {
    e.preventDefault(e);
    dispatch(
      createAgentLeaveApplication(
        leaveType,
        fromDate,
        toDate,
        noOfDays,
        supervisor,
        reasonForLeave,
        leaveStatus
      )
    );
  };

  // --- Leave Application Eligibilty
  const dateJoined = new Date(userInfo.dateOfJoining);
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate - dateJoined);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const daysLeft = 90 - diffDays;

  return (
    <div id="reports">
      <h5 className="page-title">My Leave Applications</h5>
      <div className="nav-tabs">
        <li className="active" onClick={handleShow}>
          Apply Leave
        </li>
      </div>
      <div className="half-background">
        <SearchComponent
          sortData={data}
          entriesPerPage={entriesPerPage}
          setEntriesPerPage={setEntriesPerPage}
          placeholder={"Agent Leave"}
        />

        <section className="md-ui component-data-table">
          <>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              className="myleave-modal">
              <ModalHeader
                setLgShow={setShow}
                icon={<GoBriefcase size={30} />}
                title={"Leave"}
                subtitle={"Apply for Leave"}
              />
              <Modal.Body>
                {errorCreate && (
                  <Message
                    variant="danger"
                    className="error-border"
                    dismissible>
                    {errorCreate}
                  </Message>
                )}
                {diffDays > 90 ? (
                  <Form onSubmit={createsubmitHandler}>
                    <Form.Group controlId="leaveType">
                      <Form.Label>Leave Type</Form.Label>
                      <Form.Control
                        as="select"
                        custom
                        size="sm"
                        value={leaveType}
                        onChange={(e) => setLeaveType(e.target.value)}>
                        <option value="">Select Leave Type...</option>
                        <option value="Sick-Leave">Sick Leave</option>
                        <option value="Paid-Leave">Paid Leave</option>
                        <option value="Casual-Leave">Casual Leave</option>
                        <option value="Compassionate-Leave">
                          Compassionate-Leave
                        </option>
                        <option value="Maternity-Leave">Maternity Leave</option>
                        <option value="Other">Other</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Row>
                      <Form.Group as={Col} controlId="reportsTo">
                        <Form.Label>Reports To</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          size="sm"
                          value={supervisor}
                          onChange={(e) => setSupervisor(e.target.value)}>
                          <option>Select Team Lead...</option>
                          {teamLeadss?.map((teamLead) => (
                            <option key={teamLead?._id} value={teamLead?._id}>
                              {teamLead?.firstname} {teamLead?.lastname}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} controlId="noOfDays">
                        <Form.Label>No Of Days</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="0"
                          value={noOfDays}
                          onChange={(e) =>
                            setNoOfDays(e.target.value)
                          }></Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="startDate">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Start Date"
                          value={fromDate}
                          onChange={(e) =>
                            setLeaveStartDate(e.target.value)
                          }></Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} controlId="endDate">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="End Date"
                          value={toDate}
                          onChange={(e) =>
                            setLeaveEndDate(e.target.value)
                          }></Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Group controlId="description">
                      <Form.Label>
                        Detailed Reason For Leave Application
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        style={{
                          backgroundColor: "var(--input-field-color)",
                        }}
                        value={reasonForLeave}
                        onChange={(e) => setLeaveDescription(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="status">
                      <Form.Label>Status</Form.Label>
                      <Form.Control placeholder="Pending" disabled />
                    </Form.Group>
                    <hr />
                    <Button
                      className="applyleave-btn mb-2 mr-3"
                      variant="primary"
                      disabled={successUpdate && true}
                      type="Apply"
                      value="Register">
                      {loadingCreate ? "Applying..." : "Apply"}
                    </Button>
                    <Button
                      className="mb-2"
                      variant="secondary"
                      onClick={handleClose}>
                      Close
                    </Button>
                  </Form>
                ) : (
                  <div className="not-eligible">
                    <div className="not-eligible-container">
                      <i className="fas fa-exclamation-triangle fa-2x pb-3"></i>
                      <h2>Not eligible</h2>
                      <code>You have {daysLeft} days left</code>
                    </div>
                    <hr />
                    <Button
                      className="mb-2"
                      variant="secondary"
                      onClick={handleClose}>
                      Close
                    </Button>
                  </div>
                )}
              </Modal.Body>
            </Modal>
          </>
          {showMessage && (
            <Message variant="success" className="success-border">
              {" "}
              <p className="success-color">
                Leave Application Created successfully!
              </p>{" "}
            </Message>
          )}
          {loadingA && <Loader />}
          <div className="main-table-wrapper">
            <table className="main-table-content">
              <thead className="data-table-header">
                <tr className="data-table-row">
                  <th>Leave Type</th>
                  <th>Leave Type</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Detailed Reason</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {loadingA ? (
                  <TableFetch colSpan={8} />
                ) : displayData?.length === 0 || displayData == null ? (
                  <NoRecordFound colSpan={8} />
                ) : (
                  displayData?.map((user) => (
                    <tr key={user?._id}>
                      <td>{user?.leaveType}</td>
                      <td>{user?.noOfDays}</td>
                      <td>{moment(user?.fromDate)?.format("DD-MM-YYYY")}</td>
                      <td>{moment(user?.toDate)?.format("DD-MM-YYYY")}</td>
                      <td>{user?.reasonForLeave}</td>
                      <td>{user?.leaveStatus}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </section>
        <footer className="main-table-footer">
          <Pagination
            setDisplayData={setDisplayData}
            data={data}
            entriesPerPage={entriesPerPage}
            Total={"Agent Leave Applications"}
          />
        </footer>
      </div>
    </div>
  );
};

export default AgentLeaveApplicationScreen;
