
export const MESSAGE_CREATE_REQUEST = 'MESSAGE_CREATE_REQUEST';
export const MESSAGE_CREATE_SUCCESS = 'MESSAGE_CREATE_SUCCESS';
export const MESSAGE_CREATE_FAIL = 'MESSAGE_CREATE_FAIL';
export const MESSAGE_CREATE_RESET = 'MESSAGE_CREATE_RESET';

export const MESSAGE_DETAILS_REQUEST = 'MESSAGE_DETAILS_REQUEST';
export const MESSAGE_DETAILS_SUCCESS = 'MESSAGE_DETAILS_SUCCESS';
export const MESSAGE_DETAILS_FAIL = 'MESSAGE_DETAILS_FAIL';
export const MESSAGE_DETAILS_RESET = 'MESSAGE_DETAILS_RESET';

export const EMPLOYEE_MESSAGE_REQUEST = 'EMPLOYEE_MESSAGE_REQUEST';
export const EMPLOYEE_MESSAGE_SUCCESS = 'EMPLOYEE_MESSAGE_SUCCESS';
export const EMPLOYEE_MESSAGE_FAIL = 'EMPLOYEE_MESSAGE_FAIL';
export const EMPLOYEE_MESSAGE_RESET = 'EMPLOYEE_MESSAGE_RESET';

export const MESSAGE_RESPONSE_REQUEST = 'MESSAGE_RESPONSE_REQUEST';
export const MESSAGE_RESPONSE_SUCCESS = 'MESSAGE_RESPONSE_SUCCESS';
export const MESSAGE_RESPONSE_FAIL = 'MESSAGE_RESPONSE_FAIL';
export const MESSAGE_RESPONSE_RESET = 'MESSAGE_RESPONSE_RESET';

export const ALL_MESSAGE_DETAILS_REQUEST = 'ALL_MESSAGE_DETAILS_REQUEST';
export const ALL_MESSAGE_DETAILS_SUCCESS = 'ALL_MESSAGE_DETAILS_SUCCESS';
export const ALL_MESSAGE_DETAILS_FAIL = 'ALL_MESSAGE_DETAILS_FAIL';
export const ALL_MESSAGE_DETAILS_RESET = 'ALL_MESSAGE_DETAILS_RESET';

export const SINGLE_MESSAGE_REQUEST = 'SINGLE_MESSAGE_REQUEST';
export const SINGLE_MESSAGE_SUCCESS = 'SINGLE_MESSAGE_SUCCESS';
export const SINGLE_MESSAGE_FAIL = 'SINGLE_MESSAGE_FAIL';
export const SINGLE_MESSAGE_RESET = 'SINGLE_MESSAGE_RESET';

export const CREATE_RESPONSE_REQUEST = 'CREATE_RESPONSE_REQUEST';
export const CREATE_RESPONSE_SUCCESS = 'CREATE_RESPONSE_SUCCESS';
export const CREATE_RESPONSE_FAIL = 'CREATE_RESPONSE_FAIL';
export const CREATE_RESPONSE_RESET = 'CREATE_RESPONSE_RESET';

export const MESSAGE_RESPONSE_DETAILS_REQUEST = 'MESSAGE_RESPONSE_DETAILS_REQUEST';
export const MESSAGE_RESPONSE_DETAILS_SUCCESS = 'MESSAGE_RESPONSE_DETAILS_SUCCESS';
export const MESSAGE_RESPONSE_DETAILS_FAIL = 'MESSAGE_RESPONSE_DETAILS_FAIL';
export const MESSAGE_RESPONSE_DETAILS_RESET = 'MESSAGE_RESPONSE_DETAILS_RESET';