import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Form, Col } from "react-bootstrap";
import moment from "moment";
import {
  createLaptopApplication,
  getHodLaptopRequest,
} from "../../actions/LaptopApplicationAction";
import { LAPTOP_APPLICATION_CREATE_RESET } from "../../constants/laptopApplicationContants";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import {
  NoRecordFound,
  SearchComponent,
  TableFetch,
} from "../../components/TableOptions";
import Pagination from "../../components/Pagination";
import { useNavigate } from "react-router-dom";
import { BsLaptop } from "react-icons/bs";
import ModalHeader from "../../components/ModalHeader";

const LaptopApplication = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const [data, setData] = useState([]);

  const [showUpdate, setShowUpdate] = useState(false);
  const handleCloseUp = () => setShowUpdate(false);
  // const handleShowUp = () => {
  //   setShowUpdate(true);
  // }

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const createLaptopApp = useSelector((state) => state.createLaptopApp);

  const {
    success: successCreate,
    loading: loadingCreate,
    error: errorCreate,
  } = createLaptopApp;

  const getHodLaptopDetails = useSelector((state) => state.getHodLaptopDetails);
  const { data: datas, error, loading } = getHodLaptopDetails;

  const [employeeEmail, setEmployeeEmail] = useState("");
  const [laptopType, setLaptopType] = useState("");
  const [laptopRamSize, setLaptopRamSize] = useState("");
  const [reason, setReason] = useState("");
  const [accessories, setAccessories] = useState("");
  // const [indexPc, setIndexPc] = useState(0)
  // const [Pc, SetPc] = useState('')
  const [showMessage, setShowMessage] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(() => {
    return localStorage.getItem("rowsPerPage") || "8";
  });

  const [displayData, setDisplayData] = useState([]);
  const [filtered, setFilterd] = useState([]);
  const [result, setResult] = useState("");

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else {
      if (successCreate) {
        handleClose();
      } else {
        dispatch(getHodLaptopRequest());
      }
    }
  }, [dispatch, navigate, successCreate, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault(e);
    dispatch(
      createLaptopApplication(
        laptopType,
        laptopRamSize,
        accessories,
        employeeEmail,
        reason
      )
    );
  };

  useEffect(() => {
    localStorage.setItem("rowsPerPage", entriesPerPage);
  }, [entriesPerPage]);

  useEffect(() => {
    if (successCreate) {
      setShowMessage(true);
      dispatch({
        type: LAPTOP_APPLICATION_CREATE_RESET,
      });
    }
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  }, [dispatch, successCreate, data]);

  useEffect(() => {
    setFilterd(datas);
    localStorage.setItem("rowsPerPage", entriesPerPage);
  }, [datas, entriesPerPage]);

  useEffect(() => {
    const results = filtered?.filter((item) =>
      item.employeeEmail?.toLowerCase().includes(result.toLowerCase())
    );
    setData(results);
  }, [result, filtered, datas?.employeeEmail]);

  const onChange = (e) => {
    setResult(e.target.value);
  };

  return (
    <div id="reports">
      <h5 className="page-title">{userInfo?.firstname}'s Laptop Details</h5>
      <div className="nav-tabs">
        <li className="active" onClick={handleShow}>
          Request Laptop
        </li>
      </div>
      <div className="half-background">
        <SearchComponent
          sortData={data}
          entriesPerPage={entriesPerPage}
          setEntriesPerPage={setEntriesPerPage}
          placeholder={"Laptop Details"}
          value={result}
          onChange={onChange}
        />
        <section className="md-ui component-data-table">
          <div className="main-table-wrapper">
            <>
              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="myleave-modal"
                centered>
                <ModalHeader
                  setLgShow={setShow}
                  icon={<BsLaptop size={30} />}
                  title={"Laptop"}
                  subtitle={"Request for Laptop"}
                />
                <Modal.Body>
                  {errorCreate && (
                    <Message
                      variant="danger"
                      className="error-border"
                      dismissible>
                      {errorCreate}
                    </Message>
                  )}
                  <Form onSubmit={submitHandler}>
                    <Form.Row>
                      <Form.Group as={Col} controlId="EmployeeEmail">
                        <Form.Label>Employee Email</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Email"
                          value={employeeEmail}
                          onChange={(e) => setEmployeeEmail(e.target.value)}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group controlId="laptopType">
                        <Form.Label>Laptop Type</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          size="sm"
                          value={laptopType}
                          onChange={(e) => setLaptopType(e.target.value)}>
                          <option value="">Select Laptop Type...</option>
                          <option value="Apple">Apple </option>
                          <option value="Hp">Hp</option>
                          <option value="Dell"> Dell</option>
                          <option value="Lenovo">Lenovo</option>
                          <option value="Asus">Asus</option>
                          <option value="Asus">Aser</option>
                          <option value="Toshiba">Toshiba</option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group as={Col} controlId="laptopRamSize">
                        <Form.Label>Laptop Ram Size</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          size="sm"
                          value={laptopRamSize}
                          onChange={(e) => setLaptopRamSize(e.target.value)}>
                          <option value="">Select Laptop Type...</option>
                          <option value="4gb ">4gb </option>
                          <option value="6gb ">6gb </option>
                          <option value="8gb">8gb</option>
                          <option value="12gb"> 12gb</option>
                          <option value="16gb"> 16gb</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Group controlId="laptopRamSize">
                      <Form.Label>Accessory</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Head Phone  Charger  Battery  Mouse  Keybroad  "
                        value={accessories}
                        onChange={(e) => setAccessories(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="description">
                      <Form.Label>
                        Detailed Reason For Laptop Request
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter Your Reason"
                        style={{ backgroundColor: "#fff" }}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </Form.Group>
                    <Button
                      className="applyleave-btn mt-3"
                      variant="primary"
                      disabled={loadingCreate && true}
                      type="submit"
                      value="Request">
                      {loadingCreate ? "Requesting..." : "Request"}
                    </Button>
                  </Form>
                </Modal.Body>
              </Modal>
            </>
            {error && (
              <Message variant="danger" className="error-border" dismissible>
                {error}
              </Message>
            )}
            {showMessage && (
              <Message variant="success" className="success-border">
                <p className="success-color">
                  Your laptop Request is successful!
                </p>
              </Message>
            )}
            {loading && <Loader />}
            <table className="main-table-content">
              <thead className="data-table-header">
                <tr className="data-table-row">
                  <th>Laptop Type</th>
                  <th>Ram Size</th>
                  <th>Accessories</th>
                  <th>Employee Email</th>
                  <th>Reason</th>
                  <th>Date</th>
                  <th>HOD IT Approval</th>
                  <th>Admin Approval</th>
                  <th>Final Approval</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <TableFetch colSpan={10} />
                ) : displayData?.length === 0 || displayData == null ? (
                  <NoRecordFound colSpan={10} />
                ) : (
                  displayData?.map((user) => (
                    <tr key={user?._id}>
                      <td>{user?.laptopType}</td>
                      <td>{user?.laptopRamSize}</td>
                      <td>{user?.accessories}</td>
                      <td>{user?.employeeEmail}</td>
                      <td>{user?.reason}</td>
                      <td>{moment(user?.createdAt).format("DD-MM-YYYY")}</td>
                      <td>
                        {user?.hodITApproval ? (
                          <i
                            className="fas fa-check-circle"
                            style={{ color: "green" }}></i>
                        ) : (
                          <i
                            className="fas fa-times-circle"
                            style={{ color: "red" }}></i>
                        )}
                      </td>
                      <td>
                        {user.adminApproval ? (
                          <i
                            className="fas fa-check-circle"
                            style={{ color: "green" }}></i>
                        ) : (
                          <i
                            className="fas fa-times-circle"
                            style={{ color: "red" }}></i>
                        )}
                      </td>
                      <td>
                        {user?.ceoApproval ? (
                          <i
                            className="fas fa-check-circle"
                            style={{ color: "green" }}></i>
                        ) : (
                          <i
                            className="fas fa-times-circle"
                            style={{ color: "red" }}></i>
                        )}
                      </td>
                      <td className="myleave-status-td">
                        {user?.hodITApproval &&
                        user?.adminApproval &&
                        user?.ceoApproval ? (
                          <p className="status-a">Approved</p>
                        ) : user?.rejectRequest ? (
                          <p className="status-r">Rejected</p>
                        ) : (
                          <p className="status-p">Pending...</p>
                        )}
                      </td>
                      {/* <td className="myleave-status-td">
                            <Link
                              exact
                              className="update-btn rounded-5"
                              style={{ background: '#E2522E', boxShadow: 'none' }} onClick={() => { setIndexPc(index); setShowUpdate(true); }}>
                              View
                            </Link>
                          </td> */}
                    </tr>
                  ))
                )}
              </tbody>
            </table>

            <Modal show={showUpdate} onHide={handleCloseUp}>
              <div className="not-eligible">
                <div className="not-eligible-container">
                  <ModalHeader
                    setLgShow={setShow}
                    icon={<BsLaptop size={30} />}
                    title={"Laptop"}
                    subtitle={"View My Laptop Request"}
                  />
                  <Modal.Body>
                    <div className="getjob-application-body">
                      <div className="alljobs-body">
                        {/* <div className="getjob-application-details">
                            <p>Laptop Type</p>
                            <p>{data[indexPc]?.laptopType}</p>
                            <p>Ram Size</p>
                            <p>{data[indexPc]?.laptopRamSize}</p>
                            <p>Accessories</p>
                            <p>{data[indexPc]?.accessories}</p>
                            <p>Employee Email</p>
                            <p>{data[indexPc]?.employeeEmail}</p>
                            <p>Reason</p>
                            <p>{data[indexPc]?.reason}</p>
                            <p> Date</p>
                            <p>{moment(data[indexPc]?.createdAt).format("DD-MM-YYYY")}</p>
                          </div> */}

                        <div className="spoken-languages">
                          <p>Laptop Status</p>
                          {/* <div className="getdata-application-details">
                              <p>HOD IT Approval</p>
                              <p>
                                {data[indexPc]?.hodITApproval ?
                                  <i className="fas fa-check-circle" style={{ color: 'green' }} />
                                  :
                                  <i className="fas fa-times-circle" style={{ color: '#e2522e' }} />
                                }
                              </p>
                              <p>Admin Approval</p>
                              <p>
                                {data[indexPc]?.adminApproval ?
                                  <i className="fas fa-check-circle" style={{ color: 'green' }} />
                                  :
                                  <i className="fas fa-times-circle" style={{ color: '#e2522e' }} />
                                }
                              </p>
                              <p>Final Approval</p>
                              <p>
                                {data[indexPc]?.ceoApproval ?
                                  <i className="fas fa-check-circle" style={{ color: 'green' }} />
                                  :
                                  <i className="fas fa-times-circle" style={{ color: '#e2522e' }} />
                                }
                              </p>
                            </div> */}
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </div>
              </div>
            </Modal>
          </div>
        </section>
        <footer className="main-table-footer">
          <Pagination
            setDisplayData={setDisplayData}
            data={data}
            entriesPerPage={entriesPerPage}
            Total={"Laptop Details"}
          />
        </footer>
      </div>
    </div>
  );
};

export default LaptopApplication;
