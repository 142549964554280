import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getPerformanceByIdAction,
  updateTeamPerformanceByIdAction,
} from "../../actions/performanceActions";
import { UPDATE_TEAM_ASSESSMENT_ID_RESET } from "../../constants/performanceConstants";
import axios from "axios";
import { baseUrl } from "../../shared/baseUrl";
import Message from "../../components/Message";
import emailjs from "emailjs-com";
import { useNavigate, useParams } from "react-router-dom";
import { SlGraph } from "react-icons/sl";
import ModalHeader from "../../components/ModalHeader";
import Loader from "../../components/Loader";

const HODReviewEmpKPI = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const performanceId = id;

  const dispatch = useDispatch();
  const form = useRef();

  // --- Modal --- //
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // --- Reason for Delete Modal --- //
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => {
    setShowDelete(false);
    setReason(false);
  };
  const handleShowDelete = () => {
    setReason(true);
    setShowDelete(true);
  };

  // --- Line Manager's State Declaration --- //
  const [factors, setFactors] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [ratingField, setRatingField] = useState([{ hodRating: "" }]);

  const [reportsToComment, setReportsToComment] = useState("");
  const [reportsToPerformanceAverage, setReportsToPerformanceAverage] =
    useState(0);
  const [performanceAverage, setPerformanceAverage] = useState(0);
  const [lineManagerPerformanceRating, setLineManagerPerformanceRating] =
    useState([]);
  const [developmentPlan, setDevelopmentPlan] = useState("");
  const [recommendations, setRecommendations] = useState([]);
  const [deleteReason, setDeleteReason] = useState("");
  const [deleteStatus, setDeleteStatus] = useState(false);

  // --- State For Other Details --- /
  const [performanceMessage, setPerfomanceMessage] = useState([]);
  const [message, setMessage] = useState(false);
  const [reason, setReason] = useState([]);
  const year = new Date().getFullYear().toString();
  // const [year, setYear] = useState(2021)

  const [reasoninput, setReasonInput] = useState("");

  // --- Convert factors & ratings to one array{object} --- //
  const performances = (factors, ratings) => {
    const assessments = [];
    for (let i = 0; i < factors.length; i++) {
      assessments.push({
        getFactor: factors[i],
        getRating: ratings[i],
      });
    }
    return assessments;
  };
  const performanceFields = performances(factors, ratings);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const getPerformanceById = useSelector((state) => state.getPerformanceById);
  const { data } = getPerformanceById;

  const updateTeamPerformanceById = useSelector(
    (state) => state.updateTeamPerformanceById
  );
  const {
    success: successUpdate,
    error: errorUpdate,
    loading,
  } = updateTeamPerformanceById;

  useEffect(() => {
    setPerfomanceMessage([
      `Perfomance-factor: ${factors},
      developmentPlan - ${developmentPlan}
      Employee's comment - ${data?.employeeComment}
      Manager-comment - ${reportsToComment}
      Manager -recommendations: ${recommendations}
      KPI-Average: ${data?.employeePerformanceAverage}%
      Manager's Average: ${reportsToPerformanceAverage}%
      Final Brought down Average: ${performanceAverage}%
      `,
    ]);
  }, [
    factors,
    developmentPlan,
    recommendations,
    reportsToComment,
    lineManagerPerformanceRating,
    reportsToPerformanceAverage,
    data?.employeeComment,
    data?.employeePerformanceAverage,
    performanceAverage,
  ]);

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else if (successUpdate) {
      // --- Close Modal on successUpdate --- //
      setShow(false);
      setMessage(true);
      emailjs
        .sendForm(
          "service_v4rxd7i",
          "template_7lx11g7",
          form.current,
          "nsrAhgU-pHN9kQRIs"
        )
        .then(
          (result) => console.log(result.text),
          (error) => console.log(error.text)
        );
    } else {
      setMessage(false);
    }
  }, [navigate, successUpdate, userInfo]);

  useEffect(() => {
    setReason([
      `
      Quater: ${data?.quarter},

      Year: ${year},
      
      Employee Code: ${userInfo?.employeeCode},

      Email: ${data?.employee?.email},

      Perfomance-factor: ${factors},

      Employee - comment: ${data?.employeeComment}`,
    ]);
    setReasonInput([` Reason for KPI being Rejected: ${deleteReason}`]);
  }, [
    year,
    factors,
    data?.employeeComment,
    userInfo?.employeeCode,
    data?.employee?.email,
    data?.quarter,
    deleteReason,
  ]);

  useEffect(() => {
    if (!data || data._id !== performanceId) {
      dispatch(getPerformanceByIdAction(performanceId));
    } else {
      setFactors(data?.performanceFactor);
      setRatings(data?.performanceRating);
    }
  }, [dispatch, data, performanceId]);

  // --- Handles HOD's onChange rating --- //
  const handleChangeRating = (e, index) => {
    const { value } = e.target;
    const list = [...ratingField];
    list[index] = value;
    setRatingField(list);
  };

  const handleSavePerformance = (e, index) => {
    const hodRatings = ratingField.map((item) => Number(item));

    const calcTotalRating = hodRatings.reduce((x, y) => {
      return x + y;
    }, 0);

    // --- Calculates Total Rating in Percent --- //
    var expectedTotalRating = ratingField.length * 4;
    var avgScore = calcTotalRating / expectedTotalRating;
    var hodAvgScorePercent = (avgScore * 100).toFixed(0);

    // --- Final AvgRating --- //
    const agentAvg = data?.employeePerformanceAverage;
    const finalAvg = ((agentAvg + Number(hodAvgScorePercent)) / 2).toFixed(0);

    setReportsToPerformanceAverage(Number(hodAvgScorePercent));
    setPerformanceAverage(Number(finalAvg));
    setLineManagerPerformanceRating(hodRatings);
  };

  useEffect(() => {
    handleSavePerformance();
  }, [ratingField]);

  // --- Add Recommendations --- //
  const addRecommendation = (e) => {
    if (
      e.target.value !==
      ("" || "Please, hit 'Shift Key' after each selection ...")
    ) {
      setRecommendations([...recommendations, e.target.value]);
      e.target.value = "Please, hit 'Shift Key' after each selection ...";
    }
  };

  // --- Remove Recommedations --- //
  const removeRecommendation = (index) => {
    const recommedField = [...recommendations];
    recommedField.splice(index, 1);
    setRecommendations(recommedField);
  };

  const updatePerformanceHandler = (e) => {
    e.preventDefault(e);
    dispatch(
      updateTeamPerformanceByIdAction({
        _id: performanceId,
        lineManagerPerformanceRating,
        developmentPlan,
        recommendations,
        reportsToPerformanceAverage,
        performanceAverage,
        reportsToComment,
      })
    );

    // --- Close Modal on successUpdate --- //
    // setShow(false);
  };

  const deleteAssessmentHandler = (e) => {
    async function deleteAssessment(performanceId) {
      const request = await axios.delete(
        baseUrl + `/api/v1/kpis/${performanceId}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
          data: {
            reason: `${deleteReason}`,
          },
        }
      );
      setDeleteStatus(true);
      return request;
    }
    deleteAssessment(performanceId);

    // --- Disable Modal --- /

    setShowDelete(false);
    emailjs
      .sendForm(
        "service_v4rxd7i",
        "template_7lx11g7",
        form.current,
        "nsrAhgU-pHN9kQRIs"
      )
      .then(
        (result) => console.log(result.text),
        (error) => console.log(error.text)
      );
  };

  const closeMessage = () => {
    setMessage(false);
    dispatch({
      type: UPDATE_TEAM_ASSESSMENT_ID_RESET,
    });
    navigate("/kpi/teamassessments");
  };

  return (
    <div className="dashboard-container">
      <div>
        <div className="dashboard-body">
          <Modal
            centered
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="kpi-modal submit">
            <ModalHeader
              setLgShow={setShow}
              icon={<SlGraph size={30} />}
              title={"KPI"}
              subtitle={"Submit Assessment"}
            />

            <Modal.Body>
              {loading && <Loader />}
              <p>Are you Sure?</p>
              <p className="mb-4">This action cannot be reversed</p>
              {/* <p className="last-line">
                Ensure you "Save & Check" before you hit "Yes"
              </p> */}
              <input
                type="button"
                value="No"
                className="KPI-close-btn"
                onClick={handleClose}
              />
              <input
                type="button"
                value="Yes"
                className="KPI-submit-btn"
                onClick={updatePerformanceHandler}
              />
            </Modal.Body>
          </Modal>

          {/* --- Delete Modal --- */}
          <Modal
            show={showDelete}
            onHide={handleCloseDelete}
            backdrop="static"
            keyboard={false}
            className="kpi-modal delete-reason-modal">
            <ModalHeader
              setLgShow={setShow}
              icon={<SlGraph size={30} />}
              title={"KPI"}
              subtitle={"Delete Assessment"}
            />
            <Modal.Body>
              <p>Are you Sure?</p>
              <p>
                Please state the reason for attempting to delete this assessment
              </p>
              <textarea
                className="inputField"
                rows="3"
                placeholder="Enter Your Reason ..."
                value={deleteReason}
                onChange={(e) => setDeleteReason(e.target.value)}
              />

              <input
                type="button"
                value="Close"
                className="KPI-close-btn"
                onClick={handleCloseDelete}
              />
              <input
                type="button"
                value="Proceed"
                className="KPI-submit-btn"
                onClick={deleteAssessmentHandler}
              />
            </Modal.Body>
            <form ref={form} style={{ display: "none" }}>
              <input
                name="to_name"
                id="to_name"
                defaultValue={data?.employee?.firstname}
              />
              <input
                name="email"
                id="email"
                defaultValue={data?.employee?.email}
              />

              <input
                name="from_name"
                id="from_name"
                defaultValue={
                  "Kindly see below the Reason for your KPI Rejected From " +
                  userInfo?.firstname +
                  " " +
                  userInfo?.lastname
                }
              />
              <input
                name="from_wishes"
                id="from_wishes"
                defaultValue={userInfo?.firstname + " " + userInfo?.lastname}
              />

              <Form.Group>
                <Form.Control
                  as="textarea"
                  name="message"
                  id="message"
                  rows={4}
                  placeholder="Enter Your Reason"
                  style={{
                    backgroundColor: "var(--input-field-color)",
                  }}
                  defaultValue={reason}
                />
              </Form.Group>

              <Form.Group>
                <Form.Control
                  as="textarea"
                  name="message"
                  id="message"
                  rows={4}
                  placeholder="Enter Your Reason"
                  style={{
                    backgroundColor: "var(--input-field-color)",
                  }}
                  defaultValue={reasoninput}
                />
              </Form.Group>
            </form>
          </Modal>

          {deleteStatus ? (
            <div className="info-status">
              <i className="fas fa-trash-alt fa-2x" />
              <p>This assessment has been removed</p>
              <p>Click the button below to navigate back to Team Assessments</p>
              <NavLink to="/kpi/teamassessments" className="KPI-submit-btn">
                Assessments
              </NavLink>
            </div>
          ) : message ? (
            <div className="info-status">
              <i className="fas fa-check-circle fa-2x" />
              <p>Thank you!</p>
              <p>This assessment has been updated successfully</p>
              <p>Click the button below to navigate back to Team Assessments</p>
              <button className="KPI-submit-btn" onClick={closeMessage}>
                Assessments
              </button>
            </div>
          ) : (
            <div className="kpi-body">
              <div className="kpi-performance-section">
                <div className="performance-header">
                  <div className="kpi-info-title">
                    <p>Name</p>
                    <p>
                      {data?.employee?.firstname} {data?.employee?.lastname}
                    </p>
                    <p>Staff ID</p>
                    <p>{data?.employee?.employeeCode}</p>
                  </div>
                  <div className="delete-btn">
                    <input
                      type="button"
                      value="Delete"
                      className="KPI-submit-btn"
                      onClick={handleShowDelete}
                    />
                  </div>
                </div>

                <div className="key-performance-box">
                  <div className="key-perf-title per-title">
                    <p>Performance Factor</p>
                    <p>Staff</p>
                    <p>HOD</p>
                  </div>
                  {errorUpdate && (
                    <Message variant="danger" className="error-border">
                      {errorUpdate}
                    </Message>
                  )}
                  <div>
                    <Form ref={form}>
                      {performanceFields.map((item, index) => (
                        <div key={index} className="performance-field">
                          <p style={{ backgroundColor: "#f8f8f8" }}>
                            {item.getFactor}
                          </p>
                          <input
                            type="number"
                            disabled
                            value={item.getRating}
                          />

                          <select
                            name="score"
                            value={item.hodRating}
                            onChange={(e) => handleChangeRating(e, index)}>
                            <option></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                          </select>
                        </div>
                      ))}
                      <div>
                        <p className="hod-comment review">
                          {data?.employee?.firstname}'s Comment
                        </p>
                        <textarea
                          className="inputField"
                          rows="3"
                          disabled
                          value={data?.employeeComment}
                        />
                        <p className="hod-comment review">Development Plan</p>
                        <textarea
                          placeholder="e.g: He/She is due for training"
                          className="inputField"
                          rows="1"
                          required
                          value={developmentPlan}
                          onChange={(e) => setDevelopmentPlan(e.target.value)}
                        />
                        <p className="hod-comment review">Recommendations</p>
                        <select
                          name="recommendations"
                          onKeyUp={(e) =>
                            e.key === "Shift" ? addRecommendation(e) : null
                          }>
                          <option>
                            Please, hit 'Shift Key' after each selection ...
                          </option>
                          <option value="Salary Review">Salary Review</option>
                          <option value="Promotion">Promotion</option>
                          <option value="Dismissal">Dismissal</option>
                          <option value="KPI Approved">KPI Approved</option>
                          <option value="Probation Period Extension">
                            Probation Period Extension
                          </option>
                        </select>
                        <ul id="recommendations">
                          {recommendations.map((recommend, index) => (
                            <li key={index} className="recommendation">
                              <span className="recommend-content">
                                {recommend}
                              </span>
                              <span
                                className="recommend-close-icon"
                                onClick={(e) => removeRecommendation(index)}>
                                x
                              </span>
                            </li>
                          ))}
                        </ul>
                        <p className="hod-comment review mt-2">My Comment</p>
                        <textarea
                          placeholder="Add Comment"
                          className="inputField"
                          rows="3"
                          required
                          value={reportsToComment}
                          onChange={(e) => setReportsToComment(e.target.value)}
                        />
                      </div>
                      {/* <input
                        type="button"
                        value="Save & Check"
                        className="KPI-close-btn"
                        onClick={handleSavePerformance}
                      /> */}
                      <input
                        type="button"
                        value="Submit"
                        className="KPI-submit-btn"
                        onClick={handleShow}
                      />
                    </Form>
                  </div>
                </div>
              </div>

              <form ref={form} style={{ display: "none" }}>
                <input
                  name="to_name"
                  id="to_name"
                  defaultValue={data?.employee?.firstname}
                />
                <input
                  name="email"
                  id="email"
                  defaultValue={data?.employee?.email}
                />

                <input
                  name="from_name"
                  id="from_name"
                  defaultValue={
                    "Kindly see below your performance Factors from " +
                    userInfo?.firstname +
                    " " +
                    userInfo?.lastname
                  }
                />
                <input
                  name="from_wishes"
                  id="from_wishes"
                  defaultValue={userInfo?.firstname + " " + userInfo?.lastname}
                />

                <Form.Group>
                  <Form.Control
                    as="textarea"
                    name="message"
                    id="message"
                    rows={4}
                    placeholder="Enter Your Reason"
                    style={{
                      backgroundColor: "var(--input-field-color)",
                    }}
                    defaultValue={performanceMessage}
                  />
                </Form.Group>
              </form>
              <div></div>
              <div className="kpi-legend-section">
                <div className="grading-system">
                  <div className="grading-system-title">
                    <p>Grading System</p>
                  </div>
                  <div className="grading-system-body">
                    <p>4</p>
                    <p>Exceptional</p>
                    <p>3</p>
                    <p>Expectations</p>
                    <p>2</p>
                    <p>Marginal</p>
                    <p>1</p>
                    <p>Unsatisfactory</p>
                  </div>
                </div>
                <div className="kpi-summary">
                  <div className="kpi-summary-title">
                    <p>KPI Summary</p>
                  </div>
                  <div className="kpi-summary-body final-rating-summary">
                    <p>Staff</p>
                    <p>{data?.employeePerformanceAverage}%</p>
                    <p>Line Manager</p>
                    <p id="hod-avg-rating">{reportsToPerformanceAverage}%</p>
                    <p>Brought Down Average</p>
                    <p id="final-avg">{performanceAverage}%</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HODReviewEmpKPI;
