import {
  ADMIN_CREATE_JOB_POST_REQUEST,
  ADMIN_CREATE_JOB_POST_SUCCESS,
  ADMIN_CREATE_JOB_POST_FAIL,
  ADMIN_CREATE_JOB_POST_RESET,
  GET_ALL_JOB_POST_BY_ID_REQUEST,
  GET_ALL_JOB_POST_BY_ID_SUCCESS,
  GET_ALL_JOB_POST_BY_ID_FAIL,
  GET_ALL_JOB_POST_BY_ID_RESET,
  UPDATE_JOB_POST_BY_ID_REQUEST,
  UPDATE_JOB_POST_BY_ID_SUCCESS,
  UPDATE_JOB_POST_BY_ID_FAIL,
  UPDATE_JOB_POST_BY_ID_RESET,
} from "../constants/createJobPostContants";

export const admincreateJobPostReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_CREATE_JOB_POST_REQUEST:
      return { loading: true };
    case ADMIN_CREATE_JOB_POST_SUCCESS:
      return { loading: false, success: true };
    case ADMIN_CREATE_JOB_POST_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_CREATE_JOB_POST_RESET:
      return {};
    default:
      return state;
  }
};

export const getJobPostByIdReducer = (state = { data: {} }, action) => {
  switch (action.type) {
    case GET_ALL_JOB_POST_BY_ID_REQUEST:
      return { ...state, loading: true };
    case GET_ALL_JOB_POST_BY_ID_SUCCESS:
      return { loading: false, data: action.payload.data };
    case GET_ALL_JOB_POST_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case GET_ALL_JOB_POST_BY_ID_RESET:
      return { data: {} };
    default:
      return state;
  }
};

// export const updateJobPostByIdReducer = (state = { jobPost: {} }, action) => {
//     switch (action.type) {
//         case GET_ALL_JOB_POST_BY_ID_REQUEST:
//             return { ...state, loading: true }
//         case GET_ALL_JOB_POST_BY_ID_SUCCESS:
//             return { loading: false, jobPost: action.payload.data }
//         case GET_ALL_JOB_POST_BY_ID_FAIL:
//             return { loading: false, error: action.payload }
//         case GET_ALL_JOB_POST_BY_ID_RESET:
//             return { jobPost: {} }
//         default:
//             return state
//     }
// }

export const updateJobPostByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_JOB_POST_BY_ID_REQUEST:
      return { loading: true };
    case UPDATE_JOB_POST_BY_ID_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_JOB_POST_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_JOB_POST_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};
