export const EDUCATION_DETAILS_REQUEST = 'EDUCATION_DETAILS_REQUEST'
export const EDUCATION_DETAILS_SUCCESS = 'EDUCATION_DETAILS_SUCCESS'
export const EDUCATION_DETAILS_FAIL = 'EDUCATION_DETAILS_FAIL'
export const EDUCATION_DETAILS_RESET = 'EDUCATION_DETAILS_RESET'


export const EDUCATION_UPDATE_REQUEST = 'EDUCATION_UPDATE_REQUEST'
export const EDUCATION_UPDATE_SUCCESS = 'EDUCATION_UPDATE_SUCCESS'
export const EDUCATION_UPDATE_FAIL = 'EDUCATION_UPDATE_FAIL'
export const EDUCATION_UPDATE_RESET = 'EDUCATION_UPDATE_RESET'


export const EDUCATION_CREATE_REQUEST = 'EDUCATION_CREATE_REQUEST'
export const EDUCATION_CREATE_SUCCESS = 'EDUCATION_CREATE_SUCCESS'
export const EDUCATION_CREATE_FAIL = 'EDUCATION_CREATE_FAIL'
export const EDUCATION_CREATE_RESET = 'EDUCATION_CREATE_RESET'

export const EDUCATION_CREATE_EMPLOYEE_REQUEST = 'EDUCATION_CREATE_EMPLOYEE_REQUEST'
export const EDUCATION_CREATE_EMPLOYEE_SUCCESS = 'EDUCATION_CREATE_EMPLOYEE_SUCCESS'
export const EDUCATION_CREATE_EMPLOYEE_FAIL = 'EDUCATION_CREATE_EMPLOYEE_FAIL'
export const EDUCATION_CREATE_EMPLOYEE_RESET = 'EDUCATION_CREATE_EMPLOYEE_RESET'

export const EDUCATION_DETAILS_EMPLOYEE_REQUEST = 'EDUCATION_DETAILS_EMPLOYEE_REQUEST'
export const EDUCATION_DETAILS_EMPLOYEE_SUCCESS = 'EDUCATION_DETAILS_EMPLOYEE_SUCCESS'
export const EDUCATION_DETAILS_EMPLOYEE_FAIL = 'EDUCATION_DETAILS_EMPLOYEE_FAIL'
export const EDUCATION_DETAILS_EMPLOYEE_RESET = 'EDUCATION_DETAILS_EMPLOYEE_RESET'