import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  AllLaptopInfoById,
  updateLaptopInfo,
} from "../../actions/LaptopApplicationAction";
import { Button, Form, Col } from "react-bootstrap";
import Message from "../../components/Message";
import Loader from "../../components/Loader";

import { LAPTOP_INFO_UPDATE_ID_RESET } from "../../constants/laptopApplicationContants";
import { baseUrl } from "../../shared/baseUrl";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const LaptopInfoUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const LaptopInfoId = id;

  const dispatch = useDispatch();

  // const getAllLaptopInfoById = useSelector(state => state.getAllLaptopInfoById)
  // const { laptop, loading } = getAllLaptopInfoById

  const updateLaptopInfoById = useSelector(
    (state) => state.updateLaptopInfoById
  );
  const {
    loading: loadingUpdate,
    error: errorUpadate,
    success: successUpdate,
  } = updateLaptopInfoById;

  // const [fullname, setFullName] = useState('')
  // const [department, setDepartment] = useState('')
  const [employeeStatus, setEmployeeStatus] = useState("");
  // const [systemName, setSystemName] = useState('')
  // const [systemType, setSystemType] = useState('')
  // const [serialNumber, setSerialNumber] = useState('')
  // const [monitor, setMonitor] = useState('')
  // const [deskTopSerialNumber, setDeskTopSerialNumber] = useState('')
  // const [HDD, setHDD] = useState('')
  // const [windowsVersion, setWindowsVersion] = useState('')
  // const [ramSize, setRamSize] = useState('')
  const [status, setStatus] = useState("");
  const [systemStatus, setSystemStatus] = useState("");
  const [systemCondition, setSystemCondition] = useState("");
  const [loading, setLoading] = useState(false);
  const [laptop, setLaptop] = useState([]);

  const [showMessage, setShowMessage] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (successUpdate) {
      setShowMessage(true);
      dispatch({
        type: LAPTOP_INFO_UPDATE_ID_RESET,
      });
    }
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  }, [dispatch, successUpdate, laptop]);

  const _Id = LaptopInfoId;
  const url = `/api/v1/laptopinfo/${_Id}`;
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const { data } = await axios.get(baseUrl + url, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      setLaptop(data.data);
      setLoading(false);
    };
    fetchData();
  }, [userInfo, url, successUpdate]);

  useEffect(() => {
    if (
      userInfo &&
      (userInfo?.role === "IT Support Specialist" || userInfo.role === "CEO")
    ) {
      dispatch(AllLaptopInfoById(LaptopInfoId));
    } else {
      navigate("/");
    }
  }, [dispatch, navigate, userInfo, LaptopInfoId, successUpdate]);

  // useEffect(() => {
  //   if (userInfo) {
  //     if (laptop?._id === LaptopInfoId) {
  //       dispatch({
  //         type: LAPTOP_INFO_UPDATE_ID_RESET
  //       })
  //       dispatch(AllLaptopInfoById(LaptopInfoId))
  //     }
  //      else {
  //        setFullName(laptop.fullname)
  //        setDepartment(laptop.department)
  //        setSystemName(laptop.systemName)
  //        setSystemType(laptop.systemType)
  //        setSerialNumber(laptop.serialNumber)
  //        setMonitor(laptop.monitor)
  //        setDeskTopSerialNumber(laptop.deskTopSerialNumber)
  //        setHDD(laptop.HDD)
  //        setWindowsVersion(laptop.windowsVersion)
  //        setRamSize(laptop.ramSize)
  //        setSystemCondition(laptop.systemCondition)
  //      }
  //   }
  //   else {
  //     history.push('/')
  //   }
  // }, [dispatch, history, userInfo, LaptopInfoId, laptop, successUpdate])

  const submitHandler = (e) => {
    e.preventDefault(e);
    dispatch(
      updateLaptopInfo({
        _id: LaptopInfoId,
        status,
        systemStatus,
        employeeStatus,
        systemCondition,
      })
    );
  };

  return (
    <div className="dashboard-container">
      <div>
        <div className="performance-screen-wrapper">
          <div className="kpi-body">
            <div className="kpi-performance-section">
              <div className="performance-intro">
                <div className="performance-intro-header">
                  <p style={{ marginBottom: "0px" }}>
                    Laptop Given By :{" "}
                    <i>
                      {laptop?.createdBy?.firstname}{" "}
                      {laptop?.createdBy?.lastname}
                    </i>
                  </p>
                  <p>
                    <b>Email :</b> {laptop?.createdBy?.email}{" "}
                  </p>
                  <p style={{ marginBottom: "0px", marginTop: "-20px" }}>
                    <b>
                      <i>Date : </i>
                    </b>
                    {moment(laptop?.createdAt).format("DD-MM-YYYY")}{" "}
                  </p>
                </div>
                {laptop?.updatedBy && (
                  <div
                    className="performance-intro-header"
                    style={{ backgroundColor: "#fddcdf" }}>
                    <p style={{ marginBottom: "0px" }}>
                      {" "}
                      Laptop Updated By :{" "}
                      <i>
                        {laptop?.updatedBy?.firstname}{" "}
                        {laptop?.updatedBy?.lastname}
                      </i>
                    </p>
                    <p>
                      <b>Email :</b> {laptop?.updatedBy?.email}{" "}
                    </p>
                    <p style={{ marginBottom: "0px", marginTop: "-20px" }}>
                      <b>
                        <i>Date : </i>
                      </b>
                      {moment(laptop?.updatedAt).format("DD-MM-YYYY")}{" "}
                    </p>
                  </div>
                )}
              </div>
              {errorUpadate && (
                <Message variant="danger" className="error-border" dismissible>
                  {errorUpadate}
                </Message>
              )}
              {showMessage && (
                <Message variant="success" className="success-border">
                  {" "}
                  <p className="success-color">
                    Laptop information updated successfully!
                  </p>{" "}
                </Message>
              )}
              {loading && <Loader />}
              <div className="key-performance-box-2">
                <div className="key-perf-title per-title">
                  <p>Laptop Information</p>
                </div>
                <div className="fields-container profilescreen-wrapper">
                  <Form className="form-shadow" onSubmit={submitHandler}>
                    <Form.Row>
                      <Form.Group as={Col} controlId="Status">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          size="sm"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}>
                          <option value="">Select Status...</option>
                          <option value="Good"> Good </option>
                          <option value="Faulty">Faulty</option>
                          <option value="Good & Returned">
                            Good & Returned
                          </option>
                          <option value="Faulty & Returned">
                            Faulty & Returned
                          </option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group as={Col} controlId="System Status">
                        <Form.Label>System Status</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          size="sm"
                          value={systemStatus}
                          onChange={(e) => setSystemStatus(e.target.value)}>
                          <option value="">Select Status...</option>
                          <option value="In Use & Active">
                            In Use & Active{" "}
                          </option>
                          <option value="Not In Use">Not In Use</option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group as={Col} controlId="Status">
                        <Form.Label>Employee Status</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          size="sm"
                          value={employeeStatus}
                          onChange={(e) => setEmployeeStatus(e.target.value)}>
                          <option value="">Select Status...</option>
                          <option value="Active"> Active </option>
                          <option value="Inactive">Inactive</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Group controlId="description">
                      <Form.Label>System Condition</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter System Condition"
                        style={{ backgroundColor: "#fff" }}
                        value={systemCondition}
                        onChange={(e) => setSystemCondition(e.target.value)}
                      />
                    </Form.Group>
                    <Button
                      className="KPI-close-btn"
                      variant="primary"
                      disabled={loadingUpdate && true}
                      type="submit"
                      value="Update">
                      {loadingUpdate ? "Updating..." : "Update"}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
            <div className="kpi-legend">
              <div className="performance-info">
                <p>Full Name:</p>
                <p>{laptop?.fullname}</p>
                <p> Department</p>
                <p>{laptop?.department}</p>
                <p> Employee Status</p>
                <p>{laptop?.employeeStatus}</p>
                <p> System Status</p>
                <p>{laptop?.systemStatus}</p>
                <p>System Name</p>
                <p>{laptop?.systemName}</p>
                <p>SystemType</p>
                <p>{laptop?.systemType} </p>
                <p>SerialNumber</p>
                <p>{laptop?.serialNumber}</p>
                <p>Monitor Serial Number</p>
                <p>{laptop?.monitor}</p>
                <p>Windows Version</p>
                <p>{laptop?.windowsVersion}</p>
                <p>HDD Size</p>
                <p>{laptop?.HDD}GB</p>
                <p>RAM Size</p>
                <p>{laptop?.ramSize}GB</p>
                <p>Status</p>
                <p>{laptop?.status} </p>
              </div>
              <div className="other-assessments">
                <p>System Condition</p>
                <textarea rows="3" disabled value={laptop?.systemCondition} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LaptopInfoUpdate;
